import axios from "axios";
import { fetchAuthSession } from "@aws-amplify/auth";

const api_url = "https://bt9q9z3av6.execute-api.us-east-1.amazonaws.com/Prod/";
const wompi_api_url = "https://sandbox.wompi.co/";

// Define la llave pública globalmente
const WOMPI_PUBLIC_KEY = "pub_test_0XZMwzNEJxOEaq0inSCtcLEJhSlaJfCK";

axios.defaults.baseURL = api_url;

// Función para obtener el token de acceso de Cognito
async function getAuthToken() {
  try {
    const session = await fetchAuthSession();
    return session.tokens?.idToken?.toString() || null;
  } catch (error) {
    console.log("Error obtaining auth token:", error);
    return null;
  }
}

// Función genérica para realizar solicitudes HTTP
async function request(method, path, data = null, customBaseURL = null, headers = {}) {
  const baseURL = customBaseURL || api_url;

  // Intenta obtener el token de autenticación solo si es necesario y si no es una solicitud a Wompi
  if (!customBaseURL || customBaseURL === api_url) {
    const token = await getAuthToken();
    if (token) {
      headers.Authorization = token;
    }
  }

  return axios({
    method,
    url: path,
    data,
    baseURL,
    headers,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Request error:", error);
      return error.response?.data || { error: "Error en la solicitud" };
    });
}

// Función GET
export function get(path) {
  return request("GET", path);
}

// Función POST
export function post(path, data) {
  return request("POST", path, data);
}

// Función PUT
export function put(path, data) {
  return request("PUT", path, data);
}

// Función DELETE
export function del(path, data) {
  return request("DELETE", path, data);
}

// Función GET para Wompi con token en los headers
export function getWompi(path) {
  return request("GET", path, null, wompi_api_url, {
    Authorization: `Bearer ${WOMPI_PUBLIC_KEY}`,
  });
}

// Función POST para Wompi con token en los headers
export function postWompi(path, data) {
  return request("POST", path, data, wompi_api_url, {
    Authorization: `Bearer ${WOMPI_PUBLIC_KEY}`,
  });
}