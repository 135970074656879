import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

type ScrollAnimationProps = {
  effect?: 'top' | 'bottom' | 'left' | 'right';
  delay?: number;
};

const useScrollAnimation = ({ effect = 'top', delay = 0 }: ScrollAnimationProps): React.RefObject<HTMLDivElement> => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animationProps = {
      opacity: 0,
      y: 0,
      x: 0,
    };

    switch (effect) {
      case 'top':
        animationProps.y = -100;
        break;
      case 'bottom':
        animationProps.y = 100;
        break;
      case 'left':
        animationProps.x = -100;
        break;
      case 'right':
        animationProps.x = 100;
        break;
      default:
        break;
    }

    gsap.fromTo(
      elementRef.current,
      animationProps,
      {
        y: 0,
        x: 0,
        opacity: 1,
        duration: 1,
        delay,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: elementRef.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      }
    );
  }, [effect, delay]);

  return elementRef;
};

export default useScrollAnimation;
