import React, { useState, useEffect } from "react";
import { Card, Row, Col, Spin, Typography, message, Button, Input, Form, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { get, put, post } from "../../Api/http/httpClient"; // Importar post también
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

interface WhatsAppSignupProps {
  user_data: any;
}

const WhatsAppSignup: React.FC<WhatsAppSignupProps> = ({ user_data }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasAssignedNumber, setHasAssignedNumber] = useState(false);
  const [assignedNumber, setAssignedNumber] = useState<string | null>(null);
  const [paymentRequired, setPaymentRequired] = useState(false);

  const [businessName, setBusinessName] = useState("");
  const [businessImage, setBusinessImage] = useState<string>("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [isNewProfile, setIsNewProfile] = useState(false); // Nuevo estado para indicar si es un perfil nuevo

  const navigate = useNavigate();

  const checkAssignedNumber = async (withAssignedNumber = false) => {
    setLoading(true);
    setError(null);
    try {
      const establishmentID = user_data["custom:establishment_id"];
      const family_name = user_data.family_name;
      const url = `/check-assigned-number?establishmentID=${establishmentID}&name_establishment=${family_name}${
        withAssignedNumber ? "&assignedNumber=true" : ""
      }`;
      const response = await get(url);
      if (response.answer === false && response.message === "Payment required: User has not paid for this service.") {
        setPaymentRequired(true);
      } else if (response.answer) {
        if (response.assigned) {
          setHasAssignedNumber(true);
          setAssignedNumber(response.whatsappNumber || "Número no disponible");
          message.info("Ya tienes un número asignado.");
          handleGetBusinessProfile();
        } else if (response.message === "No numbers available for assignment.") {
          setHasAssignedNumber(false);
          setAssignedNumber(null);
          message.warning("No hay números disponibles para asignación automática.");
        } else {
          setHasAssignedNumber(false);
          setAssignedNumber(null);
          message.warning("No has realizado la integración automática o manual del número.");
        }
      } else {
        setHasAssignedNumber(false);
        setAssignedNumber(null);
        message.error("Error al verificar el número asignado.");
      }
    } catch (err) {
      console.error("Error checking assigned number:", err);
      setError("Error al verificar el número asignado.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAssignedNumber();
  }, []);

  const handleGetBusinessProfile = async () => {
    setLoading(true);
    try {
      const establishmentID = user_data["custom:establishment_id"];
      const url = `/business?establishmentID=${establishmentID}`;
      const response = await get(url);

      if (response && response.answer) {
        // Si existe el perfil
        setBusinessName(response.data.nameWhastApp || "");
        setBusinessDescription(response.data.description || "");
        setBusinessImage(response.data.imageBase64 || "");
        setIsNewProfile(false);
      } else {
        // Si no existe el perfil
        // Mostramos campos vacíos y marcamos isNewProfile=true
        setBusinessName("");
        setBusinessDescription("");
        setBusinessImage("");
        setIsNewProfile(true);
      }
    } catch (err) {
      console.error("Error fetching business profile:", err);
      // Si el GET lanza un error 404, lo captamos y asumimos que no hay perfil
      setIsNewProfile(true);
      // Opcional: message.error("No se encontró el perfil de negocio, por favor créalo.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const establishmentID = user_data["custom:establishment_id"];
      const data = {
        establishmentID,
        name: businessName,
        description: businessDescription,
        imageBase64: businessImage,
        whatsappNumber: assignedNumber
      };

      let response;
      if (isNewProfile) {
        // Si es un perfil nuevo, usamos POST
        response = await post('/business', data);
        if (response && response.answer) {
          message.success("Perfil de negocio creado exitosamente");
          setIsNewProfile(false);
        } else {
          message.error("No se pudo crear el perfil de negocio");
        }
      } else {
        // Si ya existe, usamos PUT
        response = await put('/business', data);
        if (response && response.answer) {
          message.success("Perfil de negocio actualizado exitosamente");
        } else {
          message.error("No se pudo actualizar el perfil de negocio");
        }
      }

    } catch (err) {
      console.error("Error saving business profile:", err);
      message.error("Error al guardar el perfil del negocio.");
    } finally {
      setLoading(false);
    }
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleBeforeUpload = async (file: File) => {
    if (!file.type.startsWith("image/")) {
      message.error("¡Sólo puedes subir imágenes!");
      return Upload.LIST_IGNORE;
    }

    const base64 = await getBase64(file);
    setBusinessImage(base64);
    return false;
  };

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh", textAlign: "center" }}>
        <Spin size="large" />
      </Row>
    );
  }

  if (paymentRequired) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh", textAlign: "center" }}>
        <Card
          style={{
            textAlign: "center",
            padding: "30px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
        >
          <Typography.Title level={3} style={{ color: "#ff4d4f" }}>
            ¡No has pagado!
          </Typography.Title>
          <p style={{ fontSize: "16px", lineHeight: "1.5" }}>
            Debido a que no has completado el pago, no podrás realizar la integración con WhatsApp.
            Por favor, elige un plan adecuado para habilitar esta funcionalidad.
          </p>
          <p style={{ fontSize: "14px", lineHeight: "1.5", marginTop: "10px" }}>
            <strong>¿Ya pagaste?</strong> Intenta cerrando sesión y volviendo a iniciar.
          </p>
          <p style={{ fontSize: "14px", lineHeight: "1.5", marginTop: "10px" }}>
            Si el problema persiste, comunícate con nosotros para obtener ayuda.
          </p>
          <Button
            type="primary"
            size="large"
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/plans")}
          >
            Ver Planes
          </Button>
          <Button
            type="link"
            style={{ marginTop: "10px" }}
            onClick={() => message.info("Contactar soporte: soporte@miapp.com")}
          >
            Contactar Soporte
          </Button>
        </Card>
      </Row>
    );
  }

  if (error) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh", textAlign: "center" }}>
        <Card>
          <Text type="danger">{error}</Text>
          <br />
          <Button type="primary" onClick={() => checkAssignedNumber()}>
            Reintentar
          </Button>
        </Card>
      </Row>
    );
  }

  return (
    <Row justify="center" style={{ marginTop: "20px", padding: "20px" }}>
      <Col xs={24} sm={24} md={20} lg={16}>
        <Card title="Gestión de Número de WhatsApp" bordered={false}>
          {!hasAssignedNumber ? (
            <>
              <Text strong>Contexto:</Text>
              <p>
                Aquí puedes asignar un número para un agente que gestionará las
                respuestas a los usuarios mediante WhatsApp. Si eliges{" "}
                <strong>"Asignar un nuevo número"</strong>, el sistema verificará y
                asignará automáticamente uno disponible. Si prefieres{" "}
                <strong>"Asignar un número existente"</strong>, el proceso será
                manual, y será necesario esperar la integración de dicho número por
                parte del equipo de Blox.
              </p>
              <p>
                <strong>Recordatorio:</strong> El número existente debe estar previamente verificado por <strong>WhatsApp Business</strong> para poder ser integrado correctamente.
              </p>
              <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                <Col span={12}>
                  <Button
                    type="primary"
                    block
                    onClick={() => checkAssignedNumber(true)}
                    style={{ height: "50px" }}
                  >
                    Asignar un Nuevo Número
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="default"
                    block
                    onClick={() => message.info("Funcionalidad aún no implementada.")}
                    style={{ height: "50px" }}
                  >
                    Asignar Número Existente
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Text strong>Información:</Text>
              <p>
                El número asignado es al cual los clientes/usuarios enviarán mensajes. Puedes ir a{" "}
                <strong>Gestionar WhatsApp</strong> para administrar los chats.
              </p>
              <Button
                type="primary"
                onClick={() => navigate("/dashboard/whatsapp-chat")}
              >
                Gestionar WhatsApp
              </Button>
              <Card
                style={{
                  marginTop: "20px",
                  backgroundColor: "#f6f8fa",
                  borderColor: "#d9d9d9",
                }}
              >
                <Text strong>Número Actual Asignado:</Text>
                <br />
                <Text>{assignedNumber}</Text>
              </Card>

              <Card
                title="Configuración de WhatsApp Business"
                style={{ marginTop: "20px" }}
              >
                <Form layout="vertical">
                  <Form.Item label="Nombre de WhatsApp">
                    <Input
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                      placeholder="Ingresa el nombre de tu negocio en WhatsApp"
                    />
                  </Form.Item>

                  <Form.Item label="Imagen de Perfil de WhatsApp">
                    <Upload
                      name="businessImage"
                      accept="image/*"
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={handleBeforeUpload}
                    >
                      {businessImage ? (
                        <img src={businessImage} alt="Business" style={{ width: "100%" }} />
                      ) : (
                        <div>
                          <UploadOutlined />
                          <div style={{ marginTop: 8 }}>Subir</div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>

                  <Form.Item label="Descripción de WhatsApp">
                    <Input.TextArea
                      value={businessDescription}
                      onChange={(e) => setBusinessDescription(e.target.value)}
                      placeholder="Ingresa la descripción de tu negocio en WhatsApp"
                      rows={3}
                    />
                  </Form.Item>

                  <Button type="primary" onClick={handleSaveChanges}>
                    {isNewProfile ? "Crear Perfil" : "Guardar Cambios"}
                  </Button>
                </Form>
              </Card>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default WhatsAppSignup;
