import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContainerBase, ContainerCenter, ContainerHeader, ContainerMenu } from '../styledComponents/Containers';
import Logo from '../../../assets/img/landingPage/LogoBlox.svg';
import useMouseParallax from '../../../hooks/useMouseParallax';
import { MenuIcon, TextBackground, MenuItems } from '../styledComponents/Menu';
import { gsap } from 'gsap';
import { ButtonFill, ButtonGroup } from "../styledComponents/Buttons";
import { useMediaQuery } from 'react-responsive';
import { Grid, Space } from 'antd';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { x, y } = useMouseParallax();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const screens = Grid.useBreakpoint();

  useEffect(() => {
    gsap.fromTo(
      '#menuIcon',
      { x: 100, opacity: 0 },
      { duration: 1, x: 0, opacity: 1, delay: 0.3 }
    );
    gsap.fromTo(
      '#logoIcon',
      { x: -100, opacity: 0 },
      { duration: 1, x: 0, opacity: 1, delay: 0.3 }
    );
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLAnchorElement>, targetId: string) => {
    event.preventDefault();
    const targetSection = document.getElementById(targetId);

    if (targetSection) {
      const hasSticky = targetSection.classList.contains('sticky');
      if (hasSticky) targetSection.classList.remove('sticky');

      window.scrollTo({
        top: targetSection.offsetTop - 100,
        behavior: 'smooth',
      });

      setTimeout(() => {
        if (hasSticky) targetSection.classList.add('sticky');
      }, 1000);
    }

    setIsOpen(false);
  };

  return (
    <>
      {/* MENU */}
      <ContainerMenu $isOpen={isOpen}>
        <ContainerBase $height={screens.xs ? '100%' : 'auto'}>
          <ContainerCenter $height={screens.xs ? '100%' : 'auto'} $padding='100px 0 0' $isInline={false}>
            {/* <img id='logoIcon' src={Logo} alt="Logo Blox Horizontal" style={{ marginBottom: '20px' }} /> */}
            {isMobile && ( // Condicional para mostrar los botones solo en móvil
              <Space>
                <ButtonFill
                  style={{ padding: '10px 20px', width: '100%' }}
                  onClick={() => navigate('/dashboard')}
                >
                  Iniciar Sesión
                </ButtonFill>
                <ButtonFill
                  style={{ padding: '10px 20px', width: '100%' }}
                  onClick={() => navigate('/onboarding')}
                >
                  Registrarse
                </ButtonFill>
              </Space>
            )}
            <MenuItems $height='100%' $padding='20px 0' $gap='15px' $overflow='hidden auto' $justify='flex-start'>
              <a href='#problem' onClick={(e) => handleMenuClick(e, 'problem')}><span>1</span>¿Qué Resolvemos?</a>
              <a href='#solution' onClick={(e) => handleMenuClick(e, 'solution')}><span>2</span>Propuesta de Valor</a>
              <a href='#features' onClick={(e) => handleMenuClick(e, 'features')}><span>3</span>Características</a>
              <a href='#cases' onClick={(e) => handleMenuClick(e, 'cases')}><span>4</span>Casos de Uso</a>
              <a href='#testimonials' onClick={(e) => handleMenuClick(e, 'testimonials')}><span>5</span>Testimonios</a>
              <a href='#demo' onClick={(e) => handleMenuClick(e, 'demo')}><span>6</span>Demo Interactiva</a>
              <a href='#prices' onClick={(e) => handleMenuClick(e, 'prices')}><span>7</span>Planes y Precios</a>
              <a href='#cta' onClick={(e) => handleMenuClick(e, 'cta')}><span>8</span>Contáctenos</a>
            </MenuItems>
          </ContainerCenter>
          <TextBackground $offsetX={x} $offsetY={y}>Blox, el asistente <br /> virtual inteligente</TextBackground>
        </ContainerBase>
      </ContainerMenu>

      {/* HEADER */}
      <ContainerHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <a href='#hero'>
          <img id='logoIcon' src={Logo} alt="Logo Blox Horizontal" />
        </a>

        {!isMobile && ( // Botones solo en pantallas grandes
          <ButtonGroup $justify="flex-end" style={{ position: 'absolute', right: '190px' }}>
            <ButtonFill
              style={{ padding: '10px 20px', minWidth: '120px' }}
              onClick={() => navigate('/dashboard')}
            >
              Iniciar Sesión
            </ButtonFill>
            <ButtonFill
              style={{ padding: '10px 20px', minWidth: '120px' }}
              onClick={() => navigate('/onboarding')}
            >
              Registrarse
            </ButtonFill>
          </ButtonGroup>
        )}

        <MenuIcon id='menuIcon' $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} >
          <div></div>
          <div></div>
          <div></div>
        </MenuIcon>
      </ContainerHeader>
    </>
  );
};

export default Header;