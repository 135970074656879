import React from "react";
import { useFormik } from "formik";
import { Input, Form, Select, Row, Col, Button, Switch } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import { formatCardNumber, formatExpiryDate } from "../../utils/formatting";
import { paySchema } from "../../schema";

const { Option } = Select;
const installmentsOptions = [1, 3, 6, 12, 24]; // Opciones de cuotas

interface PaymentFormProps {
  onPayment: (paymentMethod: string, paymentData: any) => void;
  permalink: string; // Nueva prop para el enlace
}

const PaymentForm: React.FC<PaymentFormProps> = ({ onPayment, permalink }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      installments: 1, // Valor inicial para cuotas
      autoRenew: false, // Nuevo campo para la suscripción mensual
    },
    validationSchema: paySchema,
    onSubmit: async (values) => {
      onPayment("CARD", values);
    },
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[a-zA-Z\s]*$/; // Solo permite letras y espacios
    if (regex.test(e.target.value)) {
      formik.setFieldValue("name", e.target.value);
    }
  };

  const handlePaymentClick = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        formik.handleSubmit();
      } else {
        formik.setTouched({
          name: true,
          cardNumber: true,
          expiryDate: true,
          cvv: true,
          installments: true,
          autoRenew: true,
        });
      }
    });
  };

  return (
    <div>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <h3>Información de la tarjeta</h3>

        <Form.Item
          label="Nombre en la tarjeta"
          validateStatus={formik.touched.name && formik.errors.name ? "error" : ""}
          help={formik.touched.name && formik.errors.name}
        >
          <Input
            id="name"
            name="name"
            placeholder="John Doe"
            value={formik.values.name}
            onChange={handleNameChange}
            size="large"
          />
        </Form.Item>

        <Form.Item
          label="Número de tarjeta"
          validateStatus={formik.touched.cardNumber && formik.errors.cardNumber ? "error" : ""}
          help={formik.touched.cardNumber && formik.errors.cardNumber}
        >
          <Input
            id="cardNumber"
            name="cardNumber"
            placeholder="1234 1234 1234 1234"
            value={formatCardNumber(formik.values.cardNumber)}
            onChange={(e) => {
              e.target.value = formatCardNumber(e.target.value);
              formik.handleChange(e);
            }}
            maxLength={19}
            size="large"
            prefix={<CreditCardOutlined />}
          />
        </Form.Item>

        <Form.Item
          label="Número de cuotas"
          name="installments"
          validateStatus={formik.touched.installments && formik.errors.installments ? "error" : ""}
          help={formik.touched.installments && formik.errors.installments}
        >
          <Select
            size="large"
            placeholder="Seleccione número de cuotas"
            value={formik.values.installments}
            onChange={(value) => formik.setFieldValue("installments", value)}
          >
            {installmentsOptions.map((installment) => (
              <Option key={installment} value={installment}>
                {installment} {installment === 1 ? "Cuota" : "Cuotas"}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Fecha de expiración"
              validateStatus={formik.touched.expiryDate && formik.errors.expiryDate ? "error" : ""}
              help={formik.touched.expiryDate && formik.errors.expiryDate}
            >
              <Input
                id="expiryDate"
                name="expiryDate"
                placeholder="MM/YY"
                value={formatExpiryDate(formik.values.expiryDate)}
                onChange={(e) => {
                  e.target.value = formatExpiryDate(e.target.value);
                  formik.handleChange(e);
                }}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="CVV"
              validateStatus={formik.touched.cvv && formik.errors.cvv ? "error" : ""}
              help={formik.touched.cvv && formik.errors.cvv}
            >
              <Input
                id="cvv"
                name="cvv"
                placeholder="123"
                maxLength={4}
                value={formik.values.cvv}
                onChange={formik.handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Suscripción mensual">
          <Switch
            checked={formik.values.autoRenew}
            onChange={(checked) => formik.setFieldValue("autoRenew", checked)}
          />
          <span style={{ marginLeft: 8 }}>Activar la suscripción mensual</span>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            size="large"
            onClick={handlePaymentClick}
            style={{
              width: "100%",
              backgroundColor: "#1890ff",
              color: "#fff",
            }}
          >
            Realizar Pago
          </Button>
        </Form.Item>
      </Form>

      <p style={{ color: "#ffffff", backgroundColor: "#333", padding: "10px", borderRadius: "5px" }}>
        Al enviar este pedido, acepto los{" "}
        <a href={permalink} target="_blank" rel="noopener noreferrer" style={{ color: "#1890ff" }}>
          Términos y Condiciones
        </a>{" "}
        y el{" "}
        <a href={permalink} target="_blank" rel="noopener noreferrer" style={{ color: "#1890ff" }}>
          Acuerdo de Suscripción
        </a>
        .
      </p>
    </div>
  );
};

export default PaymentForm;