import { Modal, Form, Input, Button } from "antd";

interface AddServiceModalProps {
  visible: boolean;
  onCancel: () => void;
  onAdd: (service: { name: string; description: string; price: string }) => void;
}

export default function AddServiceModal({
  visible,
  onCancel,
  onAdd,
}: AddServiceModalProps) {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      onAdd(values);
      form.resetFields();
      onCancel();
    });
  };

  return (
    <Modal
      title="Agregar Servicio"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="add" type="primary" onClick={handleOk}>
          Agregar
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Nombre del Servicio"
          name="name"
          rules={[{ required: true, message: "Ingrese el nombre del servicio" }]}
        >
          <Input placeholder="Ej: Consulta General" />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          rules={[
            { required: true, message: "Ingrese la descripción del servicio" },
          ]}
        >
          <Input placeholder="Ej: Consulta médica básica" />
        </Form.Item>
        <Form.Item
          label="Precio"
          name="price"
          rules={[{ required: true, message: "Ingrese el precio del servicio" }]}
        >
          <Input placeholder="Ej: 50000" />
        </Form.Item>
      </Form>
    </Modal>
  );
}