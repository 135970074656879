import styled from 'styled-components';
import '../../../assets/css/fonts.css';

export const ButtonFill = styled.button`
    width: auto;
    min-width: 260px;
    padding: 18px 16px;
    gap: 10px;
    border-radius: 8px;
    border: none;
    opacity: 0px;
    background: linear-gradient(90.08deg, #0E6EAF -26.21%, #4296DE 37.83%, #4296DE 66.74%, #0E6EAF 116.78%);
    font-family: poppins-regular;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #FAFAFA;
    svg {
        font-size: 18px;
    }
    &:hover {
        background: linear-gradient(90.08deg, #032034 -26.21%, #4296DE 37.83%, #4296DE 66.74%, #032034 116.78%);
    }
    @media (max-width: 480px) {
        width: 100%;
        min-width: auto;
    }
`;

export const ButtonOutline = styled(ButtonFill).attrs<{ $textWhite?: boolean }>((props) => ({
    $textWhite: props.$textWhite,
}))`
    background: transparent;
    border: 2px solid #0E6EAF;
    color: ${({ $textWhite }) => ($textWhite ? '#FFFFFF' : '#0E6EAF')};
    position: relative;
    overflow: hidden;    
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.5s ease-in-out;
        background-color: #0E6EAF;
        border-radius: 30px;
        visibility: hidden;
        height: 10px;
        width: 10px;
        z-index: -1;
    }
    &:hover {
        background: transparent;
        border-color: transparent;
        color: #FFFFFF;
        &::after {
            visibility: visible;
            transform: scale(100) translateX(2px);
        }
    }
`;

export const ButtonGroup = styled.div.attrs<{ $justify?: string }>((props) => ({
    $justify: props.$justify || 'center',
}))`
    display: flex;
    justify-content: ${({$justify}) => $justify};
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    @media (max-width: 760px) {
        justify-content: center;
    }
`;

export const ButtonPlay = styled.button`
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0E6EAF;
    border-radius: 50%;
    border: none;
    color: #FAFAFA;
    cursor: pointer;
    font-size: 18px;
`;


export const RadioButtonContainer = styled.div`
    position: relative;
    width: 150px;
    height: 150px;
    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        &:checked + div {
            border-color: #0E6EAF;
            color: #0E6EAF;
        }
    }
`;

export const CheckboxContainer = styled(RadioButtonContainer)`
    width: 100px;
    height: 100px;
    .ant-checkbox-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
        .ant-checkbox {
            width: 100px;
            height: 100%;
            .ant-checkbox-input {
                width: 100px;
                height: 100%;
            }
        }
    }
    .ant-checkbox-wrapper:has(.ant-checkbox-input:checked) + div {
        border-color: #0E6EAF;
        color: #0E6EAF;
    }
`;

export const RadioButton = styled.div`
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    font-family: poppins-regular;
    font-size: 14px;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;  
    transition: all .2s ease-in-out;
    label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 24px;
    }
`;

export const CheckboxButton = styled(RadioButton)`
    font-size: 12px;
    label {
        padding: 14px;
    }
`;