import { useEffect, useRef } from "react";
import {
  ContainerBase,
  ContainerCenter,
  ContainerHero,
} from "../styledComponents/Containers";
import { ImageHero, LinesHero } from "../styledComponents/Images";
import { Description, Title } from "../styledComponents/Typography";
import { ButtonFill } from "../styledComponents/Buttons";
import LinesImage from "../../../assets/img/landingPage/LinesHero.webp";
import LaptopHero from "../../../assets/img/landingPage/LaptopHero.webp";
import TabletHero from "../../../assets/img/landingPage/TabletHero.webp";
import MobileHero from "../../../assets/img/landingPage/MobileHero.webp";
import { gsap } from "gsap";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const buttonRef = useRef(null);
  const imageBottomRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    ).matches;

    if (!prefersReducedMotion) {
      gsap.fromTo(
        titleRef.current,
        { y: 100, opacity: 0 },
        { duration: 1, y: 0, opacity: 1 }
      );
      gsap.fromTo(
        descriptionRef.current,
        { y: 100, opacity: 0 },
        { duration: 1, y: 0, opacity: 1, delay: 0.1 }
      );
      gsap.fromTo(
        buttonRef.current,
        { y: 100, opacity: 0 },
        { duration: 1, y: 0, opacity: 1, delay: 0.2 }
      );
      gsap.fromTo(
        imageBottomRef.current,
        { y: 100, opacity: 0 },
        { duration: 1, y: 0, opacity: 1, delay: 0.3 }
      );
    }
  }, []);

  const handleButtonClick = () => {
    navigate("/onboarding");
  };

  return (
    <ContainerHero id="hero">
      <LinesHero src={LinesImage} alt="Fondo decorativo con líneas curvas" />
      <ContainerBase>
        <ContainerCenter>
          <Title ref={titleRef}>
            Transforma la Atención al Cliente con <span>Blox</span>
          </Title>
          <Description
            ref={descriptionRef}
            aria-label="Descripción de Blox, el asistente virtual"
          >
            Una solución inteligente que comprende, responde y evoluciona con tu negocio.
          </Description>
          <ButtonFill
            ref={buttonRef}
            aria-label="Solicita tu demo gratis"
            onClick={handleButtonClick}
          >
            Pruébalo de Forma Gratuita
          </ButtonFill>
          <picture>
            <source media="(max-width: 767px)" srcSet={MobileHero} />
            <source media="(max-width: 1024px)" srcSet={TabletHero} />
            <ImageHero
              ref={imageBottomRef}
              src={LaptopHero}
              alt="Laptop con mensaje en la pantalla"
            />
          </picture>
        </ContainerCenter>
      </ContainerBase>
    </ContainerHero>
  );
};

export default HeroSection;