import { ContainerBase, ContainerCenter, ContainerFooter } from "../styledComponents/Containers";
import { TagSection } from "../styledComponents/Global";
import { Description, SubTitle } from "../styledComponents/Typography";
import { Card, CradsContainer, Image } from "../styledComponents/UseCases";
import ImageUseCases from '../../../assets/img/landingPage/ImageUseCases.jpeg';
import useScrollAnimation from "../../../hooks/useAnimation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { ButtonPlay } from "../styledComponents/Buttons";
import restaurant from '../../../assets/img/landingPage/restaurante.webp';
import comercio from '../../../assets/img/landingPage/comercio.webp';
import medico from '../../../assets/img/landingPage/medico.webp';
import card_bottom from '../../../assets/img/landingPage/card_bottom.webp';

const UseCasesSection = () => {
  const cardOne = useScrollAnimation({ effect: 'left', delay: 0 });
  const cardTwo = useScrollAnimation({ effect: 'top', delay: 0 });
  const cardThree = useScrollAnimation({ effect: 'right', delay: 0 });
  const cardFour = useScrollAnimation({ effect: 'bottom', delay: 0 });
  const cardFive = useScrollAnimation({ effect: 'right', delay: 0.1 });

  return (
    <ContainerFooter id="cases">
      <ContainerBase>
        <ContainerCenter>
          <TagSection>Casos de Uso</TagSection>
          <SubTitle $isBlack={false}>Blox se Adapta a Tu Industria</SubTitle>
          <Description>
            No importa si diriges un restaurante, una tienda de ropa o un consultorio médico, 
            Blox está diseñado para adaptarse a las necesidades de cualquier tipo de negocio.
          </Description>
        </ContainerCenter>
        <CradsContainer>
          <Card
            ref={cardOne}
            $bgColor="#0E6EAF"
            $textColor="#F9FBFB"
            style={{
              position: 'relative',
              backgroundImage: `url(${restaurant})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "#FFFFFF",
              overflow: 'hidden',
            }}
          >
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              zIndex: 1,
            }}></div>
            <h3 style={{ position: 'relative', zIndex: 2, color: '#FFFFFF' }}>Bares, Restaurantes y Discotecas</h3>
            <p style={{ position: 'relative', zIndex: 2, color: '#FFFFFF' }}>Gestiona reservas, toma pedidos automáticos y responde preguntas frecuentes sobre menús y horarios.</p>
          </Card>
          <Card ref={cardTwo} $bgColor="#4a4a4a" $textColor="#F9FBFB">
            <ButtonPlay>
              <FontAwesomeIcon icon={faPlay} />
            </ButtonPlay>
            <p>Ver Ahora</p>
          </Card>
          <Card
            ref={cardThree}
            $bgColor="#0E6EAF"
            $textColor="#F9FBFB"
            style={{
              position: 'relative',
              backgroundImage: `url(${medico})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "#FFFFFF",
              overflow: 'hidden',
            }}
          >
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}></div>
            <h3 style={{ position: 'relative', zIndex: 2, color: '#FFFFFF' }}>Consultorios Médicos y Jurídicos</h3>
            <p style={{ position: 'relative', zIndex: 2, color: '#FFFFFF' }}>Automatiza la programación de citas y respuestas a preguntas frecuentes sobre horarios y servicios.</p>
          </Card>
          <Card
            ref={cardFour}
            $bgColor="#FAFAFA"
            $textColor="#0F1415"
            style={{
              position: 'relative',
              backgroundImage: `url(${comercio})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "#FFFFFF",
              overflow: 'hidden',
            }}
          >
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}></div>
            <h3 style={{ position: 'relative', zIndex: 2, color: '#FFFFFF' }}>Tiendas de Comercio Electrónico</h3>
            <p style={{ position: 'relative', zIndex: 2, color: '#FFFFFF' }}>Ofrece asistencia en la disponibilidad de productos y en el proceso de compra.</p>
          </Card>
          <Card ref={cardFive} $bgColor="#FAFAFA" $textColor="#0F1415" style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${card_bottom})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: 1,
            }}>
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 2,
              }}></div>
              <Image src={card_bottom} alt="Imagen de Blox" style={{ opacity: 0, width: '100%', height: '100%' }} />
            </div>
          </Card>
        </CradsContainer>
      </ContainerBase>
    </ContainerFooter>
  );
};

export default UseCasesSection;