import { Modal, Form, Input, Button } from "antd";

interface AddProductModalProps {
  visible: boolean;
  onCancel: () => void;
  onAdd: (product: {
    name: string;
    price: string;
    shippingOptions: string[];
    description: string;
    productLink: string;
  }) => void;
}

export default function AddProductModal({
  visible,
  onCancel,
  onAdd,
}: AddProductModalProps) {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      onAdd(values);
      form.resetFields();
      onCancel();
    });
  };

  return (
    <Modal
      title="Agregar Producto"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="add" type="primary" onClick={handleOk}>
          Agregar
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Nombre del Producto"
          name="name"
          rules={[{ required: true, message: "Ingrese el nombre del producto" }]}
        >
          <Input placeholder="Ej: Laptop" />
        </Form.Item>
        <Form.Item
          label="Precio"
          name="price"
          rules={[{ required: true, message: "Ingrese el precio del producto" }]}
        >
          <Input placeholder="Ej: 1000" />
        </Form.Item>
        <Form.Item label="Opciones de Envío" name="shippingOptions">
          <Input placeholder="Ej: Envío gratis, Recogida en tienda" />
        </Form.Item>
        <Form.Item label="Descripción" name="description">
          <Input.TextArea placeholder="Ej: Laptop de alta gama" />
        </Form.Item>
        <Form.Item label="Enlace del Producto" name="productLink">
          <Input placeholder="Ej: https://mi-tienda.com/producto" />
        </Form.Item>
      </Form>
    </Modal>
  );
}