const theme = {
    token: {
        fontFamily: "'Poppins', sans-serif",
        colorPrimary: "#0E6EAF",
    },
    components: {
        Button: {
            paddingInline: 16,
            borderRadius: 8,
        },
        Input: {
            borderRadius: 8,
            colorBorder: 'rgba(134, 146, 166, 1)',
        },
        Select: {
            borderRadius: 8,
            colorBorder: 'rgba(134, 146, 166, 1)',
        },
        TextArea: {
            borderRadius: 8,
            colorBorder: 'rgba(134, 146, 166, 1)',
        },
        DatePicker: {
            borderRadius: 8,
            colorBorder: 'rgba(134, 146, 166, 1)',
        }
    }
};

export default theme;
