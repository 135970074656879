import { Form, Input, Button, Modal, Select, TimePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = TimePicker;

interface PhysicalPoint {
  address: string;
  phone: string;
  schedule: [string, string];
  days: string[];
}

interface PhysicalPointModalProps {
  visible: boolean;
  onCancel: () => void;
  onAdd: (values: PhysicalPoint) => void;
}

const daysOfWeek = [
  { label: "Lunes", value: "monday" },
  { label: "Martes", value: "tuesday" },
  { label: "Miércoles", value: "wednesday" },
  { label: "Jueves", value: "thursday" },
  { label: "Viernes", value: "friday" },
  { label: "Sábado", value: "saturday" },
  { label: "Domingo", value: "sunday" },
];

export default function PhysicalPointModal({
  visible,
  onCancel,
  onAdd,
}: PhysicalPointModalProps): JSX.Element {
  const [form] = Form.useForm();

  const handleFinish = (values: any) => {
    // Convertir `dayjs` a cadenas de texto
    const schedule = values.schedule.map((time: dayjs.Dayjs) =>
      time.format("HH:mm")
    );
    onAdd({ ...values, schedule });
    form.resetFields();
  };

  return (
    <Modal
      title="Agregar punto de venta"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="Dirección"
          name="address"
          rules={[{ required: true, message: "Por favor ingrese la dirección" }]}
        >
          <Input placeholder="Ingrese la dirección" />
        </Form.Item>
        <Form.Item
          label="Teléfono"
          name="phone"
          rules={[{ required: true, message: "Por favor ingrese el teléfono" }]}
        >
          <Input placeholder="Ingrese el teléfono" />
        </Form.Item>
        <Form.Item
          label="Horario"
          name="schedule"
          rules={[{ required: true, message: "Por favor seleccione el horario" }]}
        >
          <RangePicker
            format="HH:mm"
            use12Hours
            placeholder={["Hora de apertura", "Hora de cierre"]}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Días de apertura"
          name="days"
          rules={[{ required: true, message: "Por favor seleccione los días" }]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Seleccione los días"
            options={daysOfWeek}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Guardar punto de venta
        </Button>
      </Form>
    </Modal>
  );
}