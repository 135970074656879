import styled from 'styled-components';
import '../../../assets/css/fonts.css';

export const Title = styled.h1`
    font-family: libre-franklin-bold;
    font-size: 58px;
    line-height: normal;
    letter-spacing: 2%;
    text-align: center;
    color: #FAFAFA;
    margin: 0;
    span {
        background: linear-gradient(90deg, #0E6EAF 0%, #CAF0F8 38.02%, #7468FC 75.83%, #0E6EAF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
    }
    @media (max-width: 480px) {
        font-size: 34px;
    }
`;

export const SubTitle = styled(Title).attrs<{ $isBlack?: boolean; $align?: string }>((props) => ({
    $isBlack: props.$isBlack,
    $align: props.$align,
}))`
    color: ${({ $isBlack }) => ($isBlack ? 'rgba(24, 24, 24, 1)' : '#FAFAFA')};
    font-size: 48px;
    line-height: normal;
    text-align: ${({ $align }) => ($align ? $align : 'center')};
    @media (max-width: 480px) {
        font-size: 28px;
    }
`;

export const Description = styled.p.attrs<{ $align?: string; $width?: string }>((props) => ({
    $align: props.$align,
    $width: props.$width,
}))`
    font-family: poppins-regular;
    font-size: 16px;
    line-height: normal;
    text-align: ${({ $align }) => ($align ? $align : 'center')};
    color: #696F79;
    width: ${({ $width }) => ($width ? $width : '50%')};
    margin: 0;
    @media (max-width: 1024px) {
        width: 80%;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;
