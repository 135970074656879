import { useEffect, useRef } from "react";
import {
  ContainerBase,
  ContainerCenter,
  ContainerCenterProblem,
  ContainerFooter,
} from "../styledComponents/Containers";
import { ImageChat, ListSection, TagSection } from "../styledComponents/Global";
import { Description, SubTitle } from "../styledComponents/Typography";
import ImageProblem from "../../../assets/img/landingPage/ImageProblem.webp";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

gsap.registerPlugin(ScrollTrigger);

const ProblemSection = () => {
  const textRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const textAnim = gsap.fromTo(
      textRef.current,
      { x: -200, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 80%",
          end: "top 30%",
          scrub: true,
          invalidateOnRefresh: true,
        },
      }
    );

    const imageAnim = gsap.fromTo(
      imageRef.current,
      { x: 200, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: imageRef.current,
          start: "top 80%",
          end: "top 30%",
          scrub: true,
          invalidateOnRefresh: true,
        },
      }
    );

    return () => {
      textAnim.kill();
      imageAnim.kill();
    };
  }, []);
  return (
    <ContainerFooter id="problem">
      <ContainerBase>
        <ContainerCenter $isInline={true} $justify="space-between" $wrap={true}>
          <ContainerCenterProblem
            ref={textRef}
            $align="flex-start"
            $width="50%"
            aria-label="Descripción de los problemas comunes en la atención al cliente"
          >
            <TagSection>¿Qué Resolvemos?</TagSection>
            <SubTitle as={"h3"} $align="left">
              El Desafío de la Atención al Cliente
            </SubTitle>
            <Description $align="left" $width="100%">
              ¿Tus clientes enfrentan largos tiempos de espera? ¿Tienes que
              responder las mismas preguntas repetitivas una y otra vez? La
              falta de automatización en la atención al cliente puede aumentar
              los costos, frustrar a tus empleados y, peor aún, alejar a tus
              clientes.
            </Description>
            <ListSection aria-label="Lista de problemas comunes en la atención al cliente">
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Tiempos</span> de respuesta <span>largos</span>.
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Altos costos</span> en la gestión de atención al cliente.
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Pérdida de clientes</span> debido a una mala experiencia
                de servicio.
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Sobrecarga en el personal</span> por consultas
                repetitivas.
              </li>
            </ListSection>
          </ContainerCenterProblem>
          <ImageChat
            ref={imageRef}
            aria-label="Imagen ilustrativa de un teléfono con un chat de restaurante"
          >
            <img src={ImageProblem} alt="Teléfono con chat de restaurante" />
          </ImageChat>
        </ContainerCenter>
      </ContainerBase>
    </ContainerFooter>
  );
};

export default ProblemSection;
