import React, { useState, useEffect } from "react";
import { Card, Row, Col, message, Modal } from "antd";
import TableComponent from "../../../components/table/table";
import FoodModal from "../../../components/modals/FoodModal";
import { get, post, put, del } from "../../../Api/http/httpClient";

const { confirm } = Modal;

interface FoodDataProps {
  user_data: any;
}

const FoodData: React.FC<FoodDataProps> = ({ user_data }) => {
  const [data, setData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFood, setSelectedFood] = useState<any | null>(null);
  const [action, setAction] = useState<any | null>(null);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    category: "",
    description: "",
  });
  const [menuCategories, setMenuCategories] = useState<string[]>([]);

  const headers_table = {
    id: "ID",
    name: "Nombre",
    price: "Precio",
    category: "Categoría",
    description: "Descripción",
  };

  const getFoodData = async () => {
    setLoading(true);
    setDeleting(null);
    try {
      const res = await get(
        `food?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}&page=${page}&page_size=${itemsPerPage}&search=${search}&sort_by=${sortColumn}&order_by=${sortOrder}`
      );

      if (res.answer && res.items) {
        const formattedFoodItems = res.items.map((food: any) => ({
          id: food.food_id || null,
          name: food.name_food,
          price: food.price,
          category: food.category,
          description: food.description,
        }));
        setData(formattedFoodItems);

        const totalItems = res.total_items;
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      } else {
        message.error("No se encontraron comidas.");
      }
    } catch (error) {
      message.error("Error al obtener los datos de comidas.");
    } finally {
      setLoading(false);
    }
  };

  const getCategories = async () => {
    try {
      const res = await get(
        `menu-category?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}&fetch_all=true`
      );

      if (res.answer) {
        setMenuCategories(res.items || []);
      } else {
        message.error("No se encontraron categorías.");
      }
    } catch (error) {
      message.error("Error al obtener las categorías.");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getFoodData();
  }, [page, itemsPerPage, search, sortOrder, sortColumn]);

  const setActionHandler = (action: { type: string; data?: any }) => {
    if (action.type === "edit") {
      setSelectedFood(action.data);
      setFormData({
        name: action.data.name,
        price: action.data.price,
        category: action.data.category,
        description: action.data.description,
      });
      setAction(action);
      handleOpenModal();
    } else if (action.type === "delete") {
      setAction(action);
      showDeleteConfirm(action.data);
    } else if (action.type === "create") {
      setFormData({ name: "", price: "", category: "", description: "" });
      setAction(action);
      handleOpenModal();
    } else if (action.type === "reload") {
      getFoodData();
    }
  };

  const showDeleteConfirm = (food: any) => {
    confirm({
      title: "¿Estás seguro de eliminar esta comida?",
      content: `Comida: ${food.name_food}`,
      okText: "Sí, eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        handleDelete(food);
      },
      onCancel() {
        setDeleting(null);
      },
    });
  };

  const handleDelete = async (food: any) => {
    setDeleting(food.id);

    try {
      const res = await del("food", {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        food_id: food.id,
      });

      if (res && res.answer) {
        message.success("Comida eliminada correctamente");
        getFoodData();
      } else {
        message.error("Error al eliminar la comida.");
      }
    } catch (error) {
      message.error("Error al eliminar la comida.");
    } finally {
      setDeleting(null);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedFood(null);
    setAction(null);
  };

  const handleSave = async (): Promise<boolean> => {
    setLoading(true);
    try {
      const res = await post("food", {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        price: formData.price,
        name_food: formData.name,
        description: formData.description,
        category: formData.category,
      });

      if (res && res.answer) {
        message.success("Comida creada correctamente");
        handleCloseModal();
        getFoodData();
        return true;
      } else {
        message.error("Error al crear la comida.");
        return false;
      }
    } catch (error) {
      message.error("Error al crear la comida.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (): Promise<boolean> => {
    setLoading(true);
    try {
      if (selectedFood) {
        const res = await put("food", {
          establishmentID: user_data["custom:establishment_id"],
          name_establishment: user_data.family_name,
          food_id: selectedFood.id,
          price: formData.price,
          name_food: formData.name,
          description: formData.description,
          category: formData.category,
        });

        if (res && res.answer) {
          const updatedData = data.map((item) =>
            item.id === selectedFood.id ? { ...item, ...formData } : item
          );
          setData(updatedData);
          message.success("Comida actualizada correctamente");
          handleCloseModal();
          return true;
        } else {
          message.error("Error al actualizar la comida.");
          return false;
        }
      } else {
        message.error("No se seleccionó ninguna comida para actualizar.");
        return false;
      }
    } catch (error) {
      message.error("Error al actualizar la comida.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={23} style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Card
          bordered={false}
          style={{
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "0px",
          }}
        >
          <div style={{ padding: "0px" }}>
            <TableComponent
              data={data}
              headers_table={headers_table}
              loading={loading}
              setSearch={setSearch}
              setAction={setActionHandler}
              totalPages={totalPages}
              setPage={setPage}
              page={page}
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage}
              setSortOrder={setSortOrder}
              setSortColumn={setSortColumn}
              deleting={deleting}
            />
          </div>
        </Card>
      </Col>

      <FoodModal
        open={modalOpen}
        onClose={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSave={handleSave}
        handleUpdate={handleUpdate}
        action={action}
        categories={menuCategories}
      />
    </Row>
  );
};

export default FoodData;
