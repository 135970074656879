import styled from "styled-components";

export const Features = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 100px;
    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 50px;
    }
`;

export const FeaturesHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    text-align: left;
    position: sticky;
    top: 150px;
    h2, p {
        width: 100%;
        text-align: left;
        margin: 0;
    }
    @media (max-width: 1024px) {
        position: relative;
        top: 0;
        align-items: center;
        h2, p {
            text-align: center;
        }
    }
`;

export const FeaturesList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: rgba(24, 24, 24, 1);
    font-family: poppins-regular;
    font-size: 45px;
    line-height: normal;
    gap: 100px;
    @media (max-width: 1024px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
    }
`;

export const FeaturesListItem = styled.div`
    display: flex;
    align-items: last baseline;    
    gap: 40px;
    span {
        font-family: poppins-bold;
        font-size: 90px;
        -webkit-text-stroke: 1px rgba(24, 24, 24, 1);
        color: transparent;
    }
    @media (max-width: 1024px) {
        width: 45%;
        font-size: 28px;
    }
    @media (max-width: 780px) {
        width: 100%;
    }
`;