import styled from 'styled-components';
import { Field, Form } from 'formik';

export const FormContent = styled(Form)`
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    label {
        font-family: poppins-semibold;
        font-size: 16px;
        color: rgba(105, 111, 121, 1);
        line-height: normal;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const FormGroup = styled.div`
    width: 100%;
    display: flex;
    gap: 24px;
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const FormItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const InputText = styled(Field)`
    height: 56px;
    border-radius: 8px;
    padding: 0 16px;
    font-family: poppins-semibold;
    font-weight: 600;
    font-size: 16px;
    color: rgba(134, 146, 166, 1);
    background: rgba(40, 42, 47, 1);
    outline: none;
    border: 1px solid #8692A6;
    &:hover {
        &::placeholder {
            color: #fafafa;
        }
    }
    &:focus {
        border-width: 2px;
        border-color: #fafafa;
    }
`;

export const TextArea = styled(Field).attrs({ as: 'textarea' })`
    min-height: 120px;
    padding: 16px;
    border-radius: 8px;
    font-family: poppins-semibold;
    font-weight: 600;
    font-size: 16px;
    color: rgba(134, 146, 166, 1);
    background: rgba(40, 42, 47, 1);
    outline: none;
    border: 1px solid #8692A6;
    resize: vertical;

    &:hover {
        &::placeholder {
            color: #fafafa;
        }
    }

    &:focus {
        border-width: 2px;
        border-color: #fafafa;
    }
`;

export const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: -6px;
`;


