// Dashboard.tsx
import React, { useEffect, useState } from "react";
import CustomSidenav from "../../components/sidebar/Sidebar";
import DashboardRoutes from "../../utils/DashboardRoutes";
import { fetchUserAttributes } from "aws-amplify/auth";
import { Spin } from "antd";

interface DashboardProps {
  signOut: () => void;
  user_data: any;
}

const Dashboard: React.FC<DashboardProps> = ({ signOut, user_data }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <div
        style={{
          height: "100vh",
          position: "fixed",
          width: collapsed ? "80px" : "220px",
          transition: "width 0.3s",
          zIndex: 1000,
        }}
      >
        <CustomSidenav
          signOut={signOut}
          user_data={user_data}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </div>

      <div
        style={{
          flexGrow: 1,
          marginLeft: collapsed ? "90px" : "230px",
          transition: "margin-left 0.3s",
          overflowY: "auto",
          height: "100vh",
        }}
      >
        {user_data ? (
          <DashboardRoutes user_data={user_data} />
        ) : (
          <Spin size="large" style={{ display: "block", margin: "auto" }} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
