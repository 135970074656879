import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { ButtonWhatsApp } from "../styledComponents/Global";

const ButtonWhatsapp = () => {
  const whatsappNumber = "1234567890";
  return (
    <ButtonWhatsApp
      href={`https://wa.me/${whatsappNumber}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "22px" }} />
      <p>¡Hablemos por WhatsApp!</p>
    </ButtonWhatsApp>
  );
};

export default ButtonWhatsapp;
