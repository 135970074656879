import {
  ContainerBase,
  ContainerCenter,
  ContainerCenterSolution,
  ContainerFooter,
  ImageSoluction,
} from "../styledComponents/Containers";
import { ListSection, TagSection } from "../styledComponents/Global";
import { Description, SubTitle } from "../styledComponents/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import Spline from "@splinetool/react-spline";

const SolutionSection = () => {
  return (
    <ContainerFooter id="solution">
      <ContainerBase>
        <ContainerCenter $isInline={true} $justify="flex-end" $wrap={true}>
          <ImageSoluction aria-label="Modelo 3D interactivo de un asistente virtual inteligente">
            <Spline scene="https://prod.spline.design/Tz3tpp7fryZjKMl7/scene.splinecode" />
          </ImageSoluction>
          <ContainerCenterSolution $align="flex-start" $width="50%">
            <TagSection>Propuesta de Valor</TagSection>
            <SubTitle as={"h3"} $align="left">
              La Solución: Blox, tu Asistente Virtual Inteligente
            </SubTitle>
            <Description $align="left" $width="100%">
              Blox es una IA diseñada para automatizar y mejorar la atención al
              cliente en cualquier tipo de negocio. Desde restaurantes hasta
              consultorios médicos, Blox se adapta para ofrecer respuestas
              rápidas, personalizadas y disponibles 24/7, mientras reduce tus
              costos operativos.
            </Description>
            <ListSection aria-label="Características clave de la solución Blox">
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Disponibilidad 24/7</span> para atender consultas en
                tiempo real.
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Reducción de costos</span> en la gestión de atención al
                cliente.
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Respuesta simultánea</span> a múltiples clientes sin
                sobrecargar a tu personal.
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Análisis automatizado</span> de interacciones para mejorar
                la toma de decisiones.
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ fontSize: 22 }}
                  aria-hidden="true"
                />
                <span>Integración fácil</span> con WhatsApp.
              </li>
            </ListSection>
          </ContainerCenterSolution>
        </ContainerCenter>
      </ContainerBase>
    </ContainerFooter>
  );
};

export default SolutionSection;
