import React from 'react';
import { Typography, Row, Col } from 'antd';
import './Home.css'; // Importa el archivo CSS
import logo from "../../../assets/img/dashboard/logo_horizontal.webp";

const { Title, Text } = Typography;

interface WelcomeProps {
  user_data: any; // Define la estructura o tipo de los datos del usuario si lo conoces
}

const Home: React.FC<WelcomeProps> = ({ user_data }) => {
  return (
    <Row justify="center" align="middle" className="home-container">
      <Col xs={22} sm={18} md={16} lg={10}>
        <img
          src={logo}
          alt="Amplify Logo"
          className="responsive-logo"
        />
        <Title level={1} className="home-title">
          ¡Bienvenido!
        </Title>
        <Text strong className="home-text-strong">
          Hola, {user_data.family_name || 'Usuario'}.
        </Text>
        <Text type="secondary" className="home-text-secondary">
          Este es tu panel de control.
        </Text>
      </Col>
    </Row>
  );
};

export default Home;