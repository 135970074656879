import React, { useState, useEffect } from "react";
import { Button, Skeleton, message } from "antd";
import { getCurrentUser } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import {
  ContainerBase,
  ContainerCenter,
  ContainerFooter,
  PricesList,
} from "../../components/landingpage/styledComponents/Containers";
import { TagSection } from "../../components/landingpage/styledComponents/Global";
import { Description, SubTitle } from "../../components/landingpage/styledComponents/Typography";
import { CardPrice } from "../../components/landingpage/styledComponents/Cards";
import { ButtonFill, ButtonOutline } from "../../components/landingpage/styledComponents/Buttons";
import IconCheck from "../../assets/img/landingPage/check-circle.png";
import { get } from "../../Api/http/httpClient";
import logo from "../../assets/img/dashboard/logo horizontal_BN.webp"
import "./Plans.css";

interface Plan {
  title: string;
  description: string;
  messages: string;
  price: string | number;
  features: string[];
  code_plan: string;
  isCustom?: boolean;
}

const Plans = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false));

    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await get("plans?fetch_all=true");
      if (response.answer) {
        const formattedPlans = response.items
          .map((plan: any) => ({
            title: plan.plan_name,
            description: plan.plan_description, // Add description here
            messages: plan.plan_message_count,
            price: plan.isCustom === "true" ? "Plan cotizado" : parseFloat(plan.plan_price),
            features: plan.plan_features,
            code_plan: plan.plan_id,
            isCustom: plan.isCustom === "true",
          }))
          .sort((a: Plan, b: Plan) => {
            if (a.isCustom) return 1;
            if (b.isCustom) return -1;
            return typeof a.price === "number" && typeof b.price === "number"
              ? a.price - b.price
              : 0;
          });

        setPlans(formattedPlans);
      } else {
        message.error("No se pudieron cargar los planes.");
      }
    } catch (error) {
      message.error("Error al cargar los planes.");
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (price: string | number) => {
    if (typeof price === "string") return price;
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handlePurchase = (plan: Plan) => {
    if (plan.isCustom) {
      navigate("/enterprise-form");
    } else {
      navigate("/checkout", { state: { plan } });
    }
  };

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <header className="plans-header">
        <div className="logo-container">
          <img
            src={logo}
            alt="Logo"
            className="logo"
          />
        </div>
        <Button
          type="primary"
          className="dashboard-button"
          onClick={handleGoToDashboard}
        >
          Ir al Dashboard
        </Button>
      </header>

      <ContainerFooter style={{ marginTop: '20px' }}>
        <ContainerBase>
          <ContainerCenter>
            <SubTitle>Planes Flexibles para Cada Tipo de Negocio</SubTitle>
            <Description>
              Elige el plan que mejor se adapte a las necesidades de tu negocio.
              No importa si eres una pequeña tienda o una gran corporación,
              tenemos un plan para ti.
            </Description>
          </ContainerCenter>
          {isLoading ? (
            <PricesList>
              {Array.from({ length: 5 }).map((_, index) => (
                <CardPrice key={index}>
                  <Skeleton active paragraph={{ rows: 4 }} />
                </CardPrice>
              ))}
            </PricesList>
          ) : (
            <PricesList style={{ gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))" }}>
              {plans.map((plan, index) => (
                <CardPrice key={index} className={plan.isCustom ? "custom-plan" : ""}>
                  <h3>{plan.title}</h3>
                  <p className="plan-description">{plan.description}</p> {/* Display description */}
                  <p>
                    <span>{formatPrice(plan.price)}</span> / mes
                  </p>
                  <p>{plan.messages} mensajes</p>
                  <hr />
                  <ul>
                    {plan.features.map((feature, i) => (
                      <li key={i}>
                        <img src={IconCheck} alt="Ícono de verificación" aria-hidden="true" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <ContainerCenter>
                    {plan.isCustom ? (
                      <ButtonOutline onClick={() => handlePurchase(plan)}>
                        Contactarnos
                      </ButtonOutline>
                    ) : (
                      <ButtonFill onClick={() => handlePurchase(plan)}>
                        Comprar Ahora
                      </ButtonFill>
                    )}
                  </ContainerCenter>
                </CardPrice>
              ))}
            </PricesList>
          )}
        </ContainerBase>
      </ContainerFooter>
    </>
  );
};

export default Plans;