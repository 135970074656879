import { ContainerLanding } from '../../components/landingpage/styledComponents/Containers';
import Header from '../../components/landingpage/Header/Header';
import Footer from '../../components/landingpage/Footer/Footer';
import FeaturesSection from '../../components/landingpage/Features/Features';
import UseCasesSection from '../../components/landingpage/UseCases/UseCases';
import HeroSection from '../../components/landingpage/Hero/HeroSection';
import TestimonialsSection from '../../components/landingpage/testimonials/TestimonialsSection';
import DemoSection from '../../components/landingpage/Demo/DemoSection';
import PricesSection from '../../components/landingpage/Prices/PricesSection';
import CtaSection from '../../components/landingpage/Cta/CtaSection';
import ProblemSection from '../../components/landingpage/Problem/ProblemSection';
import SolutionSection from '../../components/landingpage/Solution/SolutionSection';
import ButtonWhatsapp from '../../components/landingpage/ButtonWhatsapp/ButtonWhatsapp';

const LandingPage = () => {
  return (
    <ContainerLanding>

        <ButtonWhatsapp />

        <Header />

        <HeroSection />

        <ProblemSection />

        <SolutionSection />

        <FeaturesSection />

        <UseCasesSection />

        {/* <TestimonialsSection /> */}

        {/* <DemoSection /> */}

        <PricesSection />

        <CtaSection />

        <Footer />

    </ContainerLanding>
  )
}

export default LandingPage;