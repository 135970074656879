import {
  ContainerBase,
  ContainerCenter,
  ContainerCTA,
} from "../styledComponents/Containers";
import { ImageCTA, TagSection } from "../styledComponents/Global";
import { Description, SubTitle } from "../styledComponents/Typography";
import {
  ButtonFill,
  ButtonGroup,
  ButtonOutline,
} from "../styledComponents/Buttons";
import ImageCta from "../../../assets/img/landingPage/MobileCTA.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import useScrollAnimation from "../../../hooks/useAnimation";
import { useNavigate } from "react-router-dom";

const CtaSection = () => {
  const textRef = useScrollAnimation({ effect: "right", delay: 0 });
  const imageRef = useScrollAnimation({ effect: "bottom", delay: 0 });
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/onboarding");
  };

  return (
    <ContainerCTA id="cta">
      <ContainerBase>
        <ContainerCenter
          $isInline={true}
          $justify="right"
          className="containerCenterCTA"
        >
          <ImageCTA ref={imageRef}>
            <img
              src={ImageCta}
              alt="Celular mostrando un mensaje de atención al cliente automatizado"
            />
          </ImageCTA>
          <ContainerCenter
            $align="flex-start"
            $width="60%"
            className="containerCenterColumnRight"
            ref={textRef}
          >
            <TagSection>Contáctanos</TagSection>
            <SubTitle as={"h3"} $isBlack={true} $align="left">
              ¡Empieza Hoy Mismo a Optimizar tu Atención al Cliente!
            </SubTitle>
            <Description $align="left" $width="100%">
              No esperes más para transformar la experiencia de tus clientes.
              Automatiza, optimiza y revoluciona la gestión de tu negocio con
              Blox. ¡Entrena y prueba a Blox de forma gratuita, descubre todo lo que puede hacer por ti!
            </Description>
            <ButtonGroup $justify="flex-start">
              <ButtonFill onClick={handleButtonClick}>Pruébalo de Forma Gratuita</ButtonFill>
              <ButtonOutline aria-label="Habla con un especialista">
                <FontAwesomeIcon icon={faHeadset} aria-hidden="true" />
                Habla con un Especialista
              </ButtonOutline>
            </ButtonGroup>
          </ContainerCenter>
        </ContainerCenter>
      </ContainerBase>
    </ContainerCTA>
  );
};

export default CtaSection;