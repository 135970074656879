import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";

interface ChangePasswordModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        // Simulación de cambio de contraseña
        setTimeout(() => {
          setLoading(false);
          setIsModalVisible(false);
          form.resetFields();
          message.success("Contraseña cambiada exitosamente");
        }, 1500);
      })
      .catch(() => {
        message.error("Por favor, corrige los errores del formulario");
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Cambiar Contraseña"
      open={isModalVisible} // Cambiado de `visible` a `open`
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          Cambiar Contraseña
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="currentPassword"
          label="Contraseña Actual"
          rules={[
            { required: true, message: "Por favor ingresa tu contraseña actual" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="Nueva Contraseña"
          rules={[
            { required: true, message: "Por favor ingresa tu nueva contraseña" },
            { min: 8, message: "La contraseña debe tener al menos 8 caracteres" },
            {
              pattern: /[A-Z]/,
              message: "La contraseña debe contener al menos una letra mayúscula",
            },
            {
              pattern: /[a-z]/,
              message: "La contraseña debe contener al menos una letra minúscula",
            },
            {
              pattern: /[0-9]/,
              message: "La contraseña debe contener al menos un número",
            },
            {
              pattern: /[\W_]/,
              message:
                "La contraseña debe contener al menos un carácter especial (ej: @, #, $, etc.)",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmNewPassword"
          label="Confirmar Nueva Contraseña"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Por favor confirma tu nueva contraseña" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Las contraseñas no coinciden"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;