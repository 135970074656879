import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  FaUserCircle,
  FaClock,
  FaFileAlt,
  FaImages,
  FaHome,
  FaSignOutAlt,
  FaUtensils,
  FaDrumstickBite,
  FaComments,
  FaDatabase,
  FaUsers,
  FaWhatsapp,
  FaCreditCard,
  FaClipboardList,
  FaBox,
  FaChartBar,
} from "react-icons/fa";
import { MdMiscellaneousServices, MdPolicy } from "react-icons/md";
import { Spin } from "antd";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/noti.json";
import logo from "../../assets/img/dashboard/logo horizontal_BN.webp";
import "./Sidebar.css"

interface CustomSidenavProps {
  signOut: () => void;
  user_data: any;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomSidenav: React.FC<CustomSidenavProps> = ({
  signOut,
  user_data,
  collapsed,
  setCollapsed,
}) => {
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [activeItem, setActiveItem] = useState<string>(""); // Estado del ítem seleccionado
  const [openSubmenu, setOpenSubmenu] = useState<string>(""); // Estado del submenú abierto
  const navigate = useNavigate();

  useEffect(() => {
    if (user_data) {
      setLoadingUserData(false); // Cambia el estado cuando user_data está disponible
    }
  }, [user_data]);

  const isAdmin = user_data?.["custom:group_user"] === "Administrador";

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const modules = [
    {
      label: "General",
      icon: <FaHome />,
      isSubmenu: true,
      items: [
        {
          label: "Datos",
          path: "/dashboard/general-data",
          icon: <FaFileAlt />,
        },
        {
          label: "Servicios",
          path: "/dashboard/general-services",
          icon: <MdMiscellaneousServices />,
        },
        { label: "Horarios", path: "/dashboard/schedule", icon: <FaClock /> },
        {
          label: "Políticas",
          path: "/dashboard/policy-data",
          icon: <MdPolicy />,
        },
        {
          label: "Multimedia",
          path: "/dashboard/multimedia",
          icon: <FaImages />,
        },
        {
          label: "Menú",
          icon: <FaUtensils />,
          isSubmenu: true,
          items: [
            {
              label: "Categorías",
              path: "/dashboard/menu-categories",
              icon: <FaFileAlt />,
            },
            {
              label: "Comidas",
              path: "/dashboard/food-data",
              icon: <FaDrumstickBite />,
            },
          ],
        },
        {
          label: "FAQs",
          path: "/dashboard/faqs",
          icon: <FaFileAlt />,
          isSubmenu: false,
        },
      ],
    },
    {
      label: "Pagos",
      path: "/dashboard/payment-data",
      icon: <FaCreditCard />,
    },
    ...(isAdmin
      ? [
          {
            label: "Planes",
            path: "/dashboard/plans-data",
            icon: <FaClipboardList />,
          },
          {
            label: "Paquetes",
            path: "/dashboard/packages-data",
            icon: <FaBox />,
          },
        ]
      : []),
    {
      label: "Chats",
      icon: <FaComments />,
      isSubmenu: true,
      items: [
        {
          label: "Chat Interno",
          path: "/dashboard/normal-chat",
          icon: <FaUsers />,
        },
        {
          label: "Gestionar WhatsApp",
          path: "/dashboard/whatsapp-chat",
          icon: <FaWhatsapp />,
        },
      ],
    },
    {
      label: "Historial de Chats",
      path: "/dashboard/chat-data",
      icon: <FaDatabase />,
    },
    {
      label: "Analítica",
      path: "/dashboard/analytics",
      icon: <FaChartBar />,
    },
  ];

  const handleMenuItemClick = (itemLabel: string, path: string | undefined, isSubmenu: boolean) => {
    if (isSubmenu) {
      setOpenSubmenu((prev) => (prev === itemLabel ? "" : itemLabel)); // Alterna el submenú
      setActiveItem(""); // Deselecciona cualquier elemento hoja
    } else {
      setActiveItem(itemLabel); // Marca el ítem como seleccionado
      setOpenSubmenu(""); // Cierra cualquier submenú abierto
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
    window.location.reload();
  };

  const renderSubMenu = (items: any[], parentLabel: string) => {
    return items.map((item, index) => {
      if (item.isSubmenu) {
        return (
          <SubMenu
            key={index}
            label={item.label}
            icon={item.icon}
            defaultOpen={openSubmenu === item.label}
            onOpenChange={() => handleMenuItemClick(item.label, undefined, true)}
            style={{ color: "#bccde9", backgroundColor: "#002045" }}
          onMouseEnter={(e) => {
            e.currentTarget.style.color = "#bccde9";
            e.currentTarget.style.backgroundColor = "#002045"; 
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.color = "#bccde9"; 
            e.currentTarget.style.backgroundColor = "002045"; 
          }}
          >
            {renderSubMenu(item.items, item.label)}
          </SubMenu>
        );
      } else {
        return (
          <Link
            key={index}
            to={item.path || "#"}
            className={`menu-item ${activeItem === item.label ? "active" : ""}`}
            onClick={() => handleMenuItemClick(item.label, item.path, false)}
          >
            <MenuItem
              icon={item.icon}
              style={{
                color: activeItem === item.label ? "#fff700" : "#bccde9", 
                backgroundColor: activeItem === item.label ? "transparent" : "#002045",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.color = "#000000";
                e.currentTarget.style.backgroundColor = "#ffffff";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = activeItem === item.label ? "#fff700" : "#bccde9";
                e.currentTarget.style.backgroundColor = activeItem === item.label ? "#002045" : "#002045";
              }}
            >
              {item.label}
            </MenuItem>
          </Link>
        );
      }
    });
  };

  const renderMenu = () => {
    return modules.map((module, index) => {
      if (module.isSubmenu) {
        return (
          <SubMenu
          key={index}
          label={module.label}
          icon={module.icon}
          defaultOpen={openSubmenu === module.label}
          onOpenChange={() => handleMenuItemClick(module.label, undefined, true)}
          className={openSubmenu === module.label ? "submenu-active" : ""}
          style={{ color: "#bccde9" }}
          onMouseEnter={(e) => {
            e.currentTarget.style.color = "#000000";
            e.currentTarget.style.backgroundColor = "#fff"; 
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.color = "#bccde9";
            e.currentTarget.style.backgroundColor = "transparent";
          }}
        >
          {renderSubMenu(module.items, module.label)}
        </SubMenu>
        );
      } else {
        return (
          <Link
            key={index}
            to={module.path || "#"}
            className={`menu-item ${activeItem === module.label ? "active" : ""}`}
            onClick={() => handleMenuItemClick(module.label, module.path, false)}
            style={{
              color: activeItem === module.label ? "#fff700" : "#bccde9",
              backgroundColor: activeItem === module.label ? "#e0e0e0" : "transparent",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#000000";
              e.currentTarget.style.backgroundColor = "#ffffff";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = activeItem === module.label ? "#fff700" : "#bccde9";
              e.currentTarget.style.backgroundColor = activeItem === module.label ? "#fff" : "transparent";
            }}
          >
            <MenuItem icon={module.icon}>{module.label}</MenuItem>
          </Link>
        );
      }
    });
  };

  if (loadingUserData) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <Spin size="large" style={{ color: "#00152D" }} />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
      <Sidebar collapsed={collapsed} backgroundColor="#00152D">
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              cursor: "pointer",
              padding: "10px",
              color: "#fff",
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? (
              <IoIosArrowForward size={20} color="white" />
            ) : (
              <>
                <IoIosArrowBack size={20} color="white" />
                <span
                  style={{
                    marginLeft: "5px",
                    color: "#fff",
                    fontSize: "0.9em",
                  }}
                >
                  Colapsar
                </span>
              </>
            )}
          </div>

          <Link
            to="/dashboard"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ height: collapsed ? "5vh" : "10vh", margin: "20px" }}
            />
          </Link>

          <div
            style={{
              textAlign: "center",
              fontSize: collapsed ? "0.8em" : "1.2em",
              color: "#bccde9",
            }}
          >
            <FaUserCircle /> {user_data ? user_data.family_name : ""}
          </div>

          <hr
            style={{
              border: "0.3px solid #ccc",
              width: "100%",
              alignSelf: "center",
            }}
          />

          <Menu transitionDuration={1000}>{renderMenu()}</Menu>

          <Menu style={{ marginTop: "auto" }}>
          <Link to="/dashboard/whatsapp-integration" className="menu-item">
            <MenuItem
              icon={<FaWhatsapp />}
              style={{
                color: activeItem === "Integración" ? "#fff700" : "#bccde9", // Texto amarillo si está activo
                backgroundColor: "transparent", // Fondo transparente
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.color = "#000000"; // Texto negro al hacer hover
                e.currentTarget.style.backgroundColor = "#ffffff"; // Fondo blanco al hacer hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = activeItem === "Integración" ? "#fff700" : "#bccde9"; // Volver al texto amarillo si está activo
                e.currentTarget.style.backgroundColor = "transparent"; // Fondo transparente
              }}
              onClick={() => handleMenuItemClick("Integración", "/dashboard/whatsapp-integration", false)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <span>Integración</span>
                <div style={{ width: "30px", height: "30px" }}>
                  <Lottie options={lottieOptions} height={30} width={30} />
                </div>
              </div>
            </MenuItem>
          </Link>

            <Link
              to="/dashboard/user-profile"
              className="menu-item"
              onClick={() => handleMenuItemClick("Perfil", "/dashboard/user-profile", false)}
            >
              <MenuItem
                icon={<FaUserCircle />}
                style={{
                  color: activeItem === "Perfil" ? "#fff700" : "#bccde9", // Texto amarillo si está activo
                  backgroundColor: "transparent", // Fondo transparente siempre
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.color = "#000000"; // Texto negro al hacer hover
                  e.currentTarget.style.backgroundColor = "#ffffff"; // Fondo blanco al hacer hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = activeItem === "Perfil" ? "#fff700" : "#bccde9"; // Volver al color amarillo si está activo
                  e.currentTarget.style.backgroundColor = "transparent"; // Fondo transparente
                }}
              >
                Perfil
              </MenuItem>
            </Link>
            <MenuItem
              onClick={handleSignOut}
              icon={<FaSignOutAlt />}
              style={{
                color: "#bccde9",
                backgroundColor: "transparent",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.color = "#000000"; // Texto negro
                e.currentTarget.style.backgroundColor = "#ffffff"; // Fondo blanco
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = "#bccde9"; // Volver al color original
                e.currentTarget.style.backgroundColor = "transparent"; // Fondo transparente
              }}
            >
              Cerrar sesión
            </MenuItem>

          </Menu>
        </div>
      </Sidebar>
    </div>
  );
};

export default CustomSidenav;