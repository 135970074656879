import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Select, Button, Spin, message, Typography, Switch, TimePicker } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { get, put } from "../../../Api/http/httpClient"; // Importamos get y put del httpclient
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import dayjs from "dayjs"; // Reemplazo de moment por dayjs

const { Text } = Typography;

const Schedule: React.FC<{ user_data: any }> = ({ user_data }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false); // Estado para controlar la carga del botón
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const [originalValues, setOriginalValues] = useState<any>({});
  const [hasChanges, setHasChanges] = useState(false);

  const screens = useBreakpoint();

  const daysOptions = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  // Función para convertir las horas del backend al formato interno
  const convertTimeFromBackend = (timeString: string) => {
    return dayjs(timeString, 'hh:mm A'); // Aseguramos que se usa formato de 12 horas
  };

  const fetchEstablishmentData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );
      if (response.answer) {
        const fetchedData = response.item;

        const initialValues = {
          openTime: convertTimeFromBackend(fetchedData.openTime),
          closeTime: convertTimeFromBackend(fetchedData.closeTime),
          specialHours: fetchedData.specialHours,
          specialOpenTime: fetchedData.specialOpenTime ? convertTimeFromBackend(fetchedData.specialOpenTime) : null,
          specialCloseTime: fetchedData.specialCloseTime ? convertTimeFromBackend(fetchedData.specialCloseTime) : null,
          openDays: fetchedData.openDays || [],
        };

        form.setFieldsValue(initialValues);
        setOriginalValues(initialValues);
      } else {
        setError(true);
        message.error("No se encontraron datos del establecimiento.");
      }
    } catch (error) {
      setError(true);
      message.error("Error al obtener los datos del establecimiento.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishmentData();
  }, []);

  const onValuesChange = (changedValues: any, allValues: any) => {
    const isDifferent = Object.keys(originalValues).some(key => {
      // Compara objetos tipo dayjs con isSame para tiempo
      if ((key === 'openTime' || key === 'closeTime' || key === 'specialOpenTime' || key === 'specialCloseTime') && originalValues[key] && allValues[key]) {
        return !originalValues[key].isSame(allValues[key], 'minute');
      }
      return originalValues[key] !== allValues[key];
    });
    setHasChanges(isDifferent); 
  };

  const formatTimeForBackend = (time: any) => {
    return time ? dayjs(time).format('hh:mm A') : null; // Enviar en formato 12 horas con AM/PM
  };

  const onFinish = async (values: any) => {
    const changes = Object.keys(values).reduce((acc: any, key) => {
      if (key === 'openTime' || key === 'closeTime' || key === 'specialOpenTime' || key === 'specialCloseTime') {
        const originalTime = originalValues[key];
        const newTime = values[key];
        if ((originalTime && newTime && !originalTime.isSame(newTime, 'minute')) || (originalTime && !newTime) || (!originalTime && newTime)) {
          acc[key] = formatTimeForBackend(newTime);
        }
      } else {
        if (values[key] !== originalValues[key]) {
          acc[key] = values[key];
        }
      }
      return acc;
    }, {});

    if (Object.keys(changes).length === 0) {
      message.info("No se han realizado cambios.");
      return;
    }

    try {
      setUpdating(true); // Iniciar la animación de carga en el botón

      await put("restaurant-onboarding", {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        ...changes,
      });

      message.success("Horarios actualizados correctamente.");
      fetchEstablishmentData();
      setHasChanges(false); // Desactivar el botón de guardar cambios
    } catch (error) {
      message.error("Error al actualizar los horarios del establecimiento.");
    } finally {
      setUpdating(false); // Desactivar la animación de carga
    }
  };

  // Escuchamos el valor de specialHours para mostrar u ocultar los campos de horarios especiales
  const specialHoursValue = Form.useWatch("specialHours", form);

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={screens.xs ? 24 : 22}>
        <Card 
          title="Horarios del Establecimiento" 
          bordered={false} 
          style={{ borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: screens.xs ? '10px' : '10px' }}
        >
          {loading ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "300px" }}>
              <Spin size="large" />
              <Text style={{ marginTop: "20px" }}>Cargando datos del establecimiento...</Text>
            </div>
          ) : error ? (
            <div style={{ textAlign: "center", padding: "40px" }}>
              <FrownOutlined style={{ fontSize: "64px", color: "#ff4d4f" }} />
              <Text type="danger" style={{ display: "block", fontSize: "18px", marginTop: "20px" }}>
                Ocurrió un error al cargar los datos del establecimiento.
              </Text>
              <Button 
                type="primary" 
                size="large" 
                style={{ marginTop: "20px", borderRadius: "8px" }} 
                onClick={fetchEstablishmentData}
              >
                Reintentar
              </Button>
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onValuesChange={onValuesChange}
            >
              <Row gutter={24}>
                <Col span={screens.md ? 12 : 24}>
                  <Form.Item
                    label="Hora de Apertura"
                    name="openTime"
                    rules={[{ required: true, message: "Por favor seleccione la hora de apertura" }]}
                  >
                    <TimePicker
                      use12Hours
                      format="hh:mm A" // Mostrar en formato 12 horas con AM/PM
                      size="large"
                      style={{ borderRadius: "8px", width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={screens.md ? 12 : 24}>
                  <Form.Item
                    label="Hora de Cierre"
                    name="closeTime"
                    dependencies={['openTime']}
                    rules={[
                      { required: true, message: "Por favor seleccione la hora de cierre" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const openTime = getFieldValue('openTime');
                          if (!value || !openTime || dayjs(value).isAfter(openTime)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('La hora de cierre debe ser mayor que la hora de apertura')
                          );
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      use12Hours
                      format="hh:mm A" // Mostrar en formato 12 horas con AM/PM
                      size="large"
                      style={{ borderRadius: "8px", width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Días de Apertura"
                    name="openDays"
                    rules={[{ required: true, message: "Por favor seleccione los días de apertura" }]}
                  >
                    <Select
                      mode="multiple"
                      size="large"
                      placeholder="Seleccione los días de apertura"
                      style={{ borderRadius: "8px" }}
                    >
                      {daysOptions.map(day => (
                        <Select.Option key={day} value={day}>
                          {day}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Horarios Especiales"
                    name="specialHours"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>

              {specialHoursValue && (
                <Row gutter={24}>
                  <Col span={screens.md ? 12 : 24}>
                    <Form.Item
                      label="Hora Especial de Apertura"
                      name="specialOpenTime"
                      dependencies={['specialCloseTime']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const closeVal = getFieldValue('specialCloseTime');
                            if (!value || !closeVal || dayjs(value).isBefore(closeVal)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('La hora especial de apertura debe ser menor que la hora especial de cierre')
                            );
                          },
                        }),
                      ]}
                    >
                      <TimePicker
                        use12Hours
                        format="hh:mm A"
                        size="large"
                        style={{ borderRadius: "8px", width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={screens.md ? 12 : 24}>
                    <Form.Item
                      label="Hora Especial de Cierre"
                      name="specialCloseTime"
                      dependencies={['specialOpenTime']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const openVal = getFieldValue('specialOpenTime');
                            if (!value || !openVal || dayjs(value).isAfter(openVal)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('La hora especial de cierre debe ser mayor que la hora especial de apertura')
                            );
                          },
                        }),
                      ]}
                    >
                      <TimePicker
                        use12Hours
                        format="hh:mm A"
                        size="large"
                        style={{ borderRadius: "8px", width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Form.Item>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  block 
                  size="large" 
                  loading={updating} // Mostrar la animación de carga en el botón
                  disabled={!hasChanges}
                  style={{ 
                    borderRadius: "8px", 
                    background: hasChanges ? "linear-gradient(45deg, #1890ff, #1d39c4)" : "gray",
                    border: "none" 
                  }}
                >
                  Guardar Cambios
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Schedule;