import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Spin,
  message,
  Typography,
} from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { get, put } from "../../../Api/http/httpClient"; // Importar métodos del httpclient
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Text } = Typography;

const GeneralServices: React.FC<{ user_data: any }> = ({ user_data }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const [originalValues, setOriginalValues] = useState<any>({});
  const [hasChanges, setHasChanges] = useState(false);

  const screens = useBreakpoint();

  // Ajustamos atmosphere y services a {label, value} con value en inglés
  const atmosphereOptions = [
    { label: "Formal", value: "formal" },
    { label: "Casual", value: "casual" },
    { label: "Familiar", value: "family" },
    { label: "Romántico", value: "romantic" },
  ];

  const serviceOptions = [
    { label: "Wi-Fi", value: "wifi" },
    { label: "Terraza", value: "terrace" },
    { label: "Música en vivo", value: "live-music" },
    { label: "Zona de fumadores", value: "smoking-area" },
  ];

  const fetchEstablishmentData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );
      if (response.answer) {
        const fetchedData = response.item;
        form.setFieldsValue(fetchedData);
        setOriginalValues(fetchedData);
      } else {
        setError(true);
        message.error("No se encontraron datos del establecimiento.");
      }
    } catch (error) {
      setError(true);
      message.error("Error al obtener los datos del establecimiento.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishmentData();
  }, []);

  const onValuesChange = (changedValues: any, allValues: any) => {
    const isDifferent = Object.keys(originalValues).some((key) => {
      return originalValues[key] !== allValues[key];
    });
    setHasChanges(isDifferent);
  };

  const onFinish = async (values: any) => {
    const changes = Object.keys(values).reduce((acc: any, key) => {
      if (values[key] !== originalValues[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length === 0) {
      message.info("No se han realizado cambios.");
    } else {
      try {
        setUpdating(true);
        await put("restaurant-onboarding", {
          establishmentID: user_data["custom:establishment_id"],
          name_establishment: user_data.family_name,
          ...changes,
        });
        message.success("Datos actualizados correctamente.");
        fetchEstablishmentData();
        setHasChanges(false);
      } catch (error) {
        message.error("Error al actualizar los datos del establecimiento.");
      } finally {
        setUpdating(false);
      }
    }
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={screens.xs ? 24 : 22}>
        <Card
          title="Servicios Generales"
          bordered={false}
          style={{
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: screens.xs ? "10px" : "10px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <Spin size="large" />
              <Text style={{ marginTop: "20px" }}>
                Cargando datos del establecimiento...
              </Text>
            </div>
          ) : error ? (
            <div style={{ textAlign: "center", padding: "40px" }}>
              <FrownOutlined style={{ fontSize: "64px", color: "#ff4d4f" }} />
              <Text
                type="danger"
                style={{
                  display: "block",
                  fontSize: "18px",
                  marginTop: "20px",
                }}
              >
                Ocurrió un error al cargar los datos del establecimiento.
              </Text>
              <Button
                type="primary"
                size="large"
                style={{ marginTop: "20px", borderRadius: "8px" }}
                onClick={fetchEstablishmentData}
              >
                Reintentar
              </Button>
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onValuesChange={onValuesChange}
            >
              {/* Fila 1: Estacionamiento | Transporte Público */}
              <Row gutter={24}>
                <Col span={screens.md ? 12 : 24}>
                  <Form.Item
                    label="Estacionamiento"
                    name="parking"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingrese la información de estacionamiento",
                      },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      placeholder="Ingrese información sobre el estacionamiento"
                      autoSize={{ minRows: 3, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={screens.md ? 12 : 24}>
                  <Form.Item
                    label="Transporte Público"
                    name="publicTransport"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingrese la información de transporte público",
                      },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      placeholder="Ingrese información sobre el transporte público"
                      autoSize={{ minRows: 3, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Fila 2: Servicios Adicionales | Ambiente */}
              <Row gutter={24}>
                <Col span={screens.md ? 12 : 24}>
                  <Form.Item
                    label="Servicios Adicionales"
                    name="additionalServices"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor seleccione los servicios adicionales",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      size="large"
                      placeholder="Seleccione servicios adicionales"
                      options={serviceOptions}
                    />
                  </Form.Item>
                </Col>
                <Col span={screens.md ? 12 : 24}>
                  <Form.Item
                    label="Ambiente"
                    name="atmosphere"
                    rules={[
                      {
                        required: true,
                        message: "Por favor seleccione el ambiente",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      placeholder="Seleccione el ambiente"
                      options={atmosphereOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={updating}
                  disabled={!hasChanges}
                >
                  Guardar Cambios
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default GeneralServices;