import { useEffect } from "react";
import { Form, Input, Button, Typography } from "antd";
const { Text } = Typography;

interface AccountCreationProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
}

export default function AccountCreation({
  updateFormData,
  nextStep,
  prevStep,
  initialValues,
}: AccountCreationProps) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      username: initialValues?.username,
      password: initialValues?.password || "", // Sincronizar valores iniciales de la contraseña si existen
      confirmPassword: initialValues?.confirmPassword || "",
    });
  }, [initialValues, form]);

  const validatePassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject("Por favor ingrese la contraseña");
    }
    if (value.length < 8) {
      return Promise.reject("La contraseña debe tener al menos 8 caracteres");
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject("La contraseña debe contener al menos una letra mayúscula");
    }
    if (!/[a-z]/.test(value)) {
      return Promise.reject("La contraseña debe contener al menos una letra minúscula");
    }
    if (!/[0-9]/.test(value)) {
      return Promise.reject("La contraseña debe contener al menos un número");
    }
    if (!/[!@#$%^&*(),.?\":{}|<>]/.test(value)) {
      return Promise.reject("La contraseña debe contener al menos un carácter especial");
    }
    return Promise.resolve();
  };

  const handleSubmit = (values: any) => {
    if (values.password !== values.confirmPassword) {
      form.setFields([
        {
          name: "confirmPassword",
          errors: ["Las contraseñas no coinciden"],
        },
      ]);
      return;
    }
    updateFormData(values); // Pasamos todos los valores directamente
    nextStep();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      style={{ width: "100%" }}
      initialValues={{
        username: initialValues?.username,
        password: initialValues?.password || "",
        confirmPassword: initialValues?.confirmPassword || "",
      }}
    >
      <Form.Item
        label="Nombre de usuario"
        name="username"
        rules={[
          { required: true, message: "El nombre de usuario es obligatorio" },
        ]}
      >
        <Text className="username-display" strong>
          {initialValues?.username}
        </Text>
      </Form.Item>
      <Form.Item
        label="Contraseña"
        name="password"
        rules={[{ required: true, validator: validatePassword }]}
      >
        <Input.Password size="large" placeholder="Ingrese su contraseña" />
      </Form.Item>
      <Form.Item
        label="Confirmar contraseña"
        name="confirmPassword"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Por favor confirme la contraseña",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Las contraseñas no coinciden");
            },
          }),
        ]}
      >
        <Input.Password size="large" placeholder="Confirme su contraseña" />
      </Form.Item>
      <Form.Item>
        <Button size="large" onClick={prevStep} style={{ marginRight: 8 }}>
          Anterior
        </Button>
        <Button size="large" type="primary" htmlType="submit">
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  );
}