import React, { useState, useEffect } from "react";
import { Card, Row, Col, message } from "antd";
import { get } from "../../../Api/http/httpClient";
import TableComponent from "../../../components/table/table";
import ChatModal from "../../../components/modals/ChatModal";

const ChatData: React.FC<{ user_data: any }> = ({ user_data }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState<any | null>(null);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortColumn, setSortColumn] = useState<string>("name_user");

  const headers_table = {
    chat_uuid: "ID de Chat",
    name_user: "Nombre",
    establishment_id: "ID del Establecimiento",
    name_establishment: "Establecimiento",
    chat_platform_type: "Plataforma",
    message_count: "Número de Mensajes",
  };

  const getChatData = async () => {
    setLoading(true);
    try {
      const res = await get(
        `chat-ai?establishmentID=${user_data["custom:establishment_id"]}&page=${page}&page_size=${itemsPerPage}&search=${search}&sort_by=${sortColumn}&order_by=${sortOrder}`
      );

      if (res.answer && res.items) {
        const formattedChatItems = res.items.map((chat: any) => ({
          chat_uuid: chat.chat_uuid,
          name_user: chat.name_user,
          establishment_id: chat.establishment_id,
          name_establishment: chat.name_establishment,
          chat_platform_type:
            chat.chat_platform_type.toLowerCase() === "internal"
              ? "Interna"
              : chat.chat_platform_type.toLowerCase() === "whatsapp"
              ? "WhatsApp"
              : chat.chat_platform_type,
          message_count: chat.history.length,
          messages: chat.history, // Ahora messages tiene la nueva estructura
        }));
        setData(formattedChatItems);
        setTotalPages(Math.ceil(res.total_items / itemsPerPage));
      } else {
        message.error("No se encontraron chats.");
      }
    } catch (error) {
      message.error("Error al obtener los datos de chats.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatData();
  }, [page, itemsPerPage, search, sortColumn, sortOrder]);

  const handleViewChat = (chat: any) => {
    setSelectedChat(chat);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedChat(null);
  };

  const setActionHandler = (action: { type: string; data?: any }) => {
    if (action.type === "view") {
      handleViewChat(action.data);
    } else if (action.type === "reload") {
      getChatData();
    }
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={23} style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Card bordered={false}>
          <TableComponent
            data={data}
            headers_table={headers_table}
            loading={loading}
            setSearch={setSearch}
            setAction={setActionHandler}
            totalPages={totalPages}
            setPage={setPage}
            page={page}
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
            setSortOrder={setSortOrder}
            setSortColumn={setSortColumn}
            deleting={deleting}
            isViewMode={true}
          />
        </Card>
      </Col>

      <ChatModal
        visible={modalOpen}
        onClose={handleCloseModal}
        chatDetails={selectedChat}
      />
    </Row>
  );
};

export default ChatData;