import { Form, Button, Select, Checkbox, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi, faUmbrellaBeach, faMusic, faSmoking, faMoneyBill, faCreditCard, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, CheckboxButton, CheckboxContainer } from '../landingpage/styledComponents/Buttons';

interface AdditionalPreferencesProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
  businessModel: string;
}

export default function AdditionalPreferences({
  updateFormData,
  nextStep,
  prevStep,
  initialValues,
  businessModel,
}: AdditionalPreferencesProps) {
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    updateFormData(values);
    nextStep();
  };

  const atmosphereOptions = [
    { label: 'Formal', value: 'formal' },
    { label: 'Casual', value: 'casual' },
    { label: 'Familiar', value: 'family' },
    { label: 'Romántico', value: 'romantic' },
  ];
  const serviceOptions = [
    { label: 'Wi-Fi', value: 'wifi', icon: faWifi },
    { label: 'Terraza', value: 'terrace', icon: faUmbrellaBeach },
    { label: 'Música en vivo', value: 'live-music', icon: faMusic },
    { label: 'Zona de fumadores', value: 'smoking-area', icon: faSmoking },
  ];
  const paymentMethodOptions = [
    { label: 'Efectivo', value: 'cash', icon: faMoneyBill },
    { label: 'Tarjeta de crédito', value: 'credit-card', icon: faCreditCard },
    { label: 'Tarjeta de débito', value: 'debit-card', icon: faCreditCard },
    { label: 'Pago móvil', value: 'mobile-payment', icon: faMobileAlt },
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      {/* E-commerce conditions */}
      {businessModel === 'e-commerce' && (
        <>
          <Form.Item
            label="Opciones de pago aceptadas"
            name="paymentOptions"
            rules={[{ required: true, message: 'Por favor seleccione al menos una opción de pago' }]}
          >
            <Checkbox.Group>
              <ButtonGroup $justify="flex-start">
                {paymentMethodOptions.map((payment) => (
                  <CheckboxContainer key={payment.value}>
                    <Checkbox value={payment.value} />
                    <CheckboxButton>
                      <label>
                        <FontAwesomeIcon icon={payment.icon} style={{ marginRight: 8 }} />
                        <span>{payment.label}</span>
                      </label>
                    </CheckboxButton>
                  </CheckboxContainer>
                ))}
              </ButtonGroup>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            label="Políticas de devolución y garantías"
            name="returnPolicies"
            rules={[{ required: true, message: 'Por favor ingrese las políticas de devolución y garantías' }]}
          >
            <Input.TextArea rows={4} placeholder="Ingrese las políticas de devolución y garantías" />
          </Form.Item>

          <Form.Item
            label="Descuentos y promociones"
            name="discounts"
            rules={[{ required: true, message: 'Por favor ingrese los descuentos y promociones disponibles' }]}
          >
            <Input.TextArea rows={4} placeholder="Ingrese los descuentos y promociones disponibles" />
          </Form.Item>
        </>
      )}

      {/* Restaurant conditions */}
      {businessModel === 'restaurant' && (
        <>
          <Form.Item label="Ambiente" name="atmosphere">
            <Select size="large" placeholder="Seleccione el ambiente">
              {atmosphereOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Servicios adicionales" name="additionalServices">
            <Checkbox.Group>
              <ButtonGroup $justify="flex-start">
                {serviceOptions.map((service) => (
                  <CheckboxContainer key={service.value}>
                    <Checkbox value={service.value} />
                    <CheckboxButton>
                      <label>
                        <FontAwesomeIcon icon={service.icon} style={{ marginRight: 8 }} />
                        <span>{service.label}</span>
                      </label>
                    </CheckboxButton>
                  </CheckboxContainer>
                ))}
              </ButtonGroup>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item label="Opciones de pago aceptadas" name="paymentOptions">
            <Checkbox.Group>
              <ButtonGroup $justify="flex-start">
                {paymentMethodOptions.map((payment) => (
                  <CheckboxContainer key={payment.value}>
                    <Checkbox value={payment.value} />
                    <CheckboxButton>
                      <label>
                        <FontAwesomeIcon icon={payment.icon} style={{ marginRight: 8 }} />
                        <span>{payment.label}</span>
                      </label>
                    </CheckboxButton>
                  </CheckboxContainer>
                ))}
              </ButtonGroup>
            </Checkbox.Group>
          </Form.Item>
        </>
      )}

      {/* Medical conditions */}
      {businessModel === 'medical' && (
        <Form.Item
          label="Opciones de pago aceptadas"
          name="paymentOptions"
          rules={[{ required: true, message: 'Por favor seleccione al menos una opción de pago' }]}
        >
          <Checkbox.Group>
            <ButtonGroup $justify="flex-start">
              {paymentMethodOptions.map((payment) => (
                <CheckboxContainer key={payment.value}>
                  <Checkbox value={payment.value} />
                  <CheckboxButton>
                    <label>
                      <FontAwesomeIcon icon={payment.icon} style={{ marginRight: 8 }} />
                      <span>{payment.label}</span>
                    </label>
                  </CheckboxButton>
                </CheckboxContainer>
              ))}
            </ButtonGroup>
          </Checkbox.Group>
        </Form.Item>
      )}

      <Form.Item>
        <Button size="large" onClick={prevStep} style={{ marginRight: 8 }}>
          Anterior
        </Button>
        <Button size="large" type="primary" htmlType="submit">
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  );
}