import { useState, useEffect } from "react";
import { Form, Upload, Button, Pagination, message, Space, Image } from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import Dragger from "antd/es/upload/Dragger";

interface EstablishmentPhotosProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any; // Añadido: Propiedad para los valores iniciales
}

export default function EstablishmentPhotos({
  updateFormData,
  nextStep,
  prevStep,
  initialValues,
}: EstablishmentPhotosProps) {
  const [fileList, setFileList] = useState<UploadFile[]>(
    initialValues.photos || []
  ); // Inicializar con valores previos
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8; // Número de imágenes que quieres mostrar por página
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (initialValues.photos) {
      setFileList(initialValues.photos);
    }
  }, [initialValues.photos]);

  const handleSubmit = () => {
    // Aquí, el fileList ya contiene las imágenes en base64
    updateFormData({ photos: fileList });
    nextStep();
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const getBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file: UploadFile) => {
    let src = file.thumbUrl || file.url;
    if (!src && file.originFileObj) {
      src = (await getBase64(file.originFileObj)) as string;
    }

    setPreviewImage(src);
    setPreviewOpen(true);
  };

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      setFileList((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );
    },
    beforeUpload: async (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("Solo se pueden subir imágenes");
        return Upload.LIST_IGNORE; // Ignorar el archivo no válido
      }

      try {
        const base64 = await getBase64(file);
        if (base64) {
          const newFile: UploadFile = {
            uid: file.uid,
            name: file.name,
            status: "done",
            thumbUrl: base64.toString(),
            originFileObj: file,
            url: base64.toString(),
          };
          setFileList((prevList) => [...prevList, newFile]); // Agregar el archivo con base64 al fileList
        } else {
          message.error("Error: base64 es null");
        }
      } catch (error) {
        message.error("Error al convertir la imagen");
      }

      return false; // Prevenir el comportamiento predeterminado de subida
    },
    onPreview: handlePreview,
    fileList: fileList.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    ),
    listType: "picture-card" as const,
    accept: "image/*", // Acepta todos los tipos de imágenes
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit} style={{width: '100%'}}>
      <Form.Item label="Subir fotos del establecimiento">
        <Dragger {...uploadProps} multiple style={{marginBottom: 20}}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Sube imágenes que representen lo mejor de tu negocio. Haz clic o arrastra tus archivos aquí.</p>
        </Dragger>
      </Form.Item>
      <Space wrap>
        {fileList.length > pageSize && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={fileList.length}
              onChange={handlePaginationChange}
            />
          </div>
        )}
      </Space>
      <Form.Item style={{ marginTop: "32px" }}>
        <Button onClick={prevStep} style={{ marginRight: 8 }} size="large">
          Anterior
        </Button>
        <Button type="primary" htmlType="submit" size="large">
          Siguiente
        </Button>
      </Form.Item>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </Form>
  );
}
