import React from "react";
import { Modal, Input, Form, Button, Select, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const { Option } = Select;

interface PackagesModalProps {
  open: boolean;
  onClose: () => void;
  formData: {
    establishment_id: string;
    plan_id: string;
    plan_name: string;
    autoRenew: boolean;
    is_active: boolean;
  };
  establishments: Array<{
    establishmentID: string;
    name_establishment: string;
  }>;
  plans: Array<{ plan_id: string; plan_name: string }>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (value: string, field: string) => void;
  handleCheckboxChange: (checked: boolean, field: string) => void;
  handleSave: () => void;
  handleUpdate: () => void;
  action: { type: string } | null;
  loading: boolean;
}

const PackagesModal: React.FC<PackagesModalProps> = ({
  open,
  onClose,
  formData,
  establishments,
  plans,
  handleInputChange,
  handleSelectChange,
  handleCheckboxChange,
  handleSave,
  handleUpdate,
  action,
  loading,
}) => {
  const isEdit = action?.type === "edit";

  return (
    <Modal
      title={isEdit ? "Editar Paquete" : "Crear Paquete"}
      open={open}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form layout="vertical">
        <Form.Item label="Establecimiento">
          <Select
            value={formData.establishment_id}
            onChange={(value) => handleSelectChange(value, "establishment_id")}
            placeholder="Seleccionar Establecimiento"
          >
            {establishments.map((establishment) => (
              <Option
                key={establishment.establishmentID}
                value={establishment.establishmentID}
              >
                {establishment.name_establishment}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Plan">
          <Select
            value={formData.plan_id}
            onChange={(value) => {
              const selectedPlan = plans.find((plan) => plan.plan_id === value);
              handleSelectChange(value, "plan_id");
              if (selectedPlan) {
                handleSelectChange(selectedPlan.plan_name, "plan_name");
              }
            }}
            placeholder="Seleccionar Plan"
          >
            {plans.map((plan) => (
              <Option key={plan.plan_id} value={plan.plan_id}>
                {plan.plan_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {isEdit && (
          <>
            <Form.Item>
              <Checkbox
                checked={formData.autoRenew}
                onChange={(e: CheckboxChangeEvent) =>
                  handleCheckboxChange(e.target.checked, "autoRenew")
                }
              >
                Renovación Automática
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Checkbox
                checked={formData.is_active}
                onChange={(e: CheckboxChangeEvent) =>
                  handleCheckboxChange(e.target.checked, "is_active")
                }
              >
                Activo
              </Checkbox>
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            type="primary"
            onClick={isEdit ? handleUpdate : handleSave}
            loading={loading}
          >
            {isEdit ? "Actualizar Paquete" : "Crear Paquete"}
          </Button>
          <Button onClick={onClose} style={{ marginLeft: 10 }}>
            Cancelar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PackagesModal;
