import styled from 'styled-components';

export const MenuIcon = styled.div.attrs<{$isOpen?: boolean}>((props) => ({
    $isOpen: props.$isOpen
}))`
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.$isOpen ? 'center' : 'space-between'};
  cursor: pointer;

  div {
    width: 100%;
    height: 3px;
    background-color: #FFFFFF;
    transition: 0.3s ease;
    border-radius: 2px;

    &:nth-child(1) {
      transform: ${(props) => props.$isOpen ? 'rotate(45deg) translate(0px, 5px)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${(props) => props.$isOpen ? '0' : '1'};
    }

    &:nth-child(3) {
      transform: ${(props) => props.$isOpen ? 'rotate(-45deg) translate(0px, -4px)' : 'rotate(0)'};
    }
  }
`;

export const MenuItems = styled.div.attrs<{ 
    $height?: string;
    $gap?: string;
    $padding?: string;
    $overflow?: string;
    $justify?: string;
}>((props) => ({
    $height: props.$height,
    $gap: props.$gap,
    $padding: props.$padding,
    $overflow: props.$overflow,
    $justify: props.$justify,
}))`
    width: auto;
    height: ${({ $height }) => $height || 'auto' };
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: ${({ $justify }) => $justify || 'center' };
    padding: ${({ $padding }) => $padding || 0 };
    gap: ${({ $gap }) => $gap || '20px' };
    overflow: ${({ $overflow }) => $overflow || 'hidden' };
    a {
        height: 50px;
        display: block;
        font-family: poppins-regular;
        font-size: 38px;
        line-height: normal;
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 50px;
            height: 2px;
            background-color: rgba(250, 250, 250, 1);
            transition: 0.3s ease;
        }            
        span {
            margin-right: 40px;
            font-family: poppins-bold;
            font-size: 40px;
            line-height: 60px;
            color: transparent;
            -webkit-text-stroke: 1px rgba(250, 250, 250, 1);
        }
        &:hover{
            color: #0E6EAF;
            span{
                color: #0E6EAF;
                -webkit-text-stroke: 0;
            }
            &::after {
                background-color: #0E6EAF;                    
                width: 100%;
            }
        }
    }
    @media (max-width: 1024px) {
        gap: 0;
        a {
            font-size: 28px;
            height: auto;
            span {
                font-size: 28px;
            }
        }
    }
    @media (max-width: 480px) {
        width: 100%;
        gap: 0;
        a {
            font-size: 22px;
            height: auto;
            &::after {
                bottom: 10px;
            }
            span {
                font-size: 28px;
                line-height: 70px;
            }
        }
    }
`;

export const TextBackground = styled.div.attrs<{$offsetX: number, $offsetY: number}>((props) => ({
    $offsetX: props.$offsetX || 0,
    $offsetY: props.$offsetY || 0
}))`
    position: absolute;
    bottom: 0;
    width: 100%;
    font-family: poppins-black;
    font-size: 90px;
    line-height: normal;
    color: rgba(40, 42, 47, 0.5);
    text-align: center;
    z-index: -1;
    transition: transform 0.1s ease;
    transform: ${( props ) => `translate(${props.$offsetX}px, ${props.$offsetY}px)`};
    @media (max-width: 1024px) {
        display: none;
    }
`;