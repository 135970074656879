import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/DashboardPages/Home/Home";
import UserProfile from "../pages/DashboardPages/UserProfile/UserProfile";
import GeneralData from "../pages/DashboardPages/GeneralData/GeneralData";
import GeneralServices from "../pages/DashboardPages/GeneralServices/GeneralServices";
import Schedule from "../pages/DashboardPages/Schedule/Schedule";
import MenuCategories from "../pages/DashboardPages/MenuCategories/MenuCategories";
import FoodData from "../pages/DashboardPages/FoodData/FoodData";
import PlansData from "../pages/DashboardPages/PlansData/PlansData";
import PackagesData from "../pages/DashboardPages/PackagesData/PackagesData";
import PolicyData from "../pages/DashboardPages/PolicyData/PolicyData";
import FAQs from "../pages/DashboardPages/FAQs/FAQs";
import Multimedia from "../pages/DashboardPages/Multimedia/Multimedia";
import ChatPage from "../pages/ChatPages/ChatPage/ChatPage";
import ChatPageWhatsApp from "../pages/ChatPages/ChatPageWhatsApp/ChatPageWhatsApp";
import ChatData from "../pages/DashboardPages/ChatData/ChatData";
import PaymentData from "../pages/DashboardPages/PaymentData/PaymentData";
import WhatsAppSignup from "../pages/WhatsAppSignup/WhatsAppSignup";
import Analytics from "../pages/Analytics/Analytics"

interface DashboardRoutesProps {
  user_data: any;
}

const DashboardRoutes: React.FC<DashboardRoutesProps> = ({ user_data }) => {
  const isAdmin = user_data?.["custom:group_user"] === "Administrador";

  return (
    <Routes>
      <Route path="/" element={<Home user_data={user_data} />} />
      <Route path="/user-profile" element={<UserProfile user_data={user_data} />} />
      <Route path="/general-data" element={<GeneralData user_data={user_data} />} />
      <Route path="/general-services" element={<GeneralServices user_data={user_data} />} />
      <Route path="/schedule" element={<Schedule user_data={user_data} />} />
      <Route path="/menu-categories" element={<MenuCategories user_data={user_data} />} />
      <Route path="/food-data" element={<FoodData user_data={user_data} />} />
      
      {/* Solo los administradores pueden acceder a estas rutas */}
      <Route
        path="/plans-data"
        element={isAdmin ? <PlansData user_data={user_data} /> : <Navigate to="/dashboard" />}
      />
      <Route
        path="/packages-data"
        element={isAdmin ? <PackagesData user_data={user_data} /> : <Navigate to="/dashboard" />}
      />

      <Route path="/policy-data" element={<PolicyData user_data={user_data} />} />
      <Route path="/faqs" element={<FAQs user_data={user_data} />} />
      <Route path="/multimedia" element={<Multimedia user_data={user_data} />} />
      <Route path="/normal-chat" element={<ChatPage user_data={user_data} />} />
      <Route path="/whatsapp-chat" element={<ChatPageWhatsApp user_data={user_data} />} />
      <Route path="/chat-data" element={<ChatData user_data={user_data} />} />
      <Route path="/payment-data" element={<PaymentData user_data={user_data} />} />

      {/* Nueva ruta para WhatsAppSignup */}
      <Route path="/whatsapp-integration" element={<WhatsAppSignup user_data={user_data}/>} />
      <Route path="/analytics" element={<Analytics user_data={user_data}/>} />
    </Routes>
  );
};

export default DashboardRoutes;