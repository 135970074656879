import styled from "styled-components";

export const TagSection = styled.div`
    background: linear-gradient(90.08deg, #0E6EAF -26.21%, #4296DE 8.68%, #4296DE 80.18%, #0E6EAF 116.78%);
    padding: 8px 16px;
    border-radius: 8px;
    font-family: poppins-regular;
    font-size: 12px;
    display: inline-block;
`;

export const ImageCTA = styled.div`
  position: absolute;
  left: 0;
  bottom: -40%;
  @media (max-width: 1024px) {
    bottom: -25%;
  }
  @media (max-width: 760px) {
      width: 100%;
      position: relative;
      display: flex;
      align-items: flex-end;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }
`;

export const ListSection = styled.ul`
  padding: 0;
  list-style: none;
  li {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    font-family: poppins-semibold;
    font-size: 16px;
    padding-bottom: 16px;
    color: rgba(134, 146, 166, 1);
    span {
      display: contents;
      color: rgba(14, 110, 175, 1);
    }
  }
`;

export const ImageChat = styled.div`
  width: calc(50% - 16px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1024px) {
    width: 40%;
    height: 50%;
  }
  @media (max-width: 780px) {
    width: auto;
    margin: 0 auto;
    img {
      height: 470px;
    }
  }
`;

export const ButtonWhatsApp = styled.a`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FAFAFA;
  padding: 18px 16px;
  font-family: poppins-regular;
  font-size: 14px;
  background-color: #25D366;
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 4;
  transition-duration: .3s;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  p {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    transition-duration: .3s;
  }
  &:hover {
    width: 220px;
    border-radius: 40px;
    transition-duration: .3s;
    svg {
      width: 30%;
      transition-duration: 0.3s;
    }
    p {
      position: relative;
      width: 70%;
      opacity: 1;
      transition-duration: .3s;
    }
  }
  &:active {
    transform: translate(2px, 2px);
  }
  @media (max-width: 780px) {
    bottom: 20px;
    right: 20px;
  }
`;