import React, { useState, useEffect, useRef } from "react";
import { post, get } from "../../Api/http/httpClient";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Input, Button, Avatar, Skeleton, message as antdMessage, Spin } from "antd";
import { SendOutlined } from "@ant-design/icons";
import imageProfile from "../../imgs/OIG4.jpg";
import "./Chat.css";

// Definición de tipos para los mensajes
interface Message {
  id: number;
  text: string;
  time: string;
  from: string;
  received: boolean;
  loading?: boolean;
}

interface AIResponse {
  answer: boolean;
  message: string;
}

interface ChatAIProps {
  user_data: any;
}

const Chat: React.FC<ChatAIProps> = ({ user_data }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [botTyping, setBotTyping] = useState<boolean>(false);
  const [chatUuid, setChatUuid] = useState<string>(uuidv4());
  const [remainingFreeMessages, setRemainingFreeMessages] = useState<number | null>(null);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Petición para verificar el número de mensajes gratuitos restantes
    get(
      `/restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
    )
      .then((response) => {
        setRemainingFreeMessages(response.item.remaining_free_messages);
        setMessages([
          {
            id: 1,
            text: "¡Hola! Soy tu asistente virtual, impulsado por inteligencia artificial. Estoy aquí para facilitarte la tarea de verificar el inventario de manera rápida y eficiente.",
            time: moment().format("HH:mm"),
            from: "Bot de IA",
            received: true,
          },
        ]);
      })
      .catch(() => {
        antdMessage.error("Error al cargar los datos del establecimiento.");
      })
      .finally(() => {
        setLoadingMessages(false);
      });
  }, [user_data]);

  const getResponseAI = (message: string) => {
    const requestBody = {
      question: message,
      establishment_id: user_data["custom:establishment_id"],
      name_establishment: user_data.family_name,
      chat_uuid: chatUuid,
    };

    post("/chat-ai", requestBody)
      .then((data: AIResponse) => {
        let text;
        if (data.answer) {
          text = data.message;
          setRemainingFreeMessages((prev) => (prev !== null && prev > 0 ? prev - 1 : 0)); // Reducir los mensajes restantes en 1 solo si es > 0
        } else {
          text = "Lo siento, no puedo responder a tu pregunta en este momento.";
          antdMessage.error("Error al obtener la respuesta del bot de IA.");
        }

        const botMsg: Message = {
          id: messages.length + 1,
          text: text,
          time: moment().format("HH:mm"),
          from: "Bot de IA",
          received: true,
        };

        setMessages((prevMessages) => [
          ...prevMessages.slice(0, prevMessages.length - 1),
          botMsg,
        ]);
      })
      .catch(() => {
        const botMsg: Message = {
          id: messages.length + 1,
          text: "Lo siento, no puedo responder a tu pregunta en este momento.",
          time: moment().format("HH:mm"),
          from: "Bot de IA",
          received: true,
        };

        setMessages((prevMessages) => [
          ...prevMessages.slice(0, prevMessages.length - 1),
          botMsg,
        ]);

        antdMessage.error("Error al obtener la respuesta del bot de IA.");
      })
      .finally(() => {
        setBotTyping(false);
      });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "" && remainingFreeMessages && remainingFreeMessages > 0) {
      const newMsg: Message = {
        id: messages.length + 1,
        text: newMessage,
        time: moment().format("HH:mm"),
        from: "Tú",
        received: false,
      };
      setMessages([...messages, newMsg]);
      setNewMessage("");
      setBotTyping(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          text: "",
          time: moment().format("HH:mm"),
          from: "Bot de IA",
          received: true,
          loading: true,
        },
      ]);
      getResponseAI(newMessage);
    } else if (remainingFreeMessages === 0) {
      antdMessage.warning("No tienes mensajes gratuitos disponibles.");
    }
  };

  // Determinar el color del contador de mensajes restantes
  const getRemainingMessagesStyle = () => {
    if (remainingFreeMessages !== null) {
      if (remainingFreeMessages >= 15) {
        return { backgroundColor: "green", color: "white" };
      } else if (remainingFreeMessages >= 8) {
        return { backgroundColor: "orange", color: "white" };
      } else {
        return { backgroundColor: "red", color: "white" };
      }
    }
    return {}; // Default style when loading
  };

  const formatMessage = (text: string) => {
    const listRegex = /^(\d+\.\s|\-\s)/gm;
    const imageRegex = /\.(jpeg|jpg|gif|png)$/i;

    if (imageRegex.test(text)) {
      return (
        <img
          src={text}
          alt="Imagen enviada"
          style={{ maxWidth: "100%", borderRadius: "8px" }}
        />
      );
    }

    if (listRegex.test(text)) {
      const listItems = text.split("\n").map((line, index) => {
        if (listRegex.test(line)) {
          return <li key={index} style={{ listStyleType: "none" }}>{line}</li>;
        }
        return <p key={index}>{line}</p>;
      });
      return <ul>{listItems}</ul>;
    }

    return <p>{text}</p>;
  };

  useEffect(() => {
    const handleEnterPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleSendMessage();
      }
    };

    window.addEventListener("keydown", handleEnterPress);
    return () => {
      window.removeEventListener("keydown", handleEnterPress);
    };
  }, [newMessage]);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="chat-header-details">
          <Avatar src={imageProfile} size="large" className="mr-2" />
          <div>
            <div className="chat-name">Bot de IA</div>
          </div>
        </div>
        <div className="remaining-messages-card" style={getRemainingMessagesStyle()}>
          {remainingFreeMessages !== null ? (
            `Mensajes restantes: ${remainingFreeMessages}`
          ) : (
            <Spin /> // Animación de carga mientras se obtienen los mensajes restantes
          )}
        </div>
      </div>
      <div className="chat-messages">
        {loadingMessages ? (
          <div className="initial-message-loading">
            <Skeleton.Input active style={{ width: "80%" }} /> {/* Animación de carga para el mensaje inicial */}
          </div>
        ) : (
          messages.map((msg) => (
            <div
              key={msg.id}
              className={`chat-message ${msg.received ? "received" : "sent"}`}
            >
              <div className="chat-message-content">
                {msg.loading ? (
                  <Skeleton.Input active style={{ width: 150 }} />
                ) : msg.received ? (
                  formatMessage(msg.text)
                ) : (
                  msg.text
                )}
              </div>
              <div className="chat-message-time">{msg.time}</div>
            </div>
          ))
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Escribe un mensaje"
          className="chat-input-text"
          onPressEnter={handleSendMessage}
          disabled={botTyping || remainingFreeMessages === 0}
          suffix={
            <Button
              type="primary"
              shape="circle"
              icon={<SendOutlined />}
              onClick={handleSendMessage}
              disabled={botTyping || remainingFreeMessages === 0}
            />
          }
        />
      </div>
    </div>
  );
};

export default Chat;