import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton, message } from "antd";
import { getCurrentUser } from "@aws-amplify/auth";
import { get } from "../../../Api/http/httpClient";
import IconCheck from "../../../assets/img/landingPage/check-circle.png";
import {
  ContainerFooter,
  ContainerBase,
  ContainerCenter,
  PricesList,
} from "../styledComponents/Containers";
import { TagSection } from "../styledComponents/Global";
import { Description, SubTitle } from "../styledComponents/Typography";
import { CardPrice } from "../styledComponents/Cards";
import { ButtonFill } from "../styledComponents/Buttons";

interface Plan {
  plan_id: string;
  plan_name: string;
  plan_price: string;
  plan_description: string;
  plan_features: string[];
  plan_message_count: string;
  isCustom?: boolean;
}

const PricesSection: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await getCurrentUser();
        setIsAuthenticated(true);
      } catch {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Desconectar el observador una vez cargado
        }
      },
      { threshold: 0.1 } // Activar cuando el 10% del elemento sea visible
    );
    if (sectionRef.current) observer.observe(sectionRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible) fetchPlans();
  }, [isVisible]);

  const fetchPlans = async () => {
    try {
      const response = await get("/plans?fetch_all=true");
      if (response.answer) {
        setPlans(response.items);
      } else {
        message.error("No se pudieron cargar los planes.");
      }
    } catch (error) {
      message.error("Error al cargar los planes.");
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = (plan: Plan) => {
    const planData = {
      title: plan.plan_name,
      description: plan.plan_description,
      messages: plan.plan_message_count,
      price: plan.isCustom ? "Plan cotizado" : parseFloat(plan.plan_price),
      features: plan.plan_features,
      code_plan: plan.plan_id,
      isCustom: plan.isCustom,
    };

    if (isAuthenticated) {
      navigate("/checkout", { state: { plan: planData } });
    } else {
      navigate("/onboarding");
    }
  };

  // Formatear precio
  const formatPrice = (price: string | number): string => {
    const priceNumber = typeof price === "string" ? parseFloat(price) : price;
    return `$${new Intl.NumberFormat("es-CO").format(priceNumber)}`;
  };

  return (
    <ContainerFooter id="prices" ref={sectionRef}>
      <ContainerBase>
        <ContainerCenter>
          <TagSection>Planes y Precios</TagSection>
          <SubTitle>Planes Flexibles para Cada Tipo de Negocio</SubTitle>
          <Description>
            Elige el plan que mejor se adapte a las necesidades de tu negocio.
          </Description>
        </ContainerCenter>
        {loading ? (
          <PricesList aria-label="Skeleton de planes">
            {Array.from({ length: 3 }).map((_, index) => (
              <CardPrice key={index}>
                <Skeleton active paragraph={{ rows: 3 }} title />
                <Skeleton.Button active />
              </CardPrice>
            ))}
          </PricesList>
        ) : (
          <PricesList aria-label="Lista de planes y precios">
            {plans.map((plan, index) => (
              <CardPrice
                key={plan.plan_id}
                className={index === 1 ? "active" : ""}
              >
                <h3>{plan.plan_name}</h3>
                <p>
                  {plan.isCustom
                    ? "Plan cotizado"
                    : `${formatPrice(plan.plan_price)} / mes`}
                </p>
                <p>{plan.plan_description}</p>
                <hr />
                <ul>
                  {plan.plan_features.map((feature, i) => (
                    <li key={i}>
                      <img src={IconCheck} alt="" /> {feature}
                    </li>
                  ))}
                </ul>
                <ButtonFill onClick={() => handlePurchase(plan)}>
                  {plan.isCustom ? "Contactarnos" : "Comprar Ahora"}
                </ButtonFill>
              </CardPrice>
            ))}
          </PricesList>
        )}
      </ContainerBase>
    </ContainerFooter>
  );
};

export default PricesSection;