import { Form, Input, Button } from 'antd';

interface LocationAccessProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
}

export default function LocationAccess({ updateFormData, nextStep, prevStep, initialValues }: LocationAccessProps) {
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    updateFormData(values);
    nextStep();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
      style={{ width: '100%' }}
    >
      {/* Opciones de transporte público cercano */}
      <Form.Item
        label="Opciones de transporte público cercano"
        name="publicTransport"
        tooltip="Indica si hay estaciones de metro, autobús o taxis cerca de tu ubicación para facilitar el acceso de los clientes."
      >
        <Input.TextArea placeholder="Describa las opciones de transporte público cercanas (opcional)" />
      </Form.Item>

      {/* Disponibilidad de estacionamiento */}
      <Form.Item
        label="Disponibilidad de estacionamiento"
        name="parking"
        tooltip="Especifica si cuentas con estacionamiento propio o cercano, y si tiene costo adicional."
      >
        <Input.TextArea placeholder="Describa las opciones de estacionamiento disponibles (opcional)" />
      </Form.Item>

      {/* Botones de navegación */}
      <Form.Item>
        <Button onClick={prevStep} style={{ marginRight: 8 }} size="large">
          Anterior
        </Button>
        <Button type="primary" htmlType="submit" size="large">
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  );
}