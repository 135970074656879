import React, { useState } from "react";
import { Modal, Button, Input, Spin, message, Select } from "antd";

const { Option } = Select;
const { TextArea } = Input;

interface FoodModalProps {
  open: boolean;
  onClose: () => void;
  action: { type: string; data?: any } | null;
  formData: { name: string; price: string; category: string; description: string };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void; // Cambié el tipo aquí
  handleUpdate: () => Promise<boolean>;
  handleSave: () => Promise<boolean>;
  categories: string[];
}

const FoodModal: React.FC<FoodModalProps> = ({
  open,
  onClose,
  action,
  formData,
  handleChange,
  handleUpdate,
  handleSave,
  categories,
}) => {
  const [loading, setLoading] = useState(false);

  const handleActionClick = async () => {
    if (!formData.name || !formData.price || !formData.category || !formData.description) {
      message.error("Todos los campos son obligatorios");
      return;
    }

    setLoading(true);
    if (action?.type === "edit") {
      await handleUpdate();
    } else {
      await handleSave();
    }
    setLoading(false);
  };

  return (
    <Modal
      title={action?.type === "edit" ? "Editar Comida" : "Crear Comida"}
      open={open}
      maskClosable={false}
      onCancel={() => !loading && onClose()}
      footer={[
        <Button key="back" onClick={() => !loading && onClose()} disabled={loading}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleActionClick} loading={loading}>
          {action?.type === "edit" ? "Actualizar" : "Guardar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Input
          id="name"
          placeholder="Nombre de la Comida"
          value={formData?.name}
          onChange={handleChange}
          style={{ marginBottom: 10 }}
        />
        <Input
          id="price"
          placeholder="Precio"
          value={formData?.price}
          onChange={handleChange}
          style={{ marginBottom: 10 }}
        />
        <Select
          id="category"
          placeholder="Categoría"
          value={formData?.category || undefined}
          onChange={(value) => handleChange({ target: { id: "category", value } } as any)}
          style={{ marginBottom: 10, width: "100%" }}
        >
          {categories.map((category) => (
            <Option key={category} value={category}>
              {category}
            </Option>
          ))}
        </Select>
        <TextArea
          id="description"
          placeholder="Descripción"
          value={formData?.description}
          onChange={(e) => handleChange(e as React.ChangeEvent<HTMLTextAreaElement>)} // Cambié el tipo aquí para TextArea
          style={{ marginBottom: 10 }}
          rows={4} // Ajusta las filas para hacer el área más grande
        />
      </Spin>
    </Modal>
  );
};

export default FoodModal;