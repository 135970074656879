import React, { useState } from "react";
import { Button, Input, notification } from "antd";
import { signIn, resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import styles from "./Login.module.css";
import logo from "../../assets/img/landingPage/logo_login.webp";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const isPasswordValid = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleSignIn = async () => {
    if (!email || !password) {
      notification.error({
        message: "Error de inicio de sesión",
        description: "Correo electrónico y contraseña son obligatorios.",
      });
      return;
    }
    try {
      setIsLoggingIn(true);
      await signIn({ username: email, password });

      notification.success({ message: "Inicio de sesión exitoso" });

      window.location.href = "/";
      window.location.reload();
    } catch (error: any) {
      console.error("Login error:", error);
      notification.error({
        message: "Error de inicio de sesión",
        description: error.message,
      });
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      notification.error({
        message: "Error",
        description: "Por favor, ingrese su correo electrónico.",
      });
      return;
    }

    try {
      const output = await resetPassword({ username: email });
      const { nextStep } = output;

      if (nextStep.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
        notification.success({
          message: "Código de verificación enviado.",
        });
        setForgotPasswordStep(2);
      }
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
  };

  const handleConfirmResetPassword = async () => {
    if (!isPasswordValid(newPassword)) {
      notification.error({
        message: "Error",
        description:
          "La nueva contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial.",
      });
      return;
    }
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: verificationCode,
        newPassword,
      });

      notification.success({
        message: "Contraseña restablecida correctamente.",
      });
      setForgotPasswordStep(1);
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <div
      className={styles["login-container"]}
      onKeyDown={handleKeyDown} // Detecta la tecla "Enter" en el contenedor
    >
      <div className={styles["login-form"]}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <h1 className={styles.title}>Iniciar Sesión</h1>
        {forgotPasswordStep === 1 ? (
          <>
            <div className={styles["form-field"]}>
              <label htmlFor="email" className={styles.label}>
                Correo Electrónico
              </label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className={styles["form-field"]}>
              <label htmlFor="password" className={styles.label}>
                Contraseña
              </label>
              <Input.Password
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <Button
              type="primary"
              loading={isLoggingIn}
              onClick={handleSignIn}
              block
              className={styles["login-btn-primary"]}
            >
              Iniciar Sesión
            </Button>
            <Button
              type="link"
              onClick={() => (window.location.href = "/onboarding")}
              block
              className={styles["ant-btn-link"]}
            >
              Registrarse
            </Button>
            <Button
              type="link"
              onClick={handleForgotPassword}
              block
              className={styles["ant-btn-link"]}
            >
              ¿Olvidaste tu contraseña?
            </Button>
          </>
        ) : (
          <>
            <div className={styles["form-field"]}>
              <label htmlFor="verificationCode" className={styles.label}>
                Código de Verificación
              </label>
              <Input
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
            </div>
            <div className={styles["form-field"]}>
              <label htmlFor="newPassword" className={styles.label}>
                Nueva Contraseña
              </label>
              <Input.Password
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <Button
              type="primary"
              onClick={handleConfirmResetPassword}
              block
              className={styles["ant-btn-primary"]}
            >
              Enviar
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;