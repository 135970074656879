import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  List,
  Pagination,
  message,
  Empty,
  Checkbox,
  Select,
} from "antd";
import PhysicalPointModal from "../modals/PhysicalPointModal";

const { TextArea } = Input;

interface EstablishmentDetailsProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
  businessModel: string;
}

interface PhysicalPoint {
  address: string;
  phone: string;
  schedule: [string, string];
  days: string[];
}

const daysInSpanish: Record<string, string> = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

export default function EstablishmentDetails({
  updateFormData,
  nextStep,
  prevStep,
  initialValues,
  businessModel,
}: EstablishmentDetailsProps): JSX.Element {
  const [form] = Form.useForm();
  const [physicalPoints, setPhysicalPoints] = useState<PhysicalPoint[]>(
    initialValues.physicalPoints || []
  );
  const [hasPhysicalPoints, setHasPhysicalPoints] = useState<boolean>(
    initialValues.hasPhysicalPoints || false
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showOtherCuisineField, setShowOtherCuisineField] = useState(false);
  const pageSize = 3;

  useEffect(() => {
    form.setFieldsValue({ hasPhysicalPoints, physicalPoints });
  }, [hasPhysicalPoints, physicalPoints, form]);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleAddPhysicalPoint = (values: PhysicalPoint) => {
    setPhysicalPoints([...physicalPoints, values]);
    setIsModalVisible(false);
    message.success("Punto de venta agregado correctamente.");
  };

  const handleRemovePhysicalPoint = (index: number) => {
    const updatedPoints = physicalPoints.filter((_, i) => i !== index);
    setPhysicalPoints(updatedPoints);
    message.info("Punto de venta eliminado.");
  };

  const validatePhysicalPoints = (): boolean => {
    if (hasPhysicalPoints && physicalPoints.length === 0) {
      message.error("Debe agregar al menos un punto de venta físico.");
      return false;
    }
    return true;
  };

  const handleSubmit = (values: any) => {
    if (hasPhysicalPoints && !validatePhysicalPoints()) {
      return;
    }

    updateFormData({
      ...values,
      physicalPoints,
      cuisine: values.cuisine,
      otherCuisine: values.otherCuisine,
    });
    nextStep();
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedPhysicalPoints = physicalPoints.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ ...initialValues, hasPhysicalPoints }}
      style={{ width: "100%" }}
    >
      <Form.Item
        label="Descripción breve"
        name="description"
        rules={[
          {
            required: true,
            message: "Por favor ingrese una descripción breve",
          },
        ]}
        tooltip="Proporciona una breve descripción de tu negocio. Esto ayudará a los clientes a conocer más sobre tu establecimiento."
      >
        <TextArea
          size="large"
          placeholder="Ingrese una breve descripción del establecimiento"
        />
      </Form.Item>

      {businessModel === "e-commerce" && (
        <>
          <Form.Item
            label="¿Tienes punto de venta físico?"
            name="hasPhysicalPoints"
            valuePropName="checked"
          >
            <Checkbox
              checked={hasPhysicalPoints}
              onChange={(e) => setHasPhysicalPoints(e.target.checked)}
            >
              Sí
            </Checkbox>
          </Form.Item>
          {hasPhysicalPoints && (
            <>
              <Button
                type="dashed"
                onClick={handleOpenModal}
                style={{ marginBottom: 16 }}
              >
                Agregar punto de venta
              </Button>
              <List
                header={<div>Puntos de venta agregados</div>}
                bordered
                dataSource={paginatedPhysicalPoints}
                locale={{
                  emptyText: <Empty description="No hay puntos de venta agregados" />,
                }}
                renderItem={(point, index) => (
                  <List.Item
                    actions={[
                      <Button
                        type="link"
                        danger
                        onClick={() =>
                          handleRemovePhysicalPoint(
                            (currentPage - 1) * pageSize + index
                          )
                        }
                      >
                        Eliminar
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={`Dirección: ${point.address}`}
                      description={
                        <>
                          <p>
                            <strong>Teléfono:</strong> {point.phone}
                          </p>
                          <p>
                            <strong>Horario:</strong> {point.schedule[0]} -{" "}
                            {point.schedule[1]}
                          </p>
                          <p>
                            <strong>Días:</strong>{" "}
                            {point.days
                              .map((day) => daysInSpanish[day])
                              .join(", ")}
                          </p>
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
              {physicalPoints.length > pageSize && (
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={physicalPoints.length}
                  onChange={handlePaginationChange}
                  style={{ marginTop: "16px", textAlign: "center" }}
                />
              )}
            </>
          )}
        </>
      )}

      {businessModel === "restaurant" && (
        <>
          <Form.Item label="Categoría de cocina (Opcional)" name="cuisine">
            <Select
              size="large"
              placeholder="Seleccione la categoría"
              options={[
                { label: "Italiana", value: "italian" },
                { label: "Mexicana", value: "mexican" },
                { label: "Fusión", value: "fusion" },
                { label: "Otra", value: "other" },
              ]}
              onChange={(value) => setShowOtherCuisineField(value === "other")}
            />
          </Form.Item>

          {showOtherCuisineField && (
            <Form.Item
              label="Otra categoría"
              name="otherCuisine"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el tipo de cocina",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Especifique el tipo de cocina"
              />
            </Form.Item>
          )}

          <Form.Item label="Rango de precios (Opcional)">
            <Input.Group compact>
              <Form.Item
                name="minPrice"
                noStyle
                rules={[
                  {
                    validator: (_, value) => {
                      const numericValue = parseFloat(value);
                      const maxPrice = parseFloat(form.getFieldValue("maxPrice"));
                      if (value && (isNaN(numericValue) || numericValue <= 0)) {
                        return Promise.reject(
                          "El precio mínimo debe ser mayor a 0 y un número válido."
                        );
                      }
                      if (!isNaN(maxPrice) && numericValue >= maxPrice) {
                        return Promise.reject(
                          "El precio mínimo debe ser menor que el precio máximo."
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  style={{ width: "50%" }}
                  placeholder="Precio mínimo"
                  prefix="$"
                  type="number"
                />
              </Form.Item>
              <Form.Item
                name="maxPrice"
                noStyle
                rules={[
                  {
                    validator: (_, value) => {
                      const numericValue = parseFloat(value);
                      const minPrice = parseFloat(form.getFieldValue("minPrice"));
                      if (value && (isNaN(numericValue) || numericValue <= 0)) {
                        return Promise.reject(
                          "El precio máximo debe ser mayor a 0 y un número válido."
                        );
                      }
                      if (!isNaN(minPrice) && numericValue <= minPrice) {
                        return Promise.reject(
                          "El precio máximo debe ser mayor que el precio mínimo."
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  style={{ width: "50%" }}
                  placeholder="Precio máximo"
                  prefix="$"
                  type="number"
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </>
      )}

      {businessModel === "medical" && (
        <Form.Item
          label="Tipo de consultorio"
          name="consultationType"
          rules={[
            {
              required: true,
              message: "Por favor seleccione el tipo de consultorio",
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Seleccione el tipo de consultorio"
            options={[
              { label: "Médico", value: "medical" },
              { label: "Jurídico", value: "legal" },
              { label: "Odontológico", value: "dental" },
            ]}
          />
        </Form.Item>
      )}

      <PhysicalPointModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onAdd={handleAddPhysicalPoint}
      />

      <Form.Item style={{ marginTop: 32 }}>
        <Button size="large" onClick={prevStep} style={{ marginRight: 8 }}>
          Anterior
        </Button>
        <Button size="large" type="primary" htmlType="submit">
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  );
}