import React, { useState, useEffect } from "react";
import { Modal, Input, Button, List, message, Switch } from "antd";

const { TextArea } = Input;

interface PlansModalProps {
  open: boolean;
  onClose: () => void;
  formData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSave: () => void;
  handleUpdate: () => void;
  action: { type: string } | null;
  loading: boolean;
}

const PlansModal: React.FC<PlansModalProps> = ({
  open,
  onClose,
  formData,
  handleChange,
  handleSave,
  handleUpdate,
  action,
  loading,
}) => {
  const isEdit = action?.type === "edit";
  const [featureInput, setFeatureInput] = useState("");
  const [editingFeature, setEditingFeature] = useState<number | null>(null);
  const maxFeatures = 5;
  const maxFeatureLength = 50;

  const features: string[] = JSON.parse(formData.plan_features || "[]");

  useEffect(() => {
    if (formData.isCustom === "true") {
      handleChange({
        target: {
          id: "plan_price",
          value: "Plan cotizado",
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleChange({
        target: {
          id: "plan_message_count",
          value: "Más de 10.000",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [formData.isCustom]);

  const handleAddFeature = () => {
    if (featureInput.trim().length > maxFeatureLength) {
      message.warning(
        `Cada característica puede tener un máximo de ${maxFeatureLength} caracteres.`
      );
      return;
    }

    if (featureInput.trim()) {
      const newFeatures = [...features, featureInput];
      handleChange({
        target: {
          id: "plan_features",
          value: JSON.stringify(newFeatures),
        },
      } as React.ChangeEvent<HTMLInputElement>);
      setFeatureInput("");
    }
  };

  const handleEditFeature = (index: number, value: string) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = value;
    handleChange({
      target: {
        id: "plan_features",
        value: JSON.stringify(updatedFeatures),
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleSaveFeatureEdit = (index: number, value: string) => {
    handleEditFeature(index, value);
    setEditingFeature(null);
  };

  const handleDeleteFeature = (index: number) => {
    const updatedFeatures = features.filter((_, i) => i !== index);
    handleChange({
      target: {
        id: "plan_features",
        value: JSON.stringify(updatedFeatures),
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleSwitchChange = (checked: boolean) => {
    handleChange({
      target: {
        id: "isCustom",
        value: String(checked),
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);

    if (checked) {
      handleChange({
        target: {
          id: "plan_price",
          value: "Plan cotizado",
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleChange({
        target: {
          id: "plan_message_count",
          value: "Más de 10.000",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      handleChange({
        target: {
          id: "plan_price",
          value: "",
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleChange({
        target: {
          id: "plan_message_count",
          value: "",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Modal
      title={isEdit ? "Editar Plan" : "Crear Plan"}
      visible={open}
      onCancel={!loading ? onClose : undefined}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={isEdit ? handleUpdate : handleSave}
          loading={loading}
          disabled={loading}
        >
          {isEdit ? "Actualizar" : "Crear"}
        </Button>,
      ]}
      maskClosable={!loading}
      closable={!loading}
    >
      <Input
        id="plan_name"
        placeholder="Nombre del Plan"
        value={formData.plan_name}
        onChange={handleChange}
        style={{ marginBottom: "10px" }}
        disabled={loading}
      />
      <TextArea
        id="plan_description"
        placeholder="Descripción del Plan"
        value={formData.plan_description}
        onChange={handleChange}
        style={{ marginBottom: "10px" }}
        disabled={loading}
        rows={4}
      />
      <Input
        id="plan_price"
        placeholder="Precio"
        value={formData.plan_price}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "");
          handleChange({
            target: {
              id: "plan_price",
              value,
            },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        type="number"
        style={{ marginBottom: "10px" }}
        disabled={formData.isCustom === "true" || loading}
      />
      <Input
        id="plan_message_count"
        placeholder="Cantidad de Mensajes"
        value={formData.plan_message_count}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "");
          handleChange({
            target: {
              id: "plan_message_count",
              value,
            },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        type="number"
        style={{ marginBottom: "10px" }}
        disabled={formData.isCustom === "true" || loading}
      />
      <div style={{ marginBottom: "10px" }}>
        <Input
          placeholder="Agregar característica"
          value={featureInput}
          onChange={(e) => setFeatureInput(e.target.value)}
          onPressEnter={handleAddFeature}
          maxLength={maxFeatureLength}
          style={{ width: "80%", marginRight: "10px" }}
          disabled={features.length >= maxFeatures || loading}
        />
        <Button
          type="primary"
          onClick={handleAddFeature}
          disabled={features.length >= maxFeatures || loading}
        >
          Agregar
        </Button>
        <div
          style={{
            marginTop: "5px",
            color: features.length >= maxFeatures ? "red" : "gray",
          }}
        >
          {`${features.length}/${maxFeatures} características`}
        </div>
        <List
          bordered
          dataSource={features}
          renderItem={(item: string, index: number) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => handleDeleteFeature(index)}
                  style={{ color: "red" }}
                  disabled={loading}
                >
                  Eliminar
                </Button>,
              ]}
            >
              {editingFeature === index ? (
                <Input
                  value={item}
                  onChange={(e) => handleEditFeature(index, e.target.value)}
                  onBlur={() => handleSaveFeatureEdit(index, item)}
                  onPressEnter={() => handleSaveFeatureEdit(index, item)}
                  maxLength={maxFeatureLength}
                  autoFocus
                />
              ) : (
                <span onClick={() => setEditingFeature(index)}>{item}</span>
              )}
            </List.Item>
          )}
          style={{ marginTop: "10px" }}
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <span style={{ marginRight: "10px" }}>Plan Personalizado:</span>
        <Switch
          checked={formData.isCustom === "true"}
          onChange={handleSwitchChange}
          disabled={loading}
        />
      </div>
    </Modal>
  );
};

export default PlansModal;