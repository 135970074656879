import { useState, useEffect } from 'react';
import { Form, Input, Button, List, Pagination, message, Empty } from 'antd';

interface FAQProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
}

export default function FAQ({ updateFormData, nextStep, prevStep, initialValues }: FAQProps) {
  const [faqs, setFaqs] = useState<{ question: string; answer: string }[]>(initialValues.faqs || []);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 3;

  useEffect(() => {
    if (initialValues.faqs) {
      setFaqs(initialValues.faqs);
    }
  }, [initialValues.faqs]);

  const addFAQ = (values: { question: string; answer: string }) => {
    if (values.question && values.answer) {
      setFaqs([...faqs, values]);
      form.resetFields();
    }
  };

  const handleAddFAQ = () => {
    form.validateFields()
      .then(values => {
        addFAQ(values);
      })
      .catch(errorInfo => {
        // Maneja errores si es necesario
      });
  };

  const handleSubmit = () => {
    if (faqs.length === 0) {
      message.error('Por favor, agrega al menos una FAQ antes de continuar.');
      return;
    }
    updateFormData({ faqs });
    nextStep();
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleDeleteFAQ = (index: number) => {
    const newFaqs = faqs.filter((_, i) => i !== index);
    setFaqs(newFaqs);
  };

  const paginatedFaqs = faqs.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      style={{width: '100%'}}
    >
      <Form.Item
        label="Pregunta"
        name="question"
        tooltip="Escribe una pregunta frecuente que tus clientes podrían tener sobre tu negocio o servicios."
        rules={[{ required: true, message: 'Por favor ingrese una pregunta frecuente.' }]}
      >
        <Input size="large" placeholder="Ej: ¿Cuál es el horario de atención?" />
      </Form.Item>
      <Form.Item
        label="Respuesta"
        name="answer"
        tooltip="Proporciona una respuesta clara y útil a la pregunta frecuente ingresada."
        rules={[{ required: true, message: 'Por favor ingrese una respuesta a la pregunta frecuente.' }]}
      >
        <Input.TextArea size="large" placeholder="Ej: Nuestro horario de atención es de 8 am a 10 pm." />
      </Form.Item>
      <Form.Item>
        <Button 
          type="primary" 
          size="large"
          onClick={handleAddFAQ}
        >
          Agregar FAQ
        </Button>
      </Form.Item>

      <List
        header={<div>FAQs agregadas</div>}
        bordered
        dataSource={paginatedFaqs}
        locale={{ emptyText: <Empty description="No hay FAQs agregadas" /> }}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Button type="link" danger onClick={() => handleDeleteFAQ(index)}>
                Eliminar
              </Button>,
            ]}
          >
            <List.Item.Meta title={item.question} description={item.answer} />
          </List.Item>
        )}
      />

      {faqs.length > pageSize && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={faqs.length}
          onChange={handlePaginationChange}
          style={{ marginTop: '16px', textAlign: 'center' }}
        />
      )}

      <Form.Item style={{ marginTop: '32px' }}>
        <Button size="large" onClick={prevStep} style={{ marginRight: 8 }}>
          Anterior
        </Button>
        <Button 
          size="large" 
          type="primary" 
          htmlType="submit"
          onClick={handleSubmit}
        >
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  );
}