import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Button,
  Avatar,
  Spin,
  List,
  message as antdMessage,
  Tooltip,
} from "antd";
import {
  SendOutlined,
  WhatsAppOutlined,
  ReloadOutlined,
  LoadingOutlined,
  CrownOutlined,
  UserOutlined,
  RobotOutlined,
  TeamOutlined,
  SearchOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import Picker from "@emoji-mart/react"; // Importar Picker de @emoji-mart/react
import data from "@emoji-mart/data"; // Importar los datos de emoji-mart
import webSocketService from "../../services/WebSocketService";
import { get, post } from "../../Api/http/httpClient";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import moment from "moment";
import "./WhatsAppChat.css";
import { v4 as uuidv4 } from "uuid";

// Definición de un mensaje
interface Message {
  id: string;
  text: string;
  timestamp: string; // ISO string
  role: "user" | "assistant" | "agent";
  loading?: boolean;
}

// Definición de un ítem de chat
interface ChatItem {
  chat_uuid: string;
  name_user: string;
  history: Message[];
}

// Definición de las propiedades del componente
interface WhatsAppChatProps {
  user_data: {
    "custom:establishment_id": string;
    "custom:wppConfigured": string; // Puede ser 'true' o 'false'
    "custom:plan": string; // Puede ser '0' o algún otro valor
    // Añade otros campos según sea necesario
  };
}

const formatTimestamp = (timestamp: string): string => {
  return moment(timestamp).format("DD/MM/YYYY HH:mm:ss");
};

const WhatsAppChat: React.FC<WhatsAppChatProps> = ({ user_data }) => {
  // Estados con tipos específicos
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [chatList, setChatList] = useState<ChatItem[]>([]);
  const [selectedChatUUID, setSelectedChatUUID] = useState<string | null>(null);
  const [loadingChats, setLoadingChats] = useState<boolean>(false);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false); // Nuevo estado
  const [searchQuery, setSearchQuery] = useState<string>(""); // Nuevo estado para buscador
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false); // Estado para emoji picker

  // Referencia tipada para el scroll
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Función para enviar mensajes
  const sendMessageToWhatsApp = async () => {
    if (!newMessage.trim()) return;
    setIsSending(true);

    try {
      const res = await post("/chat-ai", {
        establishment_id: user_data["custom:establishment_id"],
        name_establishment: "Restaurante Stellion",
        chat_uuid: selectedChatUUID,
        whatsapp_message: newMessage,
        whatsapp_send: true,
      });

      if (res.data.answer) {
        const newMsg: Message = {
          id: uuidv4(), // Generar ID único
          text: newMessage,
          timestamp: new Date().toISOString(),
          role: "user",
        };

        setMessages((prevMessages) => [...prevMessages, newMsg]);
        setNewMessage("");
      } else {
        antdMessage.error("Error al enviar el mensaje a WhatsApp.");
      }
    } catch (error) {
      antdMessage.error("Error al conectar con la API.");
    } finally {
      setIsSending(false);
    }
  };

  // Manejar el envío de mensajes
  const handleSendMessage = () => {
    if (!isConnected || !selectedChatUUID) {
      antdMessage.warning(
        "Por favor, selecciona un chat antes de enviar un mensaje."
      );
      return;
    }

    if (newMessage.trim() !== "") {
      sendMessageToWhatsApp();
    }
  };

  // Obtener datos de los chats
  const getChatData = async () => {
    setLoadingChats(true);
    try {
      const res = await get(
        `/chat-ai?establishmentID=${user_data["custom:establishment_id"]}&return_all=true&channel_filter=whatsapp`
      );

      if (res.answer && res.items) {
        setChatList(
          res.items.map((chat: any) => ({
            chat_uuid: chat.chat_uuid,
            name_user: chat.name_user || "Usuario no identificado",
            history: chat.history.map((msg: any) => ({
              id: uuidv4(),
              text: msg.message,
              timestamp: msg.timestamp,
              role: msg.role,
            })),
          }))
        );
      } else {
        antdMessage.error("No se encontraron chats.");
      }
    } catch (error) {
      antdMessage.error("Error al obtener los datos de chats.");
    } finally {
      setLoadingChats(false);
    }
  };

  // Obtener historial de chat
  const fetchChatHistory = async (chatUUID: string) => {
    setLoadingHistory(true);
    try {
      const res = await get(
        `/chat-ai?establishmentID=${user_data["custom:establishment_id"]}&chat_uuid=${chatUUID}`
      );

      if (res.answer && res.data) {
        const combinedMessages: Message[] = res.data.history
          .filter(
            (item: any) =>
              item.message !== "ß-Mensaje enviado por la plataforma-ß"
          )
          .map((item: any) => ({
            id: uuidv4(), // Generar ID único
            text: item.message,
            timestamp: item.timestamp,
            role: item.role, // "user", "assistant" o "agent"
          }));

        setMessages(combinedMessages);
      } else {
        antdMessage.error("No se encontraron mensajes para este chat.");
      }
    } catch (error) {
      antdMessage.error("Error al obtener el historial de chat.");
    } finally {
      setLoadingHistory(false);
    }
  };

  // Función para conectar el WebSocket con un chat_uuid específico
  const connectWebSocket = (chat_uuid: string) => {
    if (!chat_uuid) return;
    setIsConnecting(true);

    // Desconectar cualquier conexión existente antes de conectar una nueva
    webSocketService.disconnect();

    console.log(`Intentando conectar WebSocket con chat_uuid: ${chat_uuid}`);

    webSocketService.connect(
      user_data,
      chat_uuid,
      "CHAT",
      null,
      () => {
        console.log("WebSocket conectado exitosamente.");
        setIsConnected(true);
        setIsConnecting(false);
      },
      () => {
        console.log("WebSocket desconectado.");
        setIsConnected(false);
        setIsConnecting(false);
        antdMessage.error("WebSocket desconectado. Intenta reconectar.");
      },
      () => {
        console.log("WebSocket falló al conectar.");
        setIsConnected(false);
        setIsConnecting(false);
        antdMessage.error("WebSocket falló al conectar. Intenta reconectar.");
      }
    );
  };

  // Manejar la selección de un chat desde el sidebar
  const handleChatSelect = (chat_uuid: string) => {
    setSelectedChatUUID(chat_uuid);
    fetchChatHistory(chat_uuid);
    connectWebSocket(chat_uuid); // Pasar el chat_uuid directamente
  };

  // Manejar la actualización global de todos los chats
  const handleRefreshAllChats = async () => {
    setIsRefreshing(true);
    try {
      await getChatData(); // Reutilizamos la función existente para obtener los datos de los chats
      antdMessage.success("Historiales de chats actualizados correctamente.");
    } catch (error) {
      // La función getChatData ya maneja los mensajes de error, pero puedes agregar lógica adicional si es necesario
    } finally {
      setIsRefreshing(false);
    }
  };

  // Configuración inicial
  useEffect(() => {
    if (user_data["custom:wppConfigured"] === "false") {
      antdMessage.warning(
        "Por favor, configura tu WhatsApp para comenzar."
      );
      // Opcional: Redirigir a configuración si es necesario
      // window.location.href = "/wpp-config";
    } else if (user_data["custom:plan"] !== "0") {
      getChatData();
    }
  }, [user_data]);

  // Limpieza al desmontar el componente
  useEffect(() => {
    return () => {
      webSocketService.disconnect();
    };
  }, []);

  // Scroll automático al final de los mensajes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Formatear el contenido del mensaje
  const formatMessageContent = (text: string) => {
    const imageRegex = /\.(jpeg|jpg|gif|png)$/i;

    if (imageRegex.test(text)) {
      return (
        <img
          src={text}
          alt="Imagen enviada"
          style={{ maxWidth: "100%", borderRadius: "8px", marginTop: "10px" }}
        />
      );
    }

    return (
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm]}
        components={{
          img: ({ node, ...props }) => (
            <img
              src={props.src || ""}
              alt={props.alt || "Imagen enviada"}
              style={{
                maxWidth: "100%",
                borderRadius: "8px",
                marginTop: "10px",
              }}
            />
          ),
          ul: ({ node, ...props }) => (
            <ul style={{ paddingLeft: "20px", marginTop: "10px" }} {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol style={{ paddingLeft: "20px", marginTop: "10px" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "5px" }} {...props} />
          ),
          p: ({ node, ...props }) => (
            <p style={{ margin: "5px 0" }} {...props} />
          ),
        }}
      />
    );
  };

  // Manejar mensajes entrantes del WebSocket
  const handleIncomingWebSocketMessage = (data: any) => {
    // Validar que el rol sea 'assistant' o 'agent'
    const validRoles = ["assistant", "agent"];
    const role = validRoles.includes(data.role) ? data.role : "assistant";

    const newAssistantMessage: Message = {
      id: uuidv4(), // Generar ID único
      text: data.message,
      timestamp: data.timestamp,
      role: role, // "assistant" o "agent"
    };

    setMessages((prevMessages) => [...prevMessages, newAssistantMessage]);
  };

  // Configurar callback para mensajes del WebSocket
  useEffect(() => {
    webSocketService.setOnMessageCallback(handleIncomingWebSocketMessage);
  }, []);

  // Filtrar la lista de chats según la búsqueda
  const filteredChatList = chatList.filter((chat) =>
    chat.name_user.toLowerCase().includes(searchQuery.toLowerCase()) ||
    chat.chat_uuid.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Función para añadir un emoji al mensaje
  const addEmoji = (emoji: any) => {
    setNewMessage((prevMessage) => prevMessage + emoji.native);
    setShowEmojiPicker(false); // Cerrar el picker después de seleccionar
  };

  // Toggle para mostrar/ocultar el picker de emojis
  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  return (
    <div className="whatsapp-chat-wrapper">
      {/* Sidebar Izquierdo: Lista de Chats */}
      <div className="whatsapp-chat-sidebar">
        <div className="whatsapp-chat-sidebar-header">
          <h3>Historial de Chats</h3>
          <Tooltip title="Actualizar todos los historiales">
            <Button
              type="text"
              icon={<ReloadOutlined />}
              onClick={handleRefreshAllChats}
              loading={isRefreshing}
              title="Actualizar todos los historiales"
            />
          </Tooltip>
        </div>

        {/* Buscador de Chats */}
        <Input
          placeholder="Buscar chats"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          allowClear
          className="search-input" // Cambiar el estilo a través de una clase
          prefix={<SearchOutlined />}
        />

        {loadingChats ? (
          <div className="loading-container">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : filteredChatList.length === 0 ? (
          <div className="no-chats">No se encontraron chats.</div>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={filteredChatList}
            renderItem={(chat: ChatItem) => (
              <List.Item
                className={`ant-list-item chat-item ${
                  selectedChatUUID === chat.chat_uuid ? "selected-chat" : ""
                }`}
                onClick={() => handleChatSelect(chat.chat_uuid)}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={
                    <span
                      className="ant-list-item-meta-title"
                      style={{
                        fontWeight: "bold",
                        color:
                          selectedChatUUID === chat.chat_uuid
                            ? "#0056b3"
                            : "#333",
                      }}
                    >{`Usuario: ${chat.name_user}`}</span>
                  }
                  description={
                    <span
                      style={{
                        color:
                          selectedChatUUID === chat.chat_uuid
                            ? "#0056b3"
                            : "#666",
                      }}
                    >{`Chat UUID: ${chat.chat_uuid}`}</span>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </div>

      {/* Área Principal de Chat */}
      <div className="whatsapp-chat-container">
        {/* Header del chat */}
        <div className="whatsapp-chat-header">
          <Avatar size="large" icon={<WhatsAppOutlined />} className="mr-2" />
          <div className="chat-name">WhatsApp Bot</div>

          {user_data["custom:plan"] !== "0" && (
            <div className="connection-status-wrapper">
              <div
                className={`connection-status-circle ${
                  isConnecting
                    ? "connecting-circle"
                    : isConnected
                    ? "connected-circle"
                    : "disconnected-circle"
                }`}
              ></div>
              <div className="connection-status-text">
                {isConnecting
                  ? "Conectando..."
                  : isConnected
                  ? "Conectado"
                  : "Desconectado"}
              </div>
            </div>
          )}

          {isConnecting && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}

          {user_data["custom:plan"] === "0" ? (
            <Button
              type="primary"
              icon={<CrownOutlined />}
              onClick={() => (window.location.href = "/plans")}
              style={{ marginLeft: "10px" }}
            >
              Actualízate a Premium
            </Button>
          ) : (
            selectedChatUUID && !isConnected && (
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={() => connectWebSocket(selectedChatUUID)} // Pasar selectedChatUUID
                style={{ marginLeft: "10px" }}
                disabled={isConnecting}
              >
                Reconectar
              </Button>
            )
          )}
        </div>

        {/* Área de mensajes */}
        <div className="whatsapp-chat-messages">
          {loadingHistory ? (
            <div className="loading-container">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : !selectedChatUUID ? (
            <div className="no-chat-selected">Selecciona un chat para cargar la conversación.</div>
          ) : messages.length === 0 ? (
            <div className="no-messages">No hay mensajes en este chat.</div>
          ) : (
            messages.map((msg: Message) => (
              <div
                key={msg.id}
                className={`chat-message ${
                  msg.role === "assistant"
                    ? "received"
                    : msg.role === "agent"
                    ? "agent-received"
                    : "sent"
                }`}
              >
                {/* Avatar según el rol */}
                {msg.role === "user" && (
                  <Avatar
                    icon={<UserOutlined />}
                    className="message-avatar"
                    style={{ backgroundColor: "#52c41a" }} // Verde para user
                  />
                )}

                {msg.role === "assistant" && (
                  <Avatar
                    icon={<RobotOutlined />}
                    className="message-avatar"
                    style={{ backgroundColor: "#273c75" }} // Azul oscuro para assistant
                  />
                )}

                {msg.role === "agent" && (
                  <Avatar
                    icon={<TeamOutlined />}
                    className="message-avatar"
                    style={{ backgroundColor: "#44bd32" }} // Verde diferente para agent
                  />
                )}

                {/* Burbujas de mensaje */}
                <div className="chat-message-content">
                  {msg.loading ? <Spin /> : formatMessageContent(msg.text)}
                  <div className="chat-message-time">
                    {formatTimestamp(msg.timestamp)}
                  </div>
                </div>
              </div>
            ))
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input de mensajes */}
        <div className="whatsapp-chat-input">
          {/* Contenedor para el Input y el Botón de Emojis */}
          <div className="input-container">
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Escribe un mensaje"
              onPressEnter={handleSendMessage}
              disabled={isSending || !isConnected}
              className="message-input"
            />
            <Button
              type="text"
              icon={<SmileOutlined />}
              onClick={toggleEmojiPicker}
              className="emoji-button"
            />
            {showEmojiPicker && (
              <div className="emoji-picker">
                <Picker data={data} onEmojiSelect={addEmoji} theme="light" />
              </div>
            )}
          </div>
          {/* Botón de Enviar */}
          <Button
            type="primary"
            shape="circle"
            icon={isSending ? <LoadingOutlined /> : <SendOutlined />}
            onClick={handleSendMessage}
            disabled={!isConnected || isSending}
            className="chat-send-button"
          />
        </div>
      </div>
    </div>
  );
};

export default WhatsAppChat;