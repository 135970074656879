import React from "react";
import WhatsAppChat from "../../../components/chat/WhatsAppChat";
import "./ChatPageWhatsApp.css";

interface ChatPageProps {
  user_data: any;
}

const ChatPageWhatsApp: React.FC<ChatPageProps> = ({ user_data }) => {
  return (
    <div className="chat-page-container">
      <WhatsAppChat user_data={user_data} />
    </div>
  );
};

export default ChatPageWhatsApp;