/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    // "aws_cognito_identity_pool_id": "us-west-2:f25e6f82-9294-4e77-87e0-fd9433a4a602",
    "aws_cognito_region": "us-east-1",
    // "aws_user_pools_id": "us-west-2_GhpEUAXWE",
    "aws_user_pools_id": "us-east-1_4ixbr0GOD",
    // "aws_user_pools_web_client_id": "4map984jk5l5a59du5613r8d8l",
    "aws_user_pools_web_client_id": "4s27fdb63kr6mgnvqbqjv6lkur",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
