import styled from 'styled-components';

export const FooterNav = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
    gap: 100px;
    margin: 100px 0;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
    @media (max-width: 480px) {
        flex-direction: column;
        justify-content: center;
        gap: 40px;
    }
`;

export const ContactColumn = styled.div`
    width: 100%;
    font-family: poppins-regular;
    font-size: 14px;
    color: rgba(236, 236, 236, 1);
    div {
        display: flex;
        gap: 8px;
        a {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: rgba(236, 236, 236, 1);
            border: 1px solid transparent;
            &:hover {
                border-color: rgba(236, 236, 236, 1);
            }
        }
    }
    @media (max-width: 1024px) {
        flex: 1 0 auto;
        text-align: center;
        div {
            justify-content: center;
        }
    }
`;

export const NavColumn = styled.div`
    flex: 1 0 auto;
    font-family: poppins-semibold;
    font-size: 13px;
    p {
        text-transform: uppercase;
        color: rgba(14, 110, 175, 1);
    }
    a {
        display: block;
        text-decoration: none;
        color: rgba(236, 236, 236, 1);
        padding-bottom: 20px;
        &:hover {
            text-decoration: underline;
        }
    }
    @media (max-width: 480px) {
        width: 100%;
        text-align: center;
    }
`;

export const Copy = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    margin: 0 100px;
    color: rgba(113, 113, 122, 1);
    font-family: poppins-regular;
    font-size: 14px;
    line-height: normal;
    position: relative;
    &::after{
        content: '';
        width: calc(100% - 200px);
        position: absolute;
        top: 0;
        height: 1px;
        background-color: rgba(226, 232, 240, 1);
        @media (max-width: 1024px) {
            width: calc(100% - 40px);
        }
    }
    @media (max-width: 480px) {
        padding: 50px 20px 0;
        text-align: center;
    }
`;