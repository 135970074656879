const WEBSOCKET_URL = 'wss://ln77illps0.execute-api.us-east-1.amazonaws.com/production';

class WebSocketService {
  constructor() {
    this.user = null;
    this.websocket = null;
    this.isWebSocketEnabled = true; // Habilitar o deshabilitar la conexión del WebSocket
    this.onMessageCallback = null; // Callback para manejar los mensajes recibidos
  }

  setOnMessageCallback(callback) {
    this.onMessageCallback = callback; // Asignar el callback
  }

  connect(user, sessionId, connectionType, transactionReference, onOpenCallback, onCloseCallback, onErrorCallback) {
    if (!this.isWebSocketEnabled) {
      console.log('WebSocket connection is disabled');
      return;
    }

    // Asegurarse de que no haya un WebSocket activo antes de crear uno nuevo
    if (!this.websocket || this.websocket.readyState === WebSocket.CLOSED) {
      const email = user.email;
      const establishmentID = user['custom:establishment_id'];
      const name_stablishment = user.family_name;

      this.websocket = new WebSocket(
        `${WEBSOCKET_URL}?email=${email}&establishmentID=${establishmentID}&name_establishment=${encodeURIComponent(
          name_stablishment
        )}&phone=${sessionId}&connectionType=${connectionType}&reference=${transactionReference}`
      );

      this.websocket.onopen = () => {
        console.log('Successfully connected to WebSocket');
        if (onOpenCallback) onOpenCallback(); // Llamar a la función pasada para manejar el estado
      };

      this.websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (this.onMessageCallback) {
          this.onMessageCallback(data); // Llamar al callback pasado desde WhatsAppChat
        } else {
          console.warn('No message callback set.');
        }
      };

      this.websocket.onclose = () => {
        console.log('Disconnected from WebSocket');
        this.websocket = null; // Limpiar el WebSocket después de desconectarse
        if (onCloseCallback) onCloseCallback(); // Llamar a la función pasada para manejar el estado
      };

      this.websocket.onerror = (error) => {
        console.error('WebSocket error:', error);
        if (onErrorCallback) onErrorCallback(); // Llamar a la función de error
      };
    }
  }

  disconnect() {
    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
      console.log('WebSocket connection closed');
    }
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;