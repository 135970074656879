import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  Select,
  notification,
  Skeleton,
  Form,
  Spin,
} from "antd";
import { getCurrentUser, fetchUserAttributes, signIn } from "@aws-amplify/auth";
import {
  CreditCardOutlined,
  BankOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./Checkout.css";
import PaymentForm from "../../components/payments/PaymentForm";
import PseForm from "../../components/payments/PseForm";
import { get, post, getWompi, postWompi } from "../../Api/http/httpClient";
import webSocketService from "../../services/WebSocketService";
import Lottie from "react-lottie"; // Importa Lottie para las animaciones
import success from "../../assets/animations/success.json"; // Importa la animación de éxito
import error from "../../assets/animations/error.json";

interface Coupon {
  code: string;
  discount: number;
}

interface WebSocketData {
  action: string;
  type: string;
  data: {
    reference: string;
    status: string;
    amount_in_cents: number;
    currency: string;
    payment_method_type: string;
    async_payment_url: string;
  };
}

interface PaymentDataToSend {
  email: string;
  phone_number: string;
  family_name: string;
  establishment_id: string;
  plan: string;
  reference: string;
  payment_reference: string;
  payment_method_type: string;
  amount_in_cents: number;
  status: string;
  transaction_date: string;
  finalized_at: string;
  currency: string;
  full_name: string;
  establishment: string;
  plan_title: string;
  plan_messages: string;
  card_token?: string;
  name_card?: string;
  autoRenew?: boolean;
  token_expires_at?: string;
}

const { Option } = Select;

const enabledMethods = ["CARD", "PSE"];

// Funciones para obtener datos de bancos y comerciantes
const fetchBanks = async () => {
  const response = await getWompi("v1/pse/financial_institutions");
  return response.data;
};

const fetchMerchantData = async () => {
  const response = await getWompi(
    "v1/merchants/pub_test_0XZMwzNEJxOEaq0inSCtcLEJhSlaJfCK"
  );
  return response.data;
};

const coupons: Coupon[] = [
  { code: "DESCUENTO10", discount: 10 },
  { code: "DESCUENTO20", discount: 20 },
  { code: "OFERTA25", discount: 25 },
];

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Obtener el plan desde location.state o localStorage
  const [plan, setPlan] = useState(() => {
    const storedPlan = localStorage.getItem("selectedPlan");
    return location.state?.plan || (storedPlan ? JSON.parse(storedPlan) : null);
  });

  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState<string | null>(null);
  const [showCouponInput, setShowCouponInput] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userPassword, setUserPassword] = useState<string | null>(null); // Nuevo estado para la contraseña
  const [userAttributes, setUserAttributes] = useState<any>(null);
  const [loadingEmail, setLoadingEmail] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("CARD");
  const [banks, setBanks] = useState<any[]>([]);
  const [pseDetails, setPseDetails] = useState({
    bank: "",
    personType: "0",
    documentType: "",
    documentNumber: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiry: "",
    cvv: "",
  });
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [acceptanceToken, setAcceptanceToken] = useState<string | null>(null);
  const [permalink, setPermalink] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [transactionStatus, setTransactionStatus] = useState<string | null>(
    null
  );
  const [isRedirectingToPSE, setIsRedirectingToPSE] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  // Estado para el contador de redirección
  const [countdown, setCountdown] = useState(10);

  const [form] = Form.useForm();

  // Guardar el plan en localStorage si está disponible
  useEffect(() => {
    if (plan) {
      localStorage.setItem("selectedPlan", JSON.stringify(plan));
    }
  }, [plan]);

  // Si no hay plan, redirigir a la página de planes
  useEffect(() => {
    if (!plan) {
      navigate("/plans");
    }
  }, [plan, navigate]);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          setIsAuthenticated(true);
          const attributes = await fetchUserAttributes();
          setUserAttributes(attributes);
          const email = attributes?.email || "Email no encontrado";
          setUserEmail(email);
          setPseDetails((prev) => ({ ...prev, email }));
        }
      } catch (error) {
        setIsAuthenticated(false);
        console.error("Error fetching user attributes:", error);
      } finally {
        setLoadingEmail(false);
      }
    };

    const loadData = async () => {
      try {
        fetchAttributes();
        const bankList = await fetchBanks();
        setBanks(bankList || []);

        const merchantData = await fetchMerchantData();
        const methods = merchantData.accepted_payment_methods.map(
          (method: string) => {
            const label =
              method === "CARD"
                ? "Tarjeta"
                : method.replace(/_/g, " ").toUpperCase();
            return {
              value: method,
              label,
              icon:
                method === "CARD" ? <CreditCardOutlined /> : <BankOutlined />,
              enabled: enabledMethods.includes(method),
            };
          }
        );
        setPaymentMethods(methods);
        setAcceptanceToken(merchantData.presigned_acceptance.acceptance_token);
        setPermalink(merchantData.presigned_acceptance.permalink);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [plan, navigate]);

  // Bloquear la navegación hacia atrás o fuera de la página
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (isProcessing) {
        const confirmLeave = window.confirm(
          "La transacción aún está en proceso. ¿Estás seguro de que deseas salir de la página?"
        );
        if (!confirmLeave) {
          window.history.pushState(null, "", window.location.href); // Evitar que el usuario navegue
        }
      }
    };

    window.history.pushState(null, "", window.location.href); // Bloquear la navegación hacia atrás
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isProcessing]);

  // Evitar que el usuario refresque o cierre la pestaña durante la transacción
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (isProcessing && !isRedirectingToPSE) {
        const confirmLeave = window.confirm(
          "La transacción aún está en proceso. ¿Estás seguro de que deseas salir de la página?"
        );
        if (!confirmLeave) {
          window.history.pushState(null, "", window.location.href); // Evitar que el usuario navegue
        }
      }
    };

    window.history.pushState(null, "", window.location.href); // Bloquear la navegación hacia atrás
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isProcessing, isRedirectingToPSE]);

  useEffect(() => {
    if (selectedPaymentMethod === "CARD") {
      setPseDetails({
        bank: "",
        personType: "0",
        documentType: "",
        documentNumber: "",
        fullName: "",
        email: pseDetails.email,
        phoneNumber: "",
      });
    } else if (selectedPaymentMethod === "PSE") {
      setCardDetails({
        cardNumber: "",
        expiry: "",
        cvv: "",
      });
    }
  }, [selectedPaymentMethod, pseDetails.email]);

  if (!plan) {
    // Mostrar un loader mientras se redirige
    return (
      <div className="checkout-page">
        <Spin
          size="large"
          indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
        />
      </div>
    );
  }

  const { title, messages, code_plan, price } = plan;

  const handleGoToDashboard = () => {
    if (!isProcessing) {
      navigate("/dashboard");
    }
  };

  const applyCoupon = () => {
    const foundCoupon = coupons.find(
      (coupon: Coupon) => coupon.code === couponCode.toUpperCase()
    );
    if (foundCoupon) {
      setDiscount(foundCoupon.discount);
      setAppliedCoupon(foundCoupon.code);
      setIsCouponApplied(true);
      notification.success({
        message: `Cupón aplicado: ${foundCoupon.code} (${foundCoupon.discount}% de descuento)`,
      });
    } else {
      notification.error({ message: "Cupón inválido" });
      setDiscount(0);
      setIsCouponApplied(false);
      setAppliedCoupon(null);
    }
    setCouponCode("");
  };

  const connectWebSocket = (transactionReference: string) => {
    webSocketService.connect(
      userAttributes,
      "573108888888", // Sesión de referencia (ej. número de teléfono)
      "PAYMENT",
      transactionReference,
      () => console.log("WebSocket connected"),
      () => console.log("WebSocket disconnected"),
      (error: any) => console.log("WebSocket error", error)
    );

    webSocketService.setOnMessageCallback((data: WebSocketData) => {
      if (data.data.payment_method_type === "CARD") {
        switch (data.data.status) {
          case "APPROVED":
            setTransactionStatus("APPROVED");
            notification.success({
              message: "Pago aprobado",
              description: `El pago con referencia ${data.data.reference} ha sido aprobado exitosamente.`,
            });
            startCountdown(data.data);
            break;
          case "DECLINED":
            setTransactionStatus("DECLINED");
            notification.error({
              message: "Pago rechazado",
              description: `El pago con referencia ${data.data.reference} ha sido rechazado. Verifica los datos e inténtalo nuevamente.`,
            });
            startCountdown(data.data);
            break;
          case "VOIDED":
            setTransactionStatus("VOIDED");
            notification.warning({
              message: "Pago anulado",
              description: `El pago con referencia ${data.data.reference} fue anulado.`,
            });
            startCountdown(data.data);
            break;
          case "ERROR":
            setTransactionStatus("ERROR");
            notification.error({
              message: "Error en el pago",
              description: `Ocurrió un error con el pago con referencia ${data.data.reference}. Por favor, inténtalo más tarde.`,
            });
            break;
          default:
            break;
        }
        setIsProcessing(false);
      } else if (data.data.payment_method_type === "PSE") {
        if (data.data.async_payment_url) {
          notification.success({
            message: "Proceso de pago PSE",
            description:
              "Transacción creada. El pago de PSE continuará en otra página.",
          });
          setIsRedirectingToPSE(true);
          window.location.href = data.data.async_payment_url;
        }
      }
    });
  };

  const fetchNewAcceptanceToken = async () => {
    try {
      const merchantData = await fetchMerchantData();
      setAcceptanceToken(merchantData.presigned_acceptance.acceptance_token);
    } catch (error) {
      console.error("Error fetching new acceptance token:", error);
      notification.error({
        message: "Error al obtener el nuevo acceptance token",
      });
    }
  };

  const handlePaymentData = async (paymentMethod: string, paymentData: any) => {
    setIsProcessing(true);

    try {
      const amountInCents = Math.round(plan.price * 100);

      // Crea el payload de la firma
      const signaturePayload = {
        planCode: plan.code_plan,
        planName: plan.title,
        planValue: amountInCents,
        discountCode: paymentData.discountCode || "",
      };

      let signatureResponse;
      try {
        // Solicita la firma
        signatureResponse = await post("signature", signaturePayload);
        if (!signatureResponse.answer)
          throw new Error(
            "No se pudo generar la firma para la transacción. Por favor, inténtalo de nuevo."
          );
      } catch (error) {
        handleUnknownError("Error al solicitar la firma", error);
        return;
      }

      const transactionReference = signatureResponse.data.transaction_reference;
      connectWebSocket(transactionReference);

      if (paymentMethod === "CARD") {
        // Proceso de pago con tarjeta
        const tokenPayload = {
          number: paymentData.cardNumber.replace(/\s+/g, ""),
          cvc: paymentData.cvv,
          exp_month: paymentData.expiryDate.split("/")[0].trim(),
          exp_year: paymentData.expiryDate.split("/")[1].trim(),
          card_holder: paymentData.name,
        };

        let tokenResponse;
        try {
          tokenResponse = await postWompi("v1/tokens/cards", tokenPayload);
          if (tokenResponse.status !== "CREATED" || !tokenResponse.data?.id)
            throw new Error(
              "Hubo un problema con los datos de tu tarjeta. Verifica que la información esté correcta."
            );
        } catch (error) {
          handleUnknownError("Error al tokenizar la tarjeta", error);
          return;
        }

        const transactionPayload = {
          amount_in_cents: amountInCents,
          currency: "COP",
          customer_email: userAttributes.email,
          signature: signatureResponse.data.signature,
          payment_method: {
            type: "CARD",
            installments: paymentData.installments || 1,
            token: tokenResponse.data.id,
          },
          reference: transactionReference,
          acceptance_token: acceptanceToken,
          customer_data: {
            phone_number: paymentData.phoneNumber,
            full_name: paymentData.name,
          },
          redirect_url: `${window.location.origin}/invoice/${userAttributes["custom:establishment_id"]}/${transactionReference}`,
        };

        let transactionResponse;
        try {
          transactionResponse = await postWompi(
            "v1/transactions",
            transactionPayload
          );
          if (!transactionResponse.data)
            throw new Error(
              "No se pudo procesar tu pago con tarjeta. Por favor, inténtalo de nuevo más tarde."
            );
        } catch (error) {
          await fetchNewAcceptanceToken();
          handleUnknownError("Error al crear la transacción", error);
          return;
        }

        const paymentDataToSend: PaymentDataToSend = {
          email: userAttributes.email,
          phone_number: userAttributes.phone_number,
          family_name: userAttributes.family_name,
          establishment_id: userAttributes["custom:establishment_id"],
          plan: plan.code_plan,
          reference: transactionResponse.data.reference,
          payment_reference: transactionResponse.data.id,
          payment_method_type: transactionResponse.data.payment_method.type,
          amount_in_cents: transactionResponse.data.amount_in_cents,
          status: transactionResponse.data.status,
          transaction_date: transactionResponse.data.created_at,
          finalized_at: transactionResponse.data.finalized_at,
          currency: transactionResponse.data.currency,
          full_name: paymentData.name,
          establishment: userAttributes["custom:establishment"],
          plan_title: plan.title,
          plan_messages: plan.messages,
        };

        // Incluir el token en paymentDataToSend si autoRenew es true
        if (paymentData.autoRenew) {
          paymentDataToSend.card_token = tokenResponse.data.id;
          paymentDataToSend.name_card = tokenResponse.data.name;
          paymentDataToSend.autoRenew = paymentData.autoRenew;
          paymentDataToSend.token_expires_at = tokenResponse.data.expires_at;
        }

        try {
          await post("payment", paymentDataToSend);
        } catch (error) {
          handleUnknownError(
            "Hubo un problema al guardar los detalles del pago. Por favor, contáctanos para verificar el estado.",
            error
          );
          return;
        }
      } else if (paymentMethod === "PSE") {
        // Proceso de pago con PSE
        const transactionPayload = {
          amount_in_cents: amountInCents,
          currency: "COP",
          customer_email: paymentData.email ? paymentData.email : userEmail,
          signature: signatureResponse.data.signature,
          payment_method: {
            type: "PSE",
            user_type: paymentData.personType,
            user_legal_id_type: paymentData.documentType,
            user_legal_id: paymentData.documentNumber,
            financial_institution_code: paymentData.bank,
            payment_description: "Pago con PSE",
          },
          reference: transactionReference,
          acceptance_token: acceptanceToken,
          customer_data: {
            phone_number: paymentData.phoneNumber,
            full_name: paymentData.fullName,
          },
          redirect_url: `${window.location.origin}/invoice/${userAttributes["custom:establishment_id"]}/${transactionReference}`,
        };

        let transactionResponse;
        try {
          transactionResponse = await postWompi(
            "v1/transactions",
            transactionPayload
          );
          if (!transactionResponse.data)
            throw new Error(
              "No se pudo procesar tu pago con PSE. Por favor, inténtalo de nuevo más tarde."
            );
        } catch (error) {
          await fetchNewAcceptanceToken();
          handleUnknownError("Error al crear la transacción PSE", error);
          return;
        }

        const paymentDataToSend = {
          email: userAttributes.email,
          phone_number: userAttributes.phone_number,
          family_name: userAttributes.family_name,
          establishment_id: userAttributes["custom:establishment_id"],
          plan: plan.code_plan,
          reference: transactionResponse.data.reference,
          payment_reference: transactionResponse.data.id,
          payment_method_type: transactionResponse.data.payment_method.type,
          amount_in_cents: transactionResponse.data.amount_in_cents,
          status: transactionResponse.data.status,
          transaction_date: transactionResponse.data.created_at,
          finalized_at: transactionResponse.data.finalized_at,
          currency: transactionResponse.data.currency,
          full_name: paymentData.fullName,
          establishment: userAttributes["custom:establishment"],
          plan_title: plan.title,
          plan_messages: plan.messages,
        };

        try {
          await post("payment", paymentDataToSend);
        } catch (error) {
          handleUnknownError(
            "Hubo un problema al guardar los detalles del pago. Por favor, contáctanos para verificar el estado.",
            error
          );
          return;
        }
      }
    } catch (error) {
      handleUnknownError(
        `Ocurrió un error inesperado durante el proceso de pago (${paymentMethod}). Por favor, intenta nuevamente.`,
        error
      );
    }
  };

  // Función auxiliar para manejar errores desconocidos y mostrar mensajes más amigables
  function handleUnknownError(contextMessage: string, error: unknown) {
    // Desconectar el WebSocket
    webSocketService.disconnect();

    let userFriendlyMessage;

    // Mensajes personalizados basados en el contexto
    switch (contextMessage) {
      case "Error al solicitar la firma":
        userFriendlyMessage =
          "No se pudo generar la firma para la transacción. Por favor, inténtalo de nuevo.";
        break;
      case "Error al tokenizar la tarjeta":
        userFriendlyMessage =
          "Hubo un problema con los datos de tu tarjeta. Verifica que la información esté correcta.";
        break;
      case "Error al crear la transacción":
      case "Error al crear la transacción PSE":
        userFriendlyMessage =
          "No se pudo procesar tu pago. Por favor, inténtalo de nuevo más tarde.";
        break;
      case "Hubo un problema al guardar los detalles del pago":
        userFriendlyMessage =
          "Hubo un problema al guardar los detalles del pago. Por favor, contáctanos para verificar el estado.";
        break;
      default:
        userFriendlyMessage =
          "Ocurrió un error inesperado. Por favor, intenta nuevamente.";
    }

    // Registrar el error en la consola para el seguimiento técnico
    if (error instanceof Error) {
      console.error(`${contextMessage}: ${error.message}`);
    } else {
      console.error(`${contextMessage}: Error desconocido`);
    }

    // Mostrar una notificación de error más amigable al usuario
    notification.error({
      message: "Error en el proceso de pago",
      description: userFriendlyMessage,
    });

    // Finalizar el proceso de pago
    setIsProcessing(false);
  }

  // Función para iniciar el conteo regresivo
  const startCountdown = (data: any) => {
    let countdownValue = 10;
    setCountdown(countdownValue);

    const interval = setInterval(() => {
      countdownValue -= 1;
      setCountdown(countdownValue);

      if (countdownValue <= 0) {
        clearInterval(interval);
        window.location.href = data.invoice_url;
      }
    }, 1000);
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: transactionStatus === "APPROVED" ? success : error,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const discountAmount = Number((price * (discount / 100)).toFixed(0));
  const finalPrice = (price - discountAmount).toLocaleString("es-CO");

  const handleSignIn = async () => {
    try {
      setIsLoggingIn(true);
      // SignIn now accepts an object with username (email) and password
      const user = await signIn({
        username: userEmail!,
        password: userPassword!,
      });
      if (user) {
        // Si la autenticación es exitosa, obtenemos los atributos del usuario
        const attributes = await fetchUserAttributes();
        setUserAttributes(attributes);
        setIsAuthenticated(true);

        // Asignamos el email del usuario, y si no existe, asignamos null
        const email = attributes["email"]?.toString() ?? null;
        setUserEmail(email);

        notification.success({
          message: "Inicio de sesión exitoso",
        });
      }
    } catch (error: any) {
      console.error("Error al iniciar sesión:", error);
      notification.error({
        message: "Error al iniciar sesión",
        description: error.message,
      });
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleNextStep = async () => {
    if (!isAuthenticated) {
      await handleSignIn();
    }
    if (isAuthenticated) {
      notification.success({
        message: "Autenticado con éxito. Continúe con el proceso de pago.",
      });
    }
  };

  return (
    <div className="checkout-page">
      <header className="header card">
        <div className="logo-container">
          <img
            src="https://docs.amplify.aws/assets/logo-dark.svg"
            alt="Logo"
            className="logo"
          />
          <span className="checkout-text">Checkout</span>
        </div>
        <Button
          type="primary"
          className="login-button"
          onClick={handleGoToDashboard}
          disabled={isProcessing} // Deshabilitar si está procesando
        >
          Ir al Dashboard
        </Button>
      </header>

      <div className="checkout-container">
        <div className="order-summary">
          <h2>Resumen del pedido</h2>
          <div className="plan-summary-box">
            <div className="plan-summary-header">
              <span className="plan-title">{title}</span>
              <span className="price-discount-checkout">
                {price.toLocaleString("es-CO")} COP
              </span>
            </div>
            <div className="plan-summary-details">
              <p>Cantidad de mensajes: {messages}</p>
            </div>
          </div>

          <div className="pricing-info">
            <div className="total-info">
              <p>
                Precio base: <span>{price.toLocaleString("es-CO")} COP</span>
              </p>
              {isCouponApplied && (
                <>
                  <div className="divider"></div>
                  <p>
                    Descuento ({appliedCoupon}):{" "}
                    <span>-{discountAmount.toLocaleString("es-CO")} COP</span>
                  </p>
                </>
              )}
              <div className="divider"></div>
              {!showCouponInput && (
                <a
                  href="#"
                  className="coupon-link"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCouponInput(true);
                  }}
                >
                  Tengo un cupón
                </a>
              )}
              {showCouponInput && (
                <div className="coupon-input">
                  <Input
                    placeholder="Ingresa tu cupón"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <Button type="primary" onClick={applyCoupon}>
                    Aplicar cupón
                  </Button>
                </div>
              )}
            </div>
            <p className="total-payment">
              Total:{" "}
              <span>
                {isCouponApplied ? finalPrice : price.toLocaleString("es-CO")}{" "}
                COP
              </span>
            </p>
          </div>
        </div>

        <div className="email-section">
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Form
              form={form}
              layout="vertical"
              onFinish={handleGoToDashboard}
              initialValues={{ paymentMethod: "CARD" }}
            >
              {isAuthenticated ? (
                <>
                  <div className="user-registered-container">
                    <p className="user-registered-text">Registrado como</p>
                    <p className="user-email">
                      {loadingEmail ? (
                        <Skeleton.Input active={true} size="small" />
                      ) : (
                        userEmail
                      )}
                    </p>
                    <div className="divider"></div>
                  </div>

                  {!(isProcessing || transactionStatus) && (
                    <>
                      <Form.Item
                        label="Método de pago"
                        name="paymentMethod"
                        rules={[
                          {
                            required: true,
                            message: "Por favor seleccione un método de pago",
                          },
                        ]}
                      >
                        <Select
                          value={selectedPaymentMethod}
                          onChange={(value) => setSelectedPaymentMethod(value)}
                          size="large"
                          placeholder="Seleccione un método de pago"
                          className="payment-select"
                        >
                          {paymentMethods
                            .filter((method) => method.enabled)
                            .map((method) => (
                              <Option key={method.value} value={method.value}>
                                {method.icon} {method.label}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <div className="spacing"></div>
                    </>
                  )}

                  {transactionStatus ? (
                    <>
                      <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                      />
                      <p className="transaction-message">
                        {transactionStatus === "APPROVED"
                          ? "Transacción exitosa!"
                          : "Transacción fallida!"}
                      </p>
                      <p className="transaction-countdown">
                        Redirigiendo a la factura en {countdown}...
                      </p>
                    </>
                  ) : isProcessing ? (
                    <div className="processing-container">
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined style={{ fontSize: 40 }} spin />
                        }
                      />
                      <p className="processing-message">
                        Procesando transacción...
                      </p>
                    </div>
                  ) : selectedPaymentMethod === "PSE" ? (
                    <PseForm
                      banks={banks}
                      pseDetails={pseDetails}
                      setPseDetails={setPseDetails}
                      phoneError={phoneError}
                      handlePaymentData={handlePaymentData}
                      permalink={permalink || ""}
                    />
                  ) : (
                    <PaymentForm
                      onPayment={handlePaymentData}
                      permalink={permalink || ""}
                    />
                  )}

                  <div className="spacing"></div>
                </>
              ) : (
                <>
                  {isLoggingIn ? (
                    // Mostrar Spin mientras se inicia sesión
                    <div className="loading-container">
                      <Spin size="large" tip="Iniciando sesión..." />
                    </div>
                  ) : (
                    <>
                      <h2>Introduzca su email y contraseña</h2>
                      <Form.Item label="Email" name="email">
                        <Input
                          placeholder="Email"
                          value={userEmail || ""}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label="Contraseña" name="password">
                        <Input.Password
                          placeholder="Contraseña"
                          value={userPassword || ""}
                          onChange={(e) => setUserPassword(e.target.value)}
                        />
                      </Form.Item>
                      <Button
                        type="primary"
                        onClick={handleNextStep}
                        className="pay-button"
                      >
                        Iniciar Sesión y Continuar
                      </Button>
                    </>
                  )}
                </>
              )}
            </Form>
          )}
        </div>
      </div>

      <div className="footer-section">
        <div className="footer-icons">
          <div className="icon">
            <i className="fas fa-shield-alt"></i>
            <p>Garantía 100%</p>
          </div>
          <div className="icon">
            <i className="fas fa-lock"></i>
            <p>Pago Seguro</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
