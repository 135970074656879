import React, { useState, useEffect } from "react";
import { Card, Row, Col, message } from "antd";
import { get } from "../../../Api/http/httpClient";
import TableComponent from "../../../components/table/table";
import PaymentModal from "../../../components/modals/PaymentModal";

const PaymentData: React.FC<{ user_data: any }> = ({ user_data }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<any | null>(null);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const headers_table = {
    payment_reference: "Referencia de Pago",
    reference: "Referencia",
    full_name: "Nombre",
    establishment: "Establecimiento",
    amount_in_cents: "Monto (COP)",
    status: "Estado",
    payment_method_type: "Método de Pago",
    finalized_at: "Fecha de Finalización",
  };

  const translateStatus = (status: string) => {
    switch (status) {
      case "APPROVED":
        return "Aprobado";
      case "DECLINED":
        return "Rechazado";
      case "PENDING":
        return "Pendiente";
      case "VOIDED":
        return "Anulado";
      case "ERROR":
        return "Error";
      default:
        return status;
    }
  };

  const translatePaymentMethod = (method: string) => {
    switch (method) {
      case "CARD":
        return "Tarjeta";
      case "PSE":
        return "PSE";
      default:
        return method;
    }
  };

  const translateEstablishment = (establishment: string) => {
    if (establishment.toLowerCase() === "restaurant") {
      return "Restaurante";
    } else if (establishment.toLowerCase() === "bar") {
      return "Bar";
    }
    return establishment;
  };

  const formatAmountInPesos = (amount_in_cents: number) => {
    return (amount_in_cents / 100).toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("es-CO", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedDate.replace(" a. m.", " a.m.").replace(" p. m.", " p.m.");
  };

  const getPaymentData = async () => {
    setLoading(true);
    try {
      const res = await get(
        `payment?establishmentID=${user_data["custom:establishment_id"]}&page=${page}&page_size=${itemsPerPage}&search=${search}&sort_by=${sortColumn}&order_by=${sortOrder}`
      );

      if (res.answer && res.items) {
        const translatedData = res.items.map((payment: any) => ({
          ...payment,
          amount_in_cents: formatAmountInPesos(payment.amount_in_cents),
          status: translateStatus(payment.status),
          payment_method_type: translatePaymentMethod(payment.payment_method_type),
          establishment: translateEstablishment(payment.establishment),
          finalized_at: formatDate(payment.finalized_at),
          transaction_date: formatDate(payment.transaction_date),
        }));
        setData(translatedData);
        setTotalPages(Math.ceil(res.total_items / itemsPerPage));
      } else {
        message.error("No se encontraron pagos.");
      }
    } catch (error) {
      message.error("Error al obtener los datos de pagos.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentData();
  }, [page, itemsPerPage, search, sortOrder, sortColumn]);

  const handleViewPayment = (payment: any) => {
    setSelectedPayment(payment);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPayment(null);
  };

  const setActionHandler = (action: { type: string; data?: any }) => {
    if (action.type === "view") {
      handleViewPayment(action.data);
    } else if (action.type === "reload") {
      getPaymentData();
    }
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={23} style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Card bordered={false}>
          <TableComponent
            data={data}
            headers_table={headers_table}
            loading={loading}
            setSearch={setSearch}
            setAction={setActionHandler}
            totalPages={totalPages}
            setPage={setPage}
            page={page}
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
            setSortOrder={setSortOrder} // Configuración de orden
            setSortColumn={setSortColumn} // Configuración de columna
            deleting={deleting}
            isViewMode={true}
          />
        </Card>
      </Col>

      <PaymentModal
        open={modalOpen}
        onClose={handleCloseModal}
        paymentDetails={selectedPayment}
      />
    </Row>
  );
};

export default PaymentData;