import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  List,
  Spin,
  message,
  Typography,
  Modal,
} from "antd";
import { FrownOutlined, LoadingOutlined } from "@ant-design/icons";
import { get, post, put, del } from "../../../Api/http/httpClient"; // Importar métodos de httpclient
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import CategoryModal from "../../../components/modals/CategoryModal";

const { Text } = Typography;
const { confirm } = Modal;

const MenuCategories: React.FC<{ user_data: any }> = ({ user_data }) => {
  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [addingCategory, setAddingCategory] = useState(false);
  const [deletingCategory, setDeletingCategory] = useState<string | null>(null);
  const [editingCategory, setEditingCategory] = useState<string | null>(null);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [updatingCategory, setUpdatingCategory] = useState(false);
  const screens = useBreakpoint();

  const fetchCategoriesData = async (
    page = 1,
    pageSize = 5,
    isFirstLoad = false
  ) => {
    if (isFirstLoad) {
      setLoading(true);
    } else {
      setLoadingCategories(true);
    }
    setError(false);

    try {
      const response = await get(
        `menu-category?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}&page_size=${pageSize}&page=${page}&sort_by=category&search=`
      );

      if (response.answer) {
        setCategories(response.items || []);
        setTotalItems(response.total_items);
      } else {
        setError(true);
        message.error("No se encontraron categorías.");
      }
    } catch (error) {
      setError(true);
      message.error("Error al obtener las categorías.");
    } finally {
      if (isFirstLoad) {
        setLoading(false);
      } else {
        setLoadingCategories(false);
      }
    }
  };

  useEffect(() => {
    fetchCategoriesData(page, pageSize, true);
  }, []);

  const confirmAddCategory = (category: string) => {
    if (!category) {
      message.warning("Debe ingresar una categoría válida antes de agregarla.");
      return;
    }

    if (categories.includes(category)) {
      message.error("Esta categoría ya existe.");
      return;
    }

    confirm({
      title: "¿Estás seguro de que deseas agregar esta categoría?",
      content: `Categoría: ${category}`,
      onOk() {
        addCategory(category);
      },
    });
  };

  const addCategory = async (category: string) => {
    setAddingCategory(true);
    try {
      const response = await post(
        "menu-category",
        {
          establishmentID: user_data["custom:establishment_id"],
          name_establishment: user_data.family_name,
          new_category: category,
        }
      );

      if (response.answer) {
        fetchCategoriesData(page, pageSize, false);
        message.success("Categoría agregada correctamente.");
        form.resetFields(["newCategory"]);
      } else {
        message.error("Error al agregar la categoría.");
      }
    } catch (error) {
      message.error("Error al realizar la petición para agregar la categoría.");
    } finally {
      setAddingCategory(false);
    }
  };

  const confirmDeleteCategory = (category: string) => {
    confirm({
      title: "¿Estás seguro de que deseas eliminar esta categoría?",
      content: `Categoría: ${category}`,
      onOk() {
        handleDeleteCategory(category);
      },
    });
  };

  const handleDeleteCategory = async (category: string) => {
    setDeletingCategory(category);
    try {
      const response = await del("menu-category", {
        establishmentID: user_data["custom:establishment_id"],
        name_establishment: user_data.family_name,
        category_to_delete: category,
      });

      if (response.answer) {
        message.success("Categoría eliminada correctamente.");
        fetchCategoriesData(page, pageSize, false);
      } else {
        message.error("Error al eliminar la categoría.");
      }
    } catch (error) {
      message.error("Error al realizar la petición para eliminar la categoría.");
    } finally {
      setDeletingCategory(null);
    }
  };

  const handlePageChange = (newPage: number, newPageSize?: number) => {
    setPage(newPage);
    if (newPageSize) {
      setPageSize(newPageSize);
    }
    fetchCategoriesData(newPage, newPageSize || pageSize, false);
  };

  const showEditCategoryModal = (category: string) => {
    setEditingCategory(category);
    setNewCategoryName(category);
  };

  const handleUpdateCategory = async () => {
    setUpdatingCategory(true);
    try {
      const response = await put(
        "menu-category",
        {
          establishmentID: user_data["custom:establishment_id"],
          name_establishment: user_data.family_name,
          old_category: editingCategory,
          new_category: newCategoryName,
        }
      );

      if (response.answer) {
        const updatedCategories = categories.map((category) =>
          category === editingCategory ? newCategoryName : category
        );
        setCategories(updatedCategories);
        message.success("Categoría actualizada correctamente.");
        setEditingCategory(null);
      } else {
        message.error("Error al actualizar la categoría.");
      }
    } catch (error) {
      message.error(
        "Error al realizar la petición para actualizar la categoría."
      );
    } finally {
      setUpdatingCategory(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingCategory(null);
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={screens.xs ? 24 : 22}>
        <Card
          title="Categorías del Menú"
          bordered={false}
          style={{
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: screens.xs ? "10px" : "10px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <Spin size="large" />
              <Text style={{ marginTop: "20px" }}>
                Cargando elementos del menú...
              </Text>
            </div>
          ) : error ? (
            <div style={{ textAlign: "center", padding: "40px" }}>
              <FrownOutlined style={{ fontSize: "64px", color: "#ff4d4f" }} />
              <Text
                type="danger"
                style={{
                  display: "block",
                  fontSize: "18px",
                  marginTop: "20px",
                }}
              >
                Ocurrió un error al cargar las categorías.
              </Text>
              <Button
                type="primary"
                size="large"
                style={{ marginTop: "20px", borderRadius: "8px" }}
                onClick={() => fetchCategoriesData(page, pageSize, true)}
              >
                Reintentar
              </Button>
            </div>
          ) : (
            <>
              <Form form={form} layout="vertical">
                <Form.Item label="Agregar categoría de menú" name="newCategory">
                  <Input.Search
                    size="large"
                    placeholder="Ej: Entradas, Platos principales, Postres"
                    enterButton={addingCategory ? <Spin /> : "Agregar"}
                    onSearch={confirmAddCategory}
                    loading={addingCategory}
                  />
                </Form.Item>
              </Form>

              {loadingCategories ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0",
                  }}
                >
                  <Spin size="large" />
                </div>
              ) : (
                <Form.Item>
                  <List
                    bordered
                    dataSource={categories}
                    pagination={{
                      current: page,
                      pageSize: pageSize,
                      total: totalItems,
                      showSizeChanger: true,
                      onChange: handlePageChange,
                    }}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Button
                            onClick={() => showEditCategoryModal(item)}
                            disabled={!!deletingCategory}
                          >
                            Editar
                          </Button>,
                          <Button
                            danger
                            onClick={() => confirmDeleteCategory(item)}
                            disabled={!!deletingCategory}
                          >
                            {deletingCategory === item ? (
                              <Spin indicator={<LoadingOutlined />} />
                            ) : (
                              "Eliminar"
                            )}
                          </Button>,
                        ]}
                      >
                        {item}
                      </List.Item>
                    )}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Card>
      </Col>

      <CategoryModal
        visible={!!editingCategory}
        newCategoryName={newCategoryName}
        setNewCategoryName={setNewCategoryName}
        onOk={handleUpdateCategory}
        onCancel={handleCancelEdit}
        updatingCategory={updatingCategory}
      />
    </Row>
  );
};

export default MenuCategories;