import React, { useState, useEffect, useRef } from "react";
import { Steps, Button, Grid, Result, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import BasicInfo from "../../components/forms/BasicInfo";
import AccountCreation from "../../components/forms/AccountCreation";
import EstablishmentDetails from "../../components/forms/EstablishmentDetails";
import BusinessHours from "../../components/forms/BusinessHours";
import Categories from "../../components/forms/Categories";
import ReservationSystem from "../../components/forms/ReservationSystem";
import LocationAccess from "../../components/forms/LocationAccess";
import AdditionalPreferences from "../../components/forms/AdditionalPreferences";
import EstablishmentPhotos from "../../components/forms/EstablishmentPhotos";
import FAQ from "../../components/forms/FAQ";
import AgentIdentity from "../../components/forms/AgentIdentity";
import CancellationPolicy from "../../components/forms/CancellationPolicy";
import FinalReview from "../../components/forms/FinalReview";
import { getCurrentUser } from "@aws-amplify/auth";
import "./Onboarding.css"; // Import the CSS file
import {
  ContainerBase,
  ContainerCenter,
  ContainerFeatures,
  ContainerHeader,
} from "../../components/landingpage/styledComponents/Containers";
import {
  Description,
  SubTitle,
} from "../../components/landingpage/styledComponents/Typography";
import {
  ButtonGroup,
  RadioButton,
  RadioButtonContainer,
} from "../../components/landingpage/styledComponents/Buttons";
import useScrollAnimation from "../../hooks/useAnimation";
import { InfoCircleOutlined, SmileOutlined } from "@ant-design/icons";
import { mergeRefs } from "react-merge-refs";
import LogoBlox from "../../assets/img/landingPage/logoHorizontal.png";

const { Title } = Typography;

// Define FormData type to include email and dynamic fields
interface FormData {
  email?: string;
  [key: string]: any;
}

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [showResult, setShowResult] = useState(true);
  const [formData, setFormData] = useState<FormData>({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [businessModel, setBusinessModel] = useState<string>("restaurant"); // Default value
  const stepsContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const screens = Grid.useBreakpoint();
  const titleFade = useScrollAnimation({ effect: "bottom", delay: 0 });
  const textFade1 = useScrollAnimation({ effect: "bottom", delay: 0.1 });
  const textFade2 = useScrollAnimation({ effect: "bottom", delay: 0.2 });
  const modelsBusinessFade = useScrollAnimation({
    effect: "bottom",
    delay: 0.3,
  });
  const stepsFade = useScrollAnimation({ effect: "left", delay: 0.2 });
  const formFade = useScrollAnimation({ effect: "right", delay: 0.2 });

  // Titles for each business model
  const businessModelTitles: Record<string, string> = {
    restaurant: "Restaurantes, Bares y Discotecas",
    medical: "Consultorios Médicos y Jurídicos",
    "e-commerce": "E-commerce",
  };

  useEffect(() => {
    const savedModel = localStorage.getItem("businessModel");
    if (savedModel) {
      setBusinessModel(savedModel);
    } else {
      // Save default model if not present in localStorage
      localStorage.setItem("businessModel", "restaurant");
      setBusinessModel("restaurant");
    }

    const savedData = localStorage.getItem("onboardingData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }

    const savedStep = localStorage.getItem("onboardingStep");
    if (savedStep) {
      setStep(parseInt(savedStep, 10));
    }
  }, []);

  const handleBusinessModelSelection = (model: string) => {
    clearOnboardingData();
    setBusinessModel(model);
    setFormData({});
    setStep(1);
    localStorage.setItem("businessModel", model);
    const onboardingStepsSection = document.getElementById("onboarding-steps");
    if (onboardingStepsSection) {
      onboardingStepsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const clearOnboardingData = () => {
    localStorage.removeItem("businessModel");
    localStorage.removeItem("onboardingData");
    localStorage.removeItem("onboardingStep");
  };

  const updateFormData = (newData: any) => {
    const updatedData = { ...formData, ...newData };
    setFormData(updatedData);
    localStorage.setItem("onboardingData", JSON.stringify(updatedData));
  };

  const saveStep = (step: number) => {
    localStorage.setItem("onboardingStep", step.toString());
  };

  const handleScrollToActiveStep = () => {
    if (stepsContainerRef.current && screens.xs) {
      const stepsContainer = stepsContainerRef.current;
      const activeStepElement = stepsContainer.querySelector(
        `.ant-steps-item-active`
      ) as HTMLDivElement;

      if (activeStepElement) {
        const containerWidth = stepsContainer.offsetWidth;
        const stepWidth = activeStepElement.offsetWidth;
        const stepOffsetLeft = activeStepElement.offsetLeft;

        const scrollLeft = stepOffsetLeft - containerWidth / 2 + stepWidth / 2;

        stepsContainer.scrollTo({ left: scrollLeft, behavior: "smooth" });
      }
    }
  };

  const nextStep = () => {
    setStep((prev) => {
      const newStep = prev + 1;
      saveStep(newStep);
      setTimeout(handleScrollToActiveStep, 100);
      return newStep;
    });
  };

  const prevStep = () => {
    setStep((prev) => {
      const newStep = prev - 1;
      saveStep(newStep);
      setTimeout(handleScrollToActiveStep, 100);
      return newStep;
    });
  };

  const stepsByBusinessModel: Record<string, { title: string }[]> = {
    restaurant: [
      { title: "Información básica" },
      { title: "Creación de cuenta" },
      { title: "Detalles del establecimiento" },
      { title: "Horario" },
      { title: "Categorías" },
      { title: "Sistema de reservas" },
      { title: "Acceso a la ubicación" },
      { title: "Preferencias adicionales" },
      { title: "Fotos del establecimiento" },
      { title: "FAQ" },
      { title: "Identidad y estilo del agente" },
      { title: "Revisión final" },
    ],
    medical: [
      { title: "Información básica" },
      { title: "Creación de cuenta" },
      { title: "Detalles del establecimiento" },
      { title: "Horario" },
      { title: "Categorías" },
      { title: "Sistema de reservas" },
      { title: "Acceso a la ubicación" },
      { title: "Preferencias adicionales" },
      { title: "Fotos del establecimiento" },
      { title: "FAQ" },
      { title: "Identidad y estilo del agente" },
      { title: "Revisión final" },
    ],
    "e-commerce": [
      { title: "Información básica" },
      { title: "Creación de cuenta" },
      { title: "Detalles del negocio" },
      { title: "Categorías" },
      { title: "Preferencias adicionales" },
      { title: "Fotos del establecimiento" },
      { title: "FAQ" },
      { title: "Identidad y estilo del agente" },
      { title: "Revisión final" },
    ],
  };

  const steps = stepsByBusinessModel[businessModel] || [];
  const totalSteps = steps.length;

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false));
  }, []);

  useEffect(() => {
    handleScrollToActiveStep();
  }, [step, screens.xs]);

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  const renderStep = () => {
    const commonProps = {
      updateFormData,
      nextStep,
      prevStep,
      initialValues: {
        ...formData,
        username: formData.email ?? "",
      },
      businessModel: businessModel,
    };
    switch (businessModel) {
      case "restaurant":
        switch (step) {
          case 1:
            return <BasicInfo {...commonProps} />;
          case 2:
            return <AccountCreation {...commonProps} />;
          case 3:
            return <EstablishmentDetails {...commonProps} />;
          case 4:
            return <BusinessHours {...commonProps} />;
          case 5:
            return <Categories {...commonProps} />;
          case 6:
            return <ReservationSystem {...commonProps} />;
          case 7:
            return <LocationAccess {...commonProps} />;
          case 8:
            return <AdditionalPreferences {...commonProps} />;
          case 9:
            return <EstablishmentPhotos {...commonProps} />;
          case 10:
            return <FAQ {...commonProps} />;
          case 11:
            return <AgentIdentity {...commonProps} />;
          case 12:
            return (
              <FinalReview
                formData={formData}
                setStep={setStep}
                prevStep={prevStep}
                businessModel={businessModel}
              />
            );
        }
        break;

      case "medical":
        switch (step) {
          case 1:
            return <BasicInfo {...commonProps} />;
          case 2:
            return <AccountCreation {...commonProps} />;
          case 3:
            return <EstablishmentDetails {...commonProps} />;
          case 4:
            return <BusinessHours {...commonProps} />;
          case 5:
            return <Categories {...commonProps} />;
          case 6:
            return <ReservationSystem {...commonProps} />;
          case 7:
            return <LocationAccess {...commonProps} />;
          case 8:
            return <AdditionalPreferences {...commonProps} />;
          case 9:
            return <EstablishmentPhotos {...commonProps} />;
          case 10:
            return <FAQ {...commonProps} />;
          case 11:
            return <AgentIdentity {...commonProps} />;
          case 12:
            return (
              <FinalReview
                formData={formData}
                setStep={setStep}
                prevStep={prevStep}
                businessModel={businessModel}
              />
            );
        }
        break;

      case "e-commerce":
        switch (step) {
          case 1:
            return <BasicInfo {...commonProps} />;
          case 2:
            return <AccountCreation {...commonProps} />;
          case 3:
            return <EstablishmentDetails {...commonProps} />;
          case 4:
            return <Categories {...commonProps} />;
          case 5:
            return <AdditionalPreferences {...commonProps} />;
          case 6:
            return <EstablishmentPhotos {...commonProps} />;
          case 7:
            return <FAQ {...commonProps} />;
          case 8:
            return <AgentIdentity {...commonProps} />;
          case 9:
            return (
              <FinalReview
                formData={formData}
                setStep={setStep}
                prevStep={prevStep}
                businessModel={businessModel}
              />
            );
        }
        break;

      default:
        return <div>Selecciona un modelo de negocio para continuar</div>;
    }
  };

  return (
    <div className="onboarding-container">
      <ContainerHeader
        $background="#FAFAFA"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <a
          href="/"
          style={{
            width: 117,
            height: "auto",
          }}
        >
          <img
            src={LogoBlox}
            alt="Logo Horizontal Blox"
            style={{ width: "100%", height: "100%" }}
          />
        </a>
        <Button type="text" size="large" onClick={handleGoToDashboard}>
          Ingresar
        </Button>
      </ContainerHeader>
      <ContainerFeatures>
        <ContainerBase>
          <ContainerCenter>
            <SubTitle ref={titleFade} $isBlack={true}>
              ¡Entrena a tu asistente virtual Blox!
            </SubTitle>
            <Description ref={textFade1}>
              Completar este proceso es fundamental para personalizar Blox según
              las necesidades de tu negocio. Un asistente bien configurado puede
              mejorar la experiencia de tus clientes, automatizar procesos, y
              ahorrar tiempo valioso.
            </Description>
            <Description ref={textFade2}>
              Primero, selecciona el modelo de negocio que mejor describe tu
              establecimiento. Esto nos ayudará a adaptar el proceso a tus
              necesidades específicas.
              <Tooltip title="Al cambiar de modelo de negocio deberás comenzar desde cero la configuración del blox, ya que los flujos tienen ligeras variaciones.">
                <InfoCircleOutlined
                  style={{ marginLeft: 8, color: "#0E6EAF" }}
                />
              </Tooltip>
            </Description>
            <ButtonGroup ref={modelsBusinessFade}>
              <RadioButtonContainer>
                <input
                  type="radio"
                  name="business-model"
                  id={"restaurant"}
                  onChange={() => handleBusinessModelSelection("restaurant")}
                  checked={businessModel === "restaurant"}
                />
                <RadioButton>
                  <label htmlFor="restaurant">
                    <span>Restaurantes, Bares y Discotecas</span>
                  </label>
                </RadioButton>
              </RadioButtonContainer>
              <RadioButtonContainer>
                <input
                  type="radio"
                  name="business-model"
                  id={"medical"}
                  onChange={() => handleBusinessModelSelection("medical")}
                  checked={businessModel === "medical"}
                />
                <RadioButton>
                  <label htmlFor="medical">
                    <span>Consultorios Médicos y Jurídicos</span>
                  </label>
                </RadioButton>
              </RadioButtonContainer>
              <RadioButtonContainer>
                <input
                  type="radio"
                  name="business-model"
                  id={"e-commerce"}
                  onChange={() => handleBusinessModelSelection("e-commerce")}
                  checked={businessModel === "e-commerce"}
                />
                <RadioButton>
                  <label htmlFor="e-commerce">
                    <span>E-commerce</span>
                  </label>
                </RadioButton>
              </RadioButtonContainer>
            </ButtonGroup>
          </ContainerCenter>
        </ContainerBase>
      </ContainerFeatures>
      <ContainerFeatures $background="#FFFFFF" id="onboarding-steps">
        <ContainerBase $height="100%">
          {step === totalSteps - 2 && showResult && (
            <ContainerCenter
              style={{ position: "absolute", zIndex: 2, left: 0 }}
              $height="100%"
              $background="#FFFFFF"
            >
              <Result
                icon={<SmileOutlined />}
                title="¡Excelente trabajo! Ya casi terminamos."
                subTitle="Sigue así, cada paso te acerca más a personalizar tu asistente virtual Blox y mejorar la experiencia de tus clientes."
                extra={
                  <Button type="primary" onClick={() => setShowResult(false)}>
                    Continuar
                  </Button>
                }
              />
            </ContainerCenter>
          )}
          <ContainerCenter
            $isInline={!screens.xs}
            $height="100%"
            $gap={screens.xs ? "20px" : "100px"}
            $align="stretch"
          >
            <ContainerCenter
              $background={screens.xs ? "transparent" : "#FAFAFA"}
              $isInline={false}
              $justify="center"
              $padding={screens.xs ? "20px 0px" : "40px 0px"}
              $radius="24px"
              ref={mergeRefs([stepsContainerRef, stepsFade])}
            >
              <Title
                level={3}
                style={{ marginBottom: "20px", textAlign: "center" }}
              >
                {businessModelTitles[businessModel] || "Modelo de negocio"}
              </Title>
              <Steps
                current={step - 1}
                direction={screens.xs ? "horizontal" : "vertical"}
                responsive={false}
                items={steps.map((item) => ({ title: item.title }))}
                size="small"
                labelPlacement={screens.xs ? "vertical" : "horizontal"}
                style={{
                  width: screens.xs ? "100%" : "50%",
                  margin: "0 auto",
                }}
              />
            </ContainerCenter>

            <ContainerCenter ref={formFade} $padding="20px 0">
              {renderStep()}
            </ContainerCenter>
          </ContainerCenter>
        </ContainerBase>
      </ContainerFeatures>
    </div>
  );
};

export default Onboarding;
