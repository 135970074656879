import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  List,
  Empty,
  Alert,
  Upload,
  message,
} from "antd";
import { PlusOutlined, DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import AddProductModal from "./../modals/AddProductModal";
import AddServiceModal from "./../modals/AddServiceModal";

interface CategoriesProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
  businessModel: string;
}

interface Product {
  name: string;
  price: string;
  shippingOptions: string[];
  description: string;
  productLink: string;
}

interface Service {
  name: string;
  description: string;
  price: string;
}

export default function Categories({
  updateFormData,
  nextStep,
  prevStep,
  initialValues,
  businessModel,
}: CategoriesProps) {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<string[]>(
    initialValues.categories || []
  );
  const [products, setProducts] = useState<Record<string, Product[]>>({});
  const [services, setServices] = useState<Record<string, Service[]>>({});
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);
  const [isServiceModalVisible, setIsServiceModalVisible] = useState(false);
  const [uploadedFileBase64, setUploadedFileBase64] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (initialValues.categories) {
      setCategories(initialValues.categories);
    }
    if (initialValues.products) {
      setProducts(initialValues.products);
    }
    if (initialValues.services) {
      setServices(initialValues.services);
    }
  }, [initialValues]);

  const handleSubmit = () => {
    const data: any = { categories };
    if (businessModel === "e-commerce") {
      data.products = products;
      if (uploadedFileBase64) {
        data.uploadedFileBase64 = uploadedFileBase64;
      }
    } else if (businessModel === "medical") {
      data.services = services;
    }
    updateFormData(data);
    nextStep();
  };

  const addCategory = (category: string) => {
    if (category && !categories.includes(category)) {
      setCategories([...categories, category]);
      form.resetFields(["newCategory"]);
    }
  };

  const removeCategory = (category: string) => {
    setCategories(categories.filter((item) => item !== category));
    if (businessModel === "e-commerce") {
      const updatedProducts = { ...products };
      delete updatedProducts[category];
      setProducts(updatedProducts);
    }
    if (businessModel === "medical") {
      const updatedServices = { ...services };
      delete updatedServices[category];
      setServices(updatedServices);
    }
  };

  const handleAddProduct = (values: Product) => {
    if (currentCategory) {
      const updatedProducts = {
        ...products,
        [currentCategory]: [...(products[currentCategory] || []), values],
      };
      setProducts(updatedProducts);
    }
    setIsProductModalVisible(false);
    message.success("Producto agregado correctamente.");
  };

  const handleAddService = (service: Service) => {
    if (currentCategory) {
      const updatedServices = {
        ...services,
        [currentCategory]: [...(services[currentCategory] || []), service],
      };
      setServices(updatedServices);
    }
    setIsServiceModalVisible(false);
    message.success("Servicio agregado correctamente.");
  };

  const handleRemoveProduct = (category: string, index: number) => {
    const updatedProducts = {
      ...products,
      [category]: products[category].filter((_, i) => i !== index),
    };
    setProducts(updatedProducts);
  };

  const handleRemoveService = (category: string, index: number) => {
    const updatedServices = {
      ...services,
      [category]: services[category].filter((_, i) => i !== index),
    };
    setServices(updatedServices);
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result as string;
      setUploadedFileBase64(base64.split(",")[1]);
      message.success(`Archivo "${file.name}" cargado correctamente.`);
    };
    reader.onerror = () => {
      message.error(`Error al cargar el archivo "${file.name}". Intenta nuevamente.`);
    };
  };

  const beforeUpload = (file: File) => {
    const isExcelOrCsv =
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel" ||
      file.type === "text/csv";

    if (!isExcelOrCsv) {
      message.error("Solo se permiten archivos Excel (.xls, .xlsx) o CSV (.csv).");
      return Upload.LIST_IGNORE;
    }

    handleFileUpload(file);
    return false;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
      style={{ width: "100%" }}
    >
      <Form.Item
        label={
          businessModel === "e-commerce"
            ? "Categoría de productos"
            : businessModel === "medical"
            ? "Categoría de servicios"
            : "Categoría de menú"
        }
        name="newCategory"
        tooltip={
          businessModel === "e-commerce"
            ? "Crea categorías como electrónicos, ropa, accesorios para organizar tus productos."
            : businessModel === "medical"
            ? "Crea categorías como consultas generales, odontología o medicina especializada para tus servicios."
            : "Crea categorías como entradas, platos principales, bebidas, o postres para organizar tu menú."
        }
      >
        <Input.Search
          size="large"
          placeholder={
            businessModel === "e-commerce"
              ? "Ej: Electrónicos, Ropa, Accesorios"
              : businessModel === "medical"
              ? "Ej: Consultas generales, Odontología, Terapias"
              : "Ej: Entradas, Platos principales, Postres"
          }
          enterButton={
            <Button icon={<PlusOutlined />} type="primary">
              Agregar
            </Button>
          }
          onSearch={addCategory}
        />
      </Form.Item>

      {categories.length > 0 && (
        <Form.Item label="Categorías actuales">
          <List
            bordered
            dataSource={categories}
            size="small"
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
              size: "small",
            }}
            renderItem={(category) => (
              <List.Item
                actions={[
                  businessModel === "e-commerce" && (
                    <Button
                      type="link"
                      onClick={() => {
                        setCurrentCategory(category);
                        setIsProductModalVisible(true);
                      }}
                    >
                      Agregar producto
                    </Button>
                  ),
                  businessModel === "medical" && (
                    <Button
                      type="link"
                      onClick={() => {
                        setCurrentCategory(category);
                        setIsServiceModalVisible(true);
                      }}
                    >
                      Agregar servicio
                    </Button>
                  ),
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => removeCategory(category)}
                    type="text"
                    danger
                  >
                    Eliminar
                  </Button>,
                ]}
              >
                {category}
              </List.Item>
            )}
          />
        </Form.Item>
      )}

      {businessModel === "e-commerce" && (
        <>
          <Form.Item label="Archivo de productos">
            <Upload
              name="productsFile"
              beforeUpload={beforeUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Subir archivo</Button>
            </Upload>
          </Form.Item>

          {Object.entries(products).map(([category, productList]) => (
            <Form.Item key={category} label={`Productos en ${category}`}>
              <List
                bordered
                dataSource={productList}
                pagination={{
                  pageSize: 5,
                  showSizeChanger: false,
                  size: "small",
                }}
                renderItem={(product, index) => (
                  <List.Item
                    actions={[
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveProduct(category, index)}
                        type="text"
                        danger
                      >
                        Eliminar
                      </Button>,
                    ]}
                  >
                    <div>
                      <strong>{product.name}</strong>: {product.description} -{" "}
                      <strong>${product.price}</strong>
                    </div>
                  </List.Item>
                )}
              />
            </Form.Item>
          ))}
        </>
      )}

      {businessModel === "medical" &&
        Object.entries(services).map(([category, serviceList]) => (
          <Form.Item key={category} label={`Servicios en ${category}`}>
            <List
              bordered
              dataSource={serviceList}
              pagination={{
                pageSize: 5,
                showSizeChanger: false,
                size: "small",
              }}
              renderItem={(service, index) => (
                <List.Item
                  actions={[
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveService(category, index)}
                      type="text"
                      danger
                    >
                      Eliminar
                    </Button>,
                  ]}
                >
                  <div>
                    <strong>{service.name}</strong>: {service.description} -{" "}
                    <strong>${service.price}</strong>
                  </div>
                </List.Item>
              )}
            />
          </Form.Item>
        ))}

      <Form.Item>
        <Button size="large" onClick={prevStep} style={{ marginRight: 8 }}>
          Anterior
        </Button>
        <Button size="large" type="primary" htmlType="submit">
          Siguiente
        </Button>
      </Form.Item>

      <AddProductModal
        visible={isProductModalVisible}
        onCancel={() => setIsProductModalVisible(false)}
        onAdd={handleAddProduct}
      />

      <AddServiceModal
        visible={isServiceModalVisible}
        onCancel={() => setIsServiceModalVisible(false)}
        onAdd={handleAddService}
      />
    </Form>
  );
}