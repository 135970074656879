import React from "react";
import { Button } from "antd";
import { CrownOutlined } from "@ant-design/icons"; // Importa el ícono de corona
import Chat from "../../../components/chat/Chat";
import "./ChatPage.css";

interface ChatPageProps {
  user_data: any;
}

const ChatPage: React.FC<ChatPageProps> = ({ user_data }) => {

  // Verificar si el plan es 0
  const isPlanFree = user_data["custom:plan"] === "0";

  return (
    <div className="chat-page-container">
      {/* Renderizar botón solo si el plan es 0 */}
      {isPlanFree && (
        <Button
          type="primary"
          icon={<CrownOutlined />} // Icono de corona para premium
          className="update-plan-button"
          onClick={() => {
            // Redirigir a la página de planes
            window.location.href = '/plans';
          }}
        >
          Actualizar plan
        </Button>
      )}

      {/* Ajustar la clase de chat-container según si el botón está presente o no */}
      <div className={`chat-container ${isPlanFree ? 'with-button' : 'without-button'}`}>
        <Chat user_data={user_data} />
      </div>
    </div>
  );
};

export default ChatPage;