import { Form, Input, Button, List, Tooltip, Pagination } from 'antd';
import { useState } from 'react';

interface AgentIdentityProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
}

export default function AgentIdentity({ updateFormData, nextStep, prevStep, initialValues }: AgentIdentityProps) {
  const [form] = Form.useForm();
  const [phrases, setPhrases] = useState<string[]>(initialValues?.commonPhrases || []);
  const [phraseInput, setPhraseInput] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);

  const ITEMS_PER_PAGE = 5;
  const CHARACTER_LIMIT = 25;

  const handleAddPhrase = () => {
    if (phraseInput.trim()) {
      setPhrases([...phrases, phraseInput.trim()]);
      setPhraseInput('');
    }
  };

  const handleRemovePhrase = (index: number) => {
    const updatedPhrases = phrases.filter((_, i) => i !== index);
    setPhrases(updatedPhrases);
  };

  const handleSubmit = (values: any) => {
    updateFormData({ ...values, commonPhrases: phrases });
    nextStep();
  };

  const truncateText = (text: string) => {
    return text.length > CHARACTER_LIMIT
      ? `${text.slice(0, CHARACTER_LIMIT)}...`
      : text;
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Calcular frases visibles según la página actual
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const visiblePhrases = phrases.slice(startIndex, endIndex);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      {/* Descripción de Identidad de Marca */}
      <Form.Item
        label="Descripción de Identidad de Marca"
        name="brandIdentity"
        rules={[{ required: true, message: 'Por favor ingrese la descripción de la identidad de marca' }]}
      >
        <Input.TextArea
          rows={4}
          placeholder="Describa la identidad de su marca (e.g., confiable, innovadora, amigable)"
        />
      </Form.Item>

      {/* Estilo de Comunicación */}
      <Form.Item
        label="Estilo de Comunicación con Clientes"
        name="communicationStyle"
        rules={[{ required: true, message: 'Por favor ingrese el estilo de comunicación' }]}
      >
        <Input.TextArea
          rows={4}
          placeholder="Describa cómo debe comunicarse el agente con los clientes (e.g., formal, directo, casual)"
        />
      </Form.Item>

      {/* Frases Comunes */}
      <Form.Item label="Frases Comunes del Comercio">
        <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
          <Input
            placeholder="Agregar una frase común"
            value={phraseInput}
            onChange={(e) => setPhraseInput(e.target.value)}
          />
          <Button type="primary" onClick={handleAddPhrase}>
            Agregar
          </Button>
        </div>
        <List
          bordered
          dataSource={visiblePhrases}
          renderItem={(phrase, index) => (
            <List.Item
              actions={[
                <Button type="link" onClick={() => handleRemovePhrase(index + startIndex)} key="remove">
                  Eliminar
                </Button>,
              ]}
            >
              <Tooltip title={phrase}>
                <span>{truncateText(phrase)}</span>
              </Tooltip>
            </List.Item>
          )}
        />
        {phrases.length > ITEMS_PER_PAGE && (
          <Pagination
            current={currentPage}
            pageSize={ITEMS_PER_PAGE}
            total={phrases.length}
            onChange={handlePageChange}
            style={{ marginTop: '10px', textAlign: 'center' }}
          />
        )}
      </Form.Item>

      <Form.Item>
        <Button size="large" onClick={prevStep} style={{ marginRight: 8 }}>
          Anterior
        </Button>
        <Button size="large" type="primary" htmlType="submit">
          Guardar y Continuar
        </Button>
      </Form.Item>
    </Form>
  );
}