import React, { useEffect } from 'react';
import { ContainerBase, ContainerFeatures } from '../styledComponents/Containers';
import { FeaturesHeader, Features, FeaturesList, FeaturesListItem } from '../styledComponents/Features';
import { TagSection } from '../styledComponents/Global';
import { Description, SubTitle } from '../styledComponents/Typography';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const FeaturesSection = () => {
    
    useEffect(() => {
        gsap.utils.toArray('.feature-item').forEach((item, index) => {
            const element = item as HTMLElement;
            gsap.fromTo(
            element,
            { x: 100 ,opacity: 0 },
            {
                x: 0,
                opacity: 1,
                duration: 0.8,
                delay: index * 0.1,
                scrollTrigger: {
                trigger: element,
                start: 'top 70%',
                toggleActions: 'play none none none',
                }
            }
            );
        });
    }, []);

  return (
    <ContainerFeatures id='features'>
        <ContainerBase>
            <Features>
                <FeaturesHeader>
                <TagSection>Características</TagSection>
                <SubTitle as={'h2'} $isBlack={true}>Características que Transforman tu Negocio</SubTitle>
                <Description>Descubre cómo Blox hace que tu atención al cliente sea más eficiente y efectiva:</Description>
                </FeaturesHeader>
                <FeaturesList>
                    <FeaturesListItem className="feature-item"><span>1.</span>Respuestas personalizadas con identidad de marca</FeaturesListItem>
                    <FeaturesListItem className="feature-item"><span>2.</span>Disponible 24/7 para garantizar soporte continuo</FeaturesListItem>
                    <FeaturesListItem className="feature-item"><span>3.</span>Análisis de datos en tiempo real para mejorar continuamente</FeaturesListItem>
                    <FeaturesListItem className="feature-item"><span>4.</span>Implementación autogestionada que se ajusta a las necesidades específicas sin complicaciones</FeaturesListItem>
                </FeaturesList>
            </Features>
        </ContainerBase>
    </ContainerFeatures>
  )
}

export default FeaturesSection;