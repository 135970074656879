import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Spin,
  message,
  Typography,
  Upload,
  Button,
  Switch,
  Modal,
} from "antd";
import {
  UploadOutlined,
  CrownOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  SiVisa,
  SiMastercard,
  SiAmericanexpress,
  SiDiscover,
} from "react-icons/si";
import styles from "./UserProfile.module.css";
import ChangePasswordModal from "../../../components/modals/ChangePasswordModal";
import ChangePaymentMethodModal from "../../../components/modals/ChangePaymentMethodModal";
import { get, put } from "../../../Api/http/httpClient";

const { Text } = Typography;

interface UserProfileProps {
  user_data: {
    email: string;
    phone_number: string;
    family_name: string;
    address: string;
    "custom:establishment_id": string;
    sub: string;
    "custom:plan": string;
  };
}

const UserProfile: React.FC<UserProfileProps> = ({ user_data }) => {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const [plan, setPlan] = useState("Free");
  const [logoUrl, setLogoUrl] = useState(
    "https://www.bloxai.co/logo_horizontal.png"
  );
  const [fileList, setFileList] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [paymentData, setPaymentData] = useState<any>(null);
  const [autoRenew, setAutoRenew] = useState(false);
  const [packageData, setPackageData] = useState<any>(null);
  const navigate = useNavigate();

  const fetchEstablishmentData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );
      if (response.answer) {
        const fetchedData = response.item;
        form.setFieldsValue(fetchedData);
      } else {
        setError(true);
        message.error("No se encontraron datos del establecimiento.");
      }
    } catch (error) {
      setError(true);
      message.error("Error al obtener los datos del establecimiento.");
    } finally {
      setLoading(false);
    }
  };

  const fetchPackageData = async () => {
    try {
      const response = await get(
        `packages-plans?establishment_id=${user_data["custom:establishment_id"]}&only_active=true`
      );
      if (response.answer) {
        setPackageData(response.item);
      } else {
        setPackageData(null);
      }
    } catch (error) {
      console.error("Error fetching package data:", error);
      message.error("Error al obtener los datos del paquete.");
    }
  };

  const fetchAutomaticPaymentData = async () => {
    try {
      const response = await get(
        `automatic-payment?establishment_id=${user_data["custom:establishment_id"]}`
      );
      if (response.answer) {
        const payment = response.data[0];
        setPaymentData({
          ...payment,
          formattedDate: formatDate(payment.next_billing_date),
        });
        setAutoRenew(payment.autoRenew);
      } else {
        setPaymentData(null);
      }
    } catch (error) {
      console.error("Error fetching automatic payment data:", error);
      message.error("Error al obtener los datos de pago automático.");
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const getCardIcon = (cardName: string) => {
    if (cardName.toLowerCase().includes("visa"))
      return <SiVisa style={{ width: 24, height: 24 }} />;
    if (cardName.toLowerCase().includes("mastercard"))
      return <SiMastercard style={{ width: 24, height: 24 }} />;
    if (
      cardName.toLowerCase().includes("american express") ||
      cardName.includes("amex")
    )
      return <SiAmericanexpress style={{ width: 24, height: 24 }} />;
    if (cardName.toLowerCase().includes("discover"))
      return <SiDiscover style={{ width: 24, height: 24 }} />;
    return null;
  };

  const formatCardNumber = (cardNumber: string) => {
    const lastFourDigits = cardNumber.split("-")[1];
    return `**** **** **** ${lastFourDigits}`;
  };

  const handleLogoUpload = (file: any) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setLogoUrl(e.target?.result as string);
    };
    reader.readAsDataURL(file);
    return false;
  };

  useEffect(() => {
    console.log("USER_DATA: ", user_data);
    setPlan(user_data["custom:plan"] === "0" ? "Free" : "Premium");
    fetchEstablishmentData();
    fetchAutomaticPaymentData();
    if (user_data["custom:plan"] !== "0") {
      fetchPackageData(); // Solo obtener datos del paquete si el plan es Premium
    }
  }, []);

  const handlePlanChange = () => {
    if (plan === "Free") {
      navigate("/plans");
    } else {
      if (autoRenew) {
        confirmPlanChange();
      } else {
        message.info(
          `Tu plan ya fue procesado para pasar a un plan gratuito el ${formatDate(packageData.expiry_date)}.`
        );
      }
    }
  };  

  const handlePaymentMethodSubmit = async (values: {
    cardToken: string;
    nameCard: string;
    expires_at: string;
  }): Promise<any> => {
    try {
      // Preparar los datos para enviar en el cuerpo de la solicitud
      const payload = {
        cardToken: values.cardToken,
        nameCard: values.nameCard,
        expires_at: values.expires_at,
        packageData: packageData,
        update_payment_method: true,
        establishmentID: user_data["custom:establishment_id"],
      };

      // Realizar la solicitud PUT
      const response = await put("recurring-billing", payload);

      fetchAutomaticPaymentData();

      // Retorna la respuesta completa del servidor
      return response;
    } catch (error) {
      console.error("Error al actualizar el método de pago:", error);
      return null; // Retorna null en caso de error
    }
  };

  const handleCardChange = () => {
    setIsPaymentModalVisible(true);
  };

  const confirmAutoRenewChange = () => {
    Modal.confirm({
      title: `¿Estás seguro de que quieres ${
        autoRenew ? "desactivar" : "activar"
      } la renovación automática?`,
      content: `La renovación automática se ${
        autoRenew ? "cancelará" : "activará"
      } para tu método de pago.`,
      okText: "Sí",
      cancelText: "No",
      onOk: toggleAutoRenew,
    });
  };

  const confirmPlanChange = () => {
    Modal.confirm({
      title: "¿Estás seguro de que quieres cambiar a un plan gratuito?",
      content:
        "Al cambiar al plan gratuito, perderás todos tus beneficios actuales. ¿Deseas continuar?",
      okText: "Sí, cambiar",
      cancelText: "No, cancelar",
      onOk: updateSubscriptionPlan,
    });
  };

  const updateSubscriptionPlan = async () => {
    try {
      const payload = {
        subscription_action: !autoRenew ? "reactivate" : "cancel",
        packageData: packageData,
        establishmentID: user_data["custom:establishment_id"],
      };
  
      // Realizar la solicitud PUT
      const response = await put("recurring-billing", payload);
  
      if (response.answer) {
        setAutoRenew(!autoRenew);
        message.success(
          `Has cambiado al plan ${
            !autoRenew ? "gratuito" : "actual"
          } correctamente.`
        );
      } else {
        message.error(
          "Hubo un problema al intentar cambiar el plan. Por favor, intenta nuevamente."
        );
      }
    } catch (error) {
      message.error("Error al intentar cambiar el plan. Inténtalo más tarde.");
    }
  };  

  const toggleAutoRenew = async () => {
    try {
      const payload = {
        subscription_action: !autoRenew ? "reactivate" : "cancel",
        packageData: packageData,
        establishmentID: user_data["custom:establishment_id"],
      };

      // Realizar la solicitud PUT
      const response = await put("recurring-billing", payload);

      if (response.answer) {
        setAutoRenew(!autoRenew);
        message.success(
          `Renovación automática ${
            !autoRenew ? "activada" : "desactivada"
          } correctamente.`
        );
      } else {
        message.error(
          "Hubo un problema al actualizar la renovación automática."
        );
      }
    } catch (error) {
      message.error("Error al actualizar la renovación automática.");
    }
  };

  return (
    <Row
      justify="center"
      style={{ marginTop: "20px", paddingLeft: "20px", paddingRight: "20px" }}
    >
      <Col xs={24} sm={24} md={20} lg={16}>
        <Card
          title={
            <div className={styles.userProfileTitle}>
              <Text className={styles.titleText}>Perfil del Usuario</Text>
              <Button
                type="default"
                onClick={() => setIsModalVisible(true)}
                className={styles.changePasswordButton}
              >
                Cambiar Contraseña
              </Button>
            </div>
          }
          bordered={false}
          className={styles.userProfileCard}
        >
          {loading ? (
            <div className={styles.loadingContainer}>
              <Spin size="large" />
              <Text style={{ marginTop: "20px" }}>
                Cargando datos del usuario...
              </Text>
            </div>
          ) : error ? (
            <Text type="danger">Error al cargar los datos del usuario.</Text>
          ) : (
            <>
              <Form form={form} layout="vertical">
                <Row gutter={24}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Nombre del Establecimiento"
                      name="name_establishment"
                    >
                      <Input
                        size="large"
                        disabled
                        className={styles.inputDisabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Correo Electrónico" name="email">
                      <Input
                        size="large"
                        disabled
                        className={styles.inputDisabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Dirección" name="address">
                      <Input
                        size="large"
                        disabled
                        className={styles.inputDisabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Teléfono" name="phone">
                      <Input
                        size="large"
                        disabled
                        className={styles.inputDisabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Card
                title={
                  <div className={`${styles.planCardHeader}`}>
                    {plan === "Premium" ? (
                      <CrownOutlined
                        style={{ fontSize: "24px", color: "gold" }}
                      />
                    ) : (
                      <SmileOutlined
                        style={{ fontSize: "24px", color: "#1890ff" }}
                      />
                    )}
                    <Text strong>Plan {plan}</Text>
                    <Button
                      type="primary"
                      onClick={handlePlanChange}
                      className={styles.planButton}
                    >
                      {plan === "Free"
                        ? "Actualizar a Premium"
                        : "Cambiar a Free"}
                    </Button>
                  </div>
                }
                className={`${styles.planCard} ${
                  plan === "Premium" ? styles.premiumPlan : styles.freePlan
                }`}
              >
                {plan === "Free" ? (
                  <Text>
                    Disfruta de las funcionalidades básicas de tu
                    establecimiento.
                  </Text>
                ) : (
                  <>
                    <Text>Accede a todas las funcionalidades avanzadas.</Text>
                    {packageData && (
                      <Row
                        gutter={[16, 16]}
                        className={styles.packageDataContainer}
                        align="middle"
                      >
                        <Col xs={24} sm={12}>
                          <Text strong>Nombre del Plan: </Text>
                          <Text>{packageData.plan_name}</Text>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Text strong>Mensajes Restantes: </Text>
                          <Text>{packageData.remaining_messages}</Text>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Text strong>Fecha de Expiración: </Text>
                          <Text>{formatDate(packageData.expiry_date)}</Text>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Text strong>Renovación Automática: </Text>
                          <Text>
                            {packageData.autoRenew ? "Activada" : "Desactivada"}
                          </Text>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Card>

              {paymentData && (
                <Card
                  title={
                    <div className={`${styles.paymentCardHeader}`}>
                      <Text strong>Método de Pago</Text>
                      <Button
                        type="default"
                        onClick={handleCardChange}
                        className={styles.paymentButton}
                      >
                        Cambiar Tarjeta
                      </Button>
                    </div>
                  }
                  className={styles.paymentCard}
                >
                  <Row
                    gutter={[16, 16]}
                    className={styles.paymentDataContainer}
                  >
                    <Col xs={24} sm={12}>
                      <Text strong>Tarjeta:</Text>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        {getCardIcon(paymentData.name_card)}
                        <Text style={{ marginLeft: "8px" }}>
                          {formatCardNumber(paymentData.name_card)}
                        </Text>
                      </div>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Text strong>Próxima Fecha de Facturación:</Text>
                      <Text style={{ display: "block", marginTop: "5px" }}>
                        {autoRenew
                          ? paymentData.formattedDate
                          : "Renovación automática desactivada"}
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <Text strong>Renovación Automática:</Text>
                      <Switch
                        checked={autoRenew}
                        onChange={confirmAutoRenewChange}
                        className={styles.renewSwitch}
                      />
                    </Col>
                  </Row>
                </Card>
              )}

              <Card
                title={
                  <div className={styles.logoCardHeader}>
                    <Text strong className={styles.logoTitle}>
                      Logo
                    </Text>
                    <div className={styles.logoButtonContainer}>
                      <Upload
                        beforeUpload={handleLogoUpload}
                        fileList={fileList}
                        onChange={({ fileList: newFileList }) =>
                          setFileList(newFileList)
                        }
                        className={styles.uploadButton}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          className={styles.uploadButton}
                        >
                          Cambiar Logo
                        </Button>
                      </Upload>
                      <Button
                        type="primary"
                        className={styles.saveButton}
                        onClick={() =>
                          message.success("Logo guardado localmente")
                        }
                      >
                        Guardar Logo
                      </Button>
                    </div>
                  </div>
                }
                className={styles.logoCard}
              >
                <Row align="middle">
                  <Col xs={24} style={{ textAlign: "center" }}>
                    <img
                      src={logoUrl}
                      alt="Logo del Establecimiento"
                      className={styles.logoImageCentered}
                    />
                  </Col>
                </Row>
              </Card>

              <ChangePaymentMethodModal
                isModalVisible={isPaymentModalVisible}
                setIsModalVisible={setIsPaymentModalVisible}
                onSubmit={handlePaymentMethodSubmit}
              />

              <ChangePasswordModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
              />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default UserProfile;
