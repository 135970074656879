import { ContainerForm } from '../../styledComponents/Containers';
import { Description, SubTitle } from '../../styledComponents/Typography';
import { ErrorMessage, Formik } from 'formik';
import { ErrorText, FormContent, FormGroup, FormItem, InputText, TextArea } from '../../styledComponents/Inputs';
import { ButtonFill } from '../../styledComponents/Buttons';
import * as Yup from 'yup';

interface FormValues {
    name: string;
    email: string;
    message: string;
    phone: string;
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "El nombre es demasiado corto")
      .required("El nombre es obligatorio"),
    email: Yup.string()
      .email("Correo electrónico no válido")
      .required("El correo electrónico es obligatorio"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "El número de celular debe tener 10 dígitos")
      .required("El número de celular es obligatorio"),
    message: Yup.string()
      .min(10, "El mensaje debe tener al menos 10 caracteres")
      .required("El mensaje es obligatorio"),
});

const sendEmail = (values: FormValues) => {
    console.log("Enviando formulario con valores:", values);
    alert("Correo enviado exitosamente");
};

const FormFooter = () => {
  return (
    <ContainerForm>
        <SubTitle $isBlack={false}>¿Tienes preguntas?</SubTitle>
        <Description>Envíanos un mensaje y un representante te contactará.</Description>
        <Formik 
            initialValues={{ name: '', email: '', phone: '', message: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                sendEmail(values);
                resetForm();
            }}
        >
            {({isSubmitting}) => (
                <FormContent>
                    <FormGroup>
                        <FormItem>
                            <label htmlFor="name">Nombre*</label>
                            <InputText type="text" name="name" placeholder="Nombre completo" />
                            <ErrorMessage name="name" component={ErrorText} />
                        </FormItem>

                        <FormItem>
                            <label htmlFor="email">Correo*</label>
                            <InputText type="email" name="email" placeholder="Correo electrónico" />
                            <ErrorMessage name="email" component={ErrorText} />
                        </FormItem>

                        <FormItem>
                            <label htmlFor="phone">Celular*</label>
                            <InputText type="text" name="phone" placeholder="Número de celular" />
                            <ErrorMessage name="phone" component={ErrorText} />
                        </FormItem>
                    </FormGroup>

                    <FormItem>
                        <label htmlFor="message">Mensaje*</label>
                        <TextArea name="message" placeholder="Escribe un mensaje" rows={4} />
                        <ErrorMessage name="message" component={ErrorText} />
                    </FormItem>

                    <ButtonFill type="submit" aria-label="Enviar formulario">
                        {isSubmitting ? "Enviando..." : "Enviar"}
                    </ButtonFill>
                </FormContent>
            )}
        </Formik>
    </ContainerForm>
  );
}

export default FormFooter;