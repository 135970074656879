import React from "react";
import { Modal, Form, Input, Spin, message } from "antd";

interface CategoryModalProps {
  visible: boolean;
  newCategoryName: string;
  setNewCategoryName: (value: string) => void;
  onOk: () => void;
  onCancel: () => void;
  updatingCategory: boolean;
}

const CategoryModal: React.FC<CategoryModalProps> = ({
  visible,
  newCategoryName,
  setNewCategoryName,
  onOk,
  onCancel,
  updatingCategory
}) => {
  const handleCancel = () => {
    if (!updatingCategory) {
      onCancel(); // Solo cerrar si no se está actualizando
    } else {
      message.warning("Espera a que la actualización finalice."); // Mensaje si intentan cerrar durante la actualización
    }
  };

  return (
    <Modal
      title="Editar Categoría"
      visible={visible}
      onOk={onOk}
      onCancel={handleCancel} // Se utiliza la nueva función de cancelación
      okButtonProps={{ disabled: updatingCategory }} // Deshabilita el botón "OK" durante la carga
      cancelButtonProps={{ disabled: updatingCategory }} // Deshabilita el botón "Cancelar" durante la carga
      okText={updatingCategory ? <Spin /> : "Guardar"} // Muestra una animación de carga mientras se actualiza
      maskClosable={!updatingCategory} // Permite cerrar al hacer clic fuera solo si no está actualizando
      keyboard={!updatingCategory} // Deshabilita cerrar el modal con la tecla "Esc" solo si está actualizando
    >
      <Form layout="vertical">
        <Form.Item label="Nombre de la Categoría">
          <Input
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)} // Actualiza el valor del input
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryModal;