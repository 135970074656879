import { ContainerBase, ContainerFooter } from '../styledComponents/Containers';
import { ContactColumn, Copy, FooterNav, NavColumn } from '../styledComponents/Footer';
import FormFooter from './FormFooter/FormFooter';
import LogoImage from '../../../assets/img/landingPage/LogoBlox.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <ContainerFooter>
        <ContainerBase>
           <FormFooter />
            <FooterNav>
                <ContactColumn>
                    <a href="#hero"><img src={LogoImage} alt="Logo Blox" /></a>
                    <p>xxxx@xxxx.xxx</p>
                    <p>xxx xxx xx xx</p>
                    <div>
                        <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
                        <a href="#"><FontAwesomeIcon icon={faFacebook} /></a>
                        <a href="#"><FontAwesomeIcon icon={faTiktok} /></a>
                        <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
                    </div>
                </ContactColumn>
                <NavColumn>
                    <p>Ayuda</p>
                    <a href="#">Términos y Condiciones</a>
                    <a href="#">Políticas de Privacidad</a>
                </NavColumn>
                <NavColumn>
                    <p>Sitio</p>
                    <a href="#problem">¿Qué Resolvemos?</a>
                    <a href="#solution">Propuesta de Valor</a>
                    <a href="#features">Características</a>
                    <a href="#cases">Casos de Uso</a>
                </NavColumn>
                <NavColumn>
                    <p>&nbsp;</p>
                    <a href="#testimonials">Testimonios</a>
                    <a href="#demo">Demo Interactiva</a>
                    <a href="#prices">Planes y Precios</a>
                    <a href="#cta">Contáctenos</a>
                </NavColumn>
            </FooterNav>
        </ContainerBase>
        <Copy>© {currentYear}, Todos los derechos reservados por Blox.</Copy>
    </ContainerFooter>
  )
}

export default Footer