import { useEffect, useState } from 'react';

const useMouseParallax = (factor = 20) => {
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;

            const offsetX = (centerX - e.clientX) / factor;
            const offsetY = (centerY - e.clientY) / factor;

            setOffset({ x: offsetX, y: offsetY });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [factor]);

    return offset;
};

export default useMouseParallax;
