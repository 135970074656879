import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Input, Select, Button, Spin, message, Typography } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faCreditCard, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { get, put } from "../../../Api/http/httpClient";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Text } = Typography;

const paymentMethodOptions = [
  { label: "Efectivo", value: "cash", icon: faMoneyBill },
  { label: "Tarjeta de crédito", value: "credit-card", icon: faCreditCard },
  { label: "Tarjeta de débito", value: "debit-card", icon: faCreditCard },
  { label: "Pago móvil", value: "mobile-payment", icon: faMobileAlt },
];

const PolicyData: React.FC<{ user_data: any }> = ({ user_data }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const [originalValues, setOriginalValues] = useState<any>({});
  const [hasChanges, setHasChanges] = useState(false);

  const screens = useBreakpoint();

  const fetchEstablishmentData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await get(
        `restaurant-onboarding?establishmentID=${user_data["custom:establishment_id"]}&name_establishment=${user_data.family_name}`
      );
      if (response.answer) {
        const fetchedData = response.item;
        form.setFieldsValue(fetchedData);
        setOriginalValues(fetchedData);
      } else {
        setError(true);
        message.error("No se encontraron datos del establecimiento.");
      }
    } catch (error) {
      setError(true);
      message.error("Error al obtener los datos del establecimiento.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishmentData();
  }, []);

  const onValuesChange = (changedValues: any, allValues: any) => {
    const isDifferent = Object.keys(originalValues).some(key => {
      return originalValues[key] !== allValues[key];
    });
    setHasChanges(isDifferent);
  };

  const onFinish = async (values: any) => {
    const changes = Object.keys(values).reduce((acc: any, key) => {
      if (values[key] !== originalValues[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length === 0) {
      message.info("No se han realizado cambios.");
    } else {
      try {
        setUpdating(true);
        await put("restaurant-onboarding", {
          establishmentID: user_data["custom:establishment_id"],
          name_establishment: user_data.family_name,
          ...changes,
        });
        message.success("Datos actualizados correctamente.");
        fetchEstablishmentData();
        setHasChanges(false);
      } catch (error) {
        message.error("Error al actualizar los datos del establecimiento.");
      } finally {
        setUpdating(false);
      }
    }
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col span={screens.xs ? 24 : 22}>
        <Card
          title="Políticas del Establecimiento"
          bordered={false}
          style={{
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: screens.xs ? "10px" : "10px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <Spin size="large" />
              <Text style={{ marginTop: "20px" }}>Cargando datos del establecimiento...</Text>
            </div>
          ) : error ? (
            <div style={{ textAlign: "center", padding: "40px" }}>
              <FrownOutlined style={{ fontSize: "64px", color: "#ff4d4f" }} />
              <Text
                type="danger"
                style={{ display: "block", fontSize: "18px", marginTop: "20px" }}
              >
                Ocurrió un error al cargar los datos del establecimiento.
              </Text>
              <Button
                type="primary"
                size="large"
                style={{ marginTop: "20px", borderRadius: "8px" }}
                onClick={fetchEstablishmentData}
              >
                Reintentar
              </Button>
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onValuesChange={onValuesChange}
              style={{ fontFamily: "Roboto, sans-serif" }}
            >
              {/* Política de Reservas */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Política de Reservas"
                    name="reservationPolicy"
                    rules={[{ required: true, message: "Por favor ingrese la política de reservas" }]}
                  >
                    <Input.TextArea
                      size="large"
                      placeholder="Ingrese la política de reservas"
                      autoSize={{ minRows: 3, maxRows: 8 }}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Política de Cancelación */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Política de Cancelación"
                    name="cancellationPolicy"
                    rules={[
                      { required: true, message: "Por favor ingrese la política de cancelación" },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      placeholder="Ingrese la política de cancelación"
                      autoSize={{ minRows: 3, maxRows: 8 }}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Opciones de Pago */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Opciones de Pago"
                    name="paymentOptions"
                    rules={[
                      { required: true, message: "Por favor seleccione las opciones de pago" },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      size="large"
                      placeholder="Seleccione las opciones de pago"
                      style={{ borderRadius: "8px" }}
                      options={paymentMethodOptions.map(option => ({
                        value: option.value,
                        label: (
                          <span>
                            <FontAwesomeIcon icon={option.icon} style={{ marginRight: 8 }} />
                            {option.label}
                          </span>
                        ),
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={updating}
                  disabled={!hasChanges}
                  style={{
                    borderRadius: "8px",
                    background: hasChanges
                      ? "linear-gradient(45deg, #1890ff, #1d39c4)"
                      : "gray",
                    border: "none",
                  }}
                >
                  Guardar Cambios
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default PolicyData;