import React from "react";
import { Modal, Form, Input, Row, Col } from "antd";

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
  paymentDetails: any | null;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  open,
  onClose,
  paymentDetails,
}) => {
  if (!paymentDetails) return null;

  return (
    <Modal
      title={`Detalles de la Transacción: ${paymentDetails.payment_reference}`}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Referencia de Pago">
              <Input value={paymentDetails.payment_reference} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Referencia">
              <Input value={paymentDetails.reference} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Nombre Completo">
              <Input value={paymentDetails.full_name} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Establecimiento">
              <Input value={paymentDetails.establishment} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Código del Plan">
              <Input value={paymentDetails.plan} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Monto (COP)">
              <Input value={paymentDetails.amount_in_cents} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Estado">
              <Input value={paymentDetails.status} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Método de Pago">
              <Input value={paymentDetails.payment_method_type} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Fecha de Finalización">
              <Input value={paymentDetails.finalized_at} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Fecha de Transacción">
              <Input value={paymentDetails.transaction_date} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Correo Electrónico">
              <Input value={paymentDetails.email} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Número de Teléfono">
              <Input value={paymentDetails.phone_number} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Familia">
              <Input value={paymentDetails.family_name} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Mensajes del Plan">
              <Input value={paymentDetails.plan_messages} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Título del Plan">
              <Input value={paymentDetails.plan_title} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Moneda">
              <Input value={paymentDetails.currency} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PaymentModal;