import styled from 'styled-components';

export const CradsContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 60px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Card = styled.div.attrs<{ $bgColor?: string; $textColor?: string }>((props) => ({
  $bgColor: props.$bgColor,
  $textColor: props.$textColor,
}))`
  background-color: ${({ $bgColor }) => $bgColor || '#ffffff'};
  color: ${({ $textColor }) => $textColor || '#000000'};
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  gap: 16px;

  h3 {
    font-family: poppins-medium;
    line-height: 38px;
    font-weight: 500;
    font-size: 30px;
  }

  h3, p {
    margin: 0;
  }

  p {
    font-family: poppins-regular;
    font-size: 20px;
    line-height: 28px;
  }

  &:nth-child(1) {
    grid-column: 1;
    grid-row: 1 / span 6;
    color: #ffffff;
    padding-top: 100px;
  }

  &:nth-child(2) {
    grid-column: 2;
    grid-row: 1 / span 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:nth-child(3) {
    grid-column: 3;
    grid-row: 1 / span 4;
    color: #ffffff;
    padding-top: 100px;
  }

  &:nth-child(4) {
    grid-column: 2;
    grid-row: 3 / span 4;
    background-color: #ffffff;
    color: #000000;
    padding-top: 100px;
  }

  &:nth-child(5) {
    grid-column: 3;
    grid-row: 5 / span 2;
    padding: 0;
    height: 200px;
  }

  @media (max-width: 1024px) {
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / span 4;
    }

    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
      height: 200px;
    }

    &:nth-child(3) {
      grid-column: 2;
      grid-row: 4 / span 2;
    }

    &:nth-child(4) {
      grid-column: 2;
      grid-row: 2 / span 2;
    }

    &:nth-child(5) {
      grid-column: 1;
      grid-row: 5;
    }
    h3 {
      font-size: 24px;
    }
  
    p {
      font-size: 16px;
    }
  }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
  border-radius: 10px;
  object-fit: cover;
`;