import React, { useState } from "react";
import { Form, Button, Checkbox, DatePicker } from "antd";
import moment, { Moment } from "moment";
import {
  ButtonGroup,
  CheckboxButton,
  CheckboxContainer,
} from "../landingpage/styledComponents/Buttons";

interface BusinessHoursProps {
  updateFormData: (newData: any) => void;
  nextStep: () => void;
  prevStep: () => void;
  initialValues: any;
}

export default function BusinessHours({
  updateFormData,
  nextStep,
  prevStep,
  initialValues,
}: BusinessHoursProps) {
  const [form] = Form.useForm();
  const [hasSpecialHours, setHasSpecialHours] = useState(
    initialValues.specialHours || false
  );

  const handleSubmit = (values: any) => {
    const openTime = values.openTime ? moment(values.openTime) : null;
    const closeTime = values.closeTime ? moment(values.closeTime) : null;
    const specialOpenTime = values.specialOpenTime
      ? moment(values.specialOpenTime)
      : null;
    const specialCloseTime = values.specialCloseTime
      ? moment(values.specialCloseTime)
      : null;

    const formattedValues = {
      ...values,
      openTime: openTime ? openTime.format("h:mm A") : "",
      closeTime: closeTime ? closeTime.format("h:mm A") : "",
      specialOpenTime: specialOpenTime ? specialOpenTime.format("h:mm A") : "",
      specialCloseTime: specialCloseTime ? specialCloseTime.format("h:mm A") : "",
    };
    updateFormData(formattedValues);
    nextStep();
  };

  const validateTimes = (_: any, value: Moment) => {
    const openTime = form.getFieldValue("openTime");
    if (openTime && value && value.isBefore(moment(openTime))) {
      return Promise.reject(
        "La hora de cierre debe ser posterior a la hora de apertura"
      );
    }
    return Promise.resolve();
  };

  const days = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        ...initialValues,
        openTime: initialValues.openTime
          ? moment(initialValues.openTime, "h:mm A")
          : null,
        closeTime: initialValues.closeTime
          ? moment(initialValues.closeTime, "h:mm A")
          : null,
        specialOpenTime: initialValues.specialOpenTime
          ? moment(initialValues.specialOpenTime, "h:mm A")
          : null,
        specialCloseTime: initialValues.specialCloseTime
          ? moment(initialValues.specialCloseTime, "h:mm A")
          : null,
      }}
    >
      <Form.Item
        label="Días de apertura"
        name="openDays"
        rules={[
          { required: true, message: "Por favor seleccione los días de apertura" },
        ]}
      >
        <Checkbox.Group>
          <ButtonGroup $justify="flex-start">
            {days.map((day) => (
              <CheckboxContainer key={day}>
                <Checkbox value={day} />
                <CheckboxButton>
                  <label>
                    <span>{day}</span>
                  </label>
                </CheckboxButton>
              </CheckboxContainer>
            ))}
          </ButtonGroup>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          label="Hora de apertura"
          name="openTime"
          rules={[
            { required: true, message: "Por favor ingrese la hora de apertura" },
          ]}
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
        >
          <DatePicker
            picker="time"
            format="h:mm A"
            use12Hours
            showNow={false}
            size="large"
            placeholder="Seleccionar hora de apertura"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Hora de cierre"
          name="closeTime"
          dependencies={["openTime"]}
          rules={[
            { required: true, message: "Por favor ingrese la hora de cierre" },
            { validator: validateTimes },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
        >
          <DatePicker
            picker="time"
            format="h:mm A"
            use12Hours
            showNow={false}
            size="large"
            placeholder="Seleccionar hora de cierre"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item name="specialHours" valuePropName="checked">
        <Checkbox onChange={(e) => setHasSpecialHours(e.target.checked)}>
          Horarios especiales para fines de semana y festivos
        </Checkbox>
      </Form.Item>
      {hasSpecialHours && (
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label="Hora de apertura (Horarios especiales)"
            name="specialOpenTime"
            rules={[
              {
                required: true,
                message: "Por favor ingrese la hora de apertura especial",
              },
            ]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <DatePicker
              picker="time"
              format="h:mm A"
              use12Hours
              showNow={false}
              size="large"
              placeholder="Seleccionar hora de apertura especial"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Hora de cierre (Horarios especiales)"
            name="specialCloseTime"
            dependencies={["specialOpenTime"]}
            rules={[
              {
                required: true,
                message: "Por favor ingrese la hora de cierre especial",
              },
              {
                validator: (_, value) => {
                  const specialOpenTime = form.getFieldValue("specialOpenTime");
                  if (
                    specialOpenTime &&
                    value &&
                    value.isBefore(moment(specialOpenTime))
                  ) {
                    return Promise.reject(
                      "La hora de cierre especial debe ser posterior a la hora de apertura especial"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <DatePicker
              picker="time"
              format="h:mm A"
              use12Hours
              showNow={false}
              size="large"
              placeholder="Seleccionar hora de cierre especial"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form.Item>
      )}
      <Form.Item>
        <Button onClick={prevStep} style={{ marginRight: 8 }} size="large">
          Anterior
        </Button>
        <Button type="primary" htmlType="submit" size="large">
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  );
}