import styled from "styled-components";

export const CardTestimonial = styled.div`
    width: 100%;
    height: 440px;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px -2px 40px 0px #A7A0F826;
    box-shadow: 0px -2px 10px 0px #E9DFFF4D;    
    font-family: poppins-regular;
    font-size: 20px;
    line-height: normal;
    color: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
    h3, p {
        margin: 0;
    }
    p {
        font-size: 16px;
        font-family: poppins-light;
    }
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
`;

export const CardPrice = styled.div.attrs<{ active?: boolean }>((props) => ({
    className: props.active ? 'active' : '',
}))`
    width: calc((100% / 3) - 30px);
    border-radius: 20px;
    background-color: #18181B;
    padding: 30px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    font-size: 16px;
    font-family: poppins-regular;
    h3 {
        margin: 0;
        font-weight: 300;
    }
    p {
        width: 100%;
        color: #A1A1AA;
        margin: 0;
        span {
            font-size: 40px;
            color: #FFFFFF;
        }
    }
    hr {
        width: 100%;
    }
    ul {
        width: 100%;
        height: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            margin-bottom: 8px;
        }
    }
    &.active {
        border: 2px solid #0E6EAF;
    }
    @media (max-width: 1024px) {
        width: calc((100% / 2) - 16px);
    }
    @media (max-width: 780px) {
        width: 100%;
    }
`;