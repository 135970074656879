import React, { useState } from "react";
import { Modal, Button, Input, Spin, message } from "antd";

interface FaqModalProps {
  open: boolean;
  onClose: () => void;
  action: { type: string; data?: any } | null;
  formData: { question: string; answer: string };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleUpdate: () => Promise<boolean>;
  handleSave: () => Promise<boolean>;
}

const FaqModal: React.FC<FaqModalProps> = ({
  open,
  onClose,
  action,
  formData,
  handleChange,
  handleUpdate,
  handleSave,
}) => {
  const [loading, setLoading] = useState(false);

  const handleActionClick = async () => {
    if (!formData.question || !formData.answer) {
      message.error("Todos los campos son obligatorios");
      return;
    }

    setLoading(true);
    let success;
    if (action?.type === "edit") {
      await handleUpdate();
    } else {
      await handleSave();
    }
    setLoading(false);
  };

  return (
    <Modal
      title={action?.type === "edit" ? "Editar FAQ" : "Crear FAQ"}
      open={open}
      maskClosable={false}
      onCancel={() => !loading && onClose()}
      footer={[
        <Button key="back" onClick={() => !loading && onClose()} disabled={loading}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleActionClick} loading={loading}>
          {action?.type === "edit" ? "Actualizar" : "Guardar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Input
          id="question"
          placeholder="Pregunta"
          value={formData?.question}
          onChange={handleChange}
          style={{ marginBottom: 10 }}
        />
        <Input.TextArea
          id="answer"
          placeholder="Respuesta"
          value={formData?.answer}
          onChange={handleChange}
          rows={4}
          style={{ marginBottom: 10 }}
        />
      </Spin>
    </Modal>
  );
};

export default FaqModal;