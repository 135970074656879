import React from "react";
import { Modal, Form, Input, Button, message, Row, Col } from "antd";
import { useFormik } from "formik";
import { CreditCardOutlined } from "@ant-design/icons";
import { formatCardNumber, formatExpiryDate } from "../../utils/formatting"; // Importar desde utils
import { postWompi } from "../../Api/http/httpClient";
import * as Yup from "yup";

// Esquema de validación con Yup
const paymentSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required("Por favor ingresa el número de tarjeta")
    .matches(
      /^\d{16}$|^\d{4} \d{4} \d{4} \d{4}$/,
      "Número de tarjeta inválido"
    ),
  cardHolder: Yup.string()
    .required("Por favor ingresa el nombre del titular")
    .matches(/^[a-zA-Z\s]*$/, "Solo se permiten letras y espacios"),
  expiryDate: Yup.string()
    .required("Por favor ingresa la fecha de expiración")
    .matches(/^(0[1-9]|1[0-2]) \/ \d{2}$/, "Formato inválido (MM / YY)"),
  cvv: Yup.string()
    .required("Por favor ingresa el CVV")
    .matches(/^\d{3,4}$/, "El CVV debe tener 3 o 4 dígitos"),
});

interface ChangePaymentMethodModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
  onSubmit: (values: { cardToken: string; nameCard: string; expires_at: string }) => Promise<{
    answer: boolean;
    message: string;
  }>;
}

const ChangePaymentMethodModal: React.FC<ChangePaymentMethodModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      cardHolder: "",
      expiryDate: "",
      cvv: "",
    },
    validationSchema: paymentSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      formik.setSubmitting(true); // Activar estado de carga
      try {
        const tokenPayload = {
          number: values.cardNumber.replace(/\s+/g, ""),
          cvc: values.cvv,
          exp_month: values.expiryDate.split(" / ")[0].trim(),
          exp_year: values.expiryDate.split(" / ")[1].trim(),
          card_holder: values.cardHolder,
        };

        const tokenResponse = await postWompi("v1/tokens/cards", tokenPayload);

        if (tokenResponse.status !== "CREATED" || !tokenResponse.data?.id) {
          throw new Error("Error al tokenizar la tarjeta.");
        }

        const response = await onSubmit({
          cardToken: tokenResponse.data.id,
          nameCard: tokenResponse.data.name,
          expires_at: tokenResponse.data.expires_at,
        });

        if (response.answer) {
          message.success("Método de pago actualizado correctamente.");
          formik.resetForm();
          setIsModalVisible(false);
        } else {
          message.error("No se pudo actualizar el método de pago.");
        }
      } catch (error) {
        console.error("Error al tokenizar la tarjeta:", error);
        message.error(
          error instanceof Error
            ? error.message
            : "Hubo un problema al actualizar el método de pago."
        );
      } finally {
        formik.setSubmitting(false); // Desactivar estado de carga
      }
    },
  });

  const isButtonDisabled = !formik.isValid || formik.isSubmitting;

  const handleCancel = () => {
    if (!formik.isSubmitting) {
      formik.resetForm();
      setIsModalVisible(false);
    }
  };

  return (
    <Modal
      title="Cambiar Método de Pago"
      open={isModalVisible}
      onCancel={handleCancel}
      maskClosable={false} // Evita cerrar haciendo clic fuera del modal
      footer={[
        <Button
          key="cancel"
          onClick={handleCancel}
          disabled={formik.isSubmitting} // Deshabilitar botón de cancelar mientras carga
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => formik.handleSubmit()}
          disabled={isButtonDisabled}
          loading={formik.isSubmitting} // Animación de carga en el botón
        >
          Guardar
        </Button>,
      ]}
    >
      <Form layout="vertical" disabled={formik.isSubmitting}>
        <Form.Item
          label="Número de Tarjeta"
          validateStatus={
            formik.touched.cardNumber && formik.errors.cardNumber ? "error" : ""
          }
          help={formik.touched.cardNumber && formik.errors.cardNumber}
        >
          <Input
            id="cardNumber"
            name="cardNumber"
            placeholder="1234 5678 9012 3456"
            value={formik.values.cardNumber}
            onChange={(e) => {
              const formattedValue = formatCardNumber(e.target.value);
              formik.setFieldValue("cardNumber", formattedValue);
            }}
            maxLength={19}
            prefix={<CreditCardOutlined />}
            disabled={formik.isSubmitting} // Deshabilitar mientras carga
          />
        </Form.Item>

        <Form.Item
          label="Nombre del Titular"
          validateStatus={
            formik.touched.cardHolder && formik.errors.cardHolder ? "error" : ""
          }
          help={formik.touched.cardHolder && formik.errors.cardHolder}
        >
          <Input
            id="cardHolder"
            name="cardHolder"
            placeholder="John Doe"
            value={formik.values.cardHolder}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting} // Deshabilitar mientras carga
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Fecha de Expiración"
              validateStatus={
                formik.touched.expiryDate && formik.errors.expiryDate
                  ? "error"
                  : ""
              }
              help={formik.touched.expiryDate && formik.errors.expiryDate}
            >
              <Input
                id="expiryDate"
                name="expiryDate"
                placeholder="MM / YY"
                value={formik.values.expiryDate}
                onChange={(e) => {
                  const formattedValue = formatExpiryDate(e.target.value);
                  formik.setFieldValue("expiryDate", formattedValue);
                }}
                disabled={formik.isSubmitting} // Deshabilitar mientras carga
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="CVV"
              validateStatus={
                formik.touched.cvv && formik.errors.cvv ? "error" : ""
              }
              help={formik.touched.cvv && formik.errors.cvv}
            >
              <Input.Password
                id="cvv"
                name="cvv"
                placeholder="123"
                maxLength={4}
                value={formik.values.cvv}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting} // Deshabilitar mientras carga
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangePaymentMethodModal;