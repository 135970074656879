import React from "react";
import { Form, Input, Select, Button } from "antd";
import { BankOutlined } from "@ant-design/icons";

const { Option } = Select;

interface PseFormProps {
  banks: any[];
  pseDetails: {
    bank: string;
    personType: string;
    documentType: string;
    documentNumber: string;
    fullName: string;
    email: string;
    phoneNumber: string;
  };
  setPseDetails: React.Dispatch<
    React.SetStateAction<{
      bank: string;
      personType: string;
      documentType: string;
      documentNumber: string;
      fullName: string;
      email: string;
      phoneNumber: string;
    }>
  >;
  phoneError: string | null;
  handlePaymentData: (paymentMethod: string, paymentData: any) => void;
  permalink: string;
}

const documentTypes = [
  { value: "CC", label: "Cédula de Ciudadanía" },
  { value: "CE", label: "Cédula de Extranjería" },
  { value: "TI", label: "Tarjeta de Identidad" },
  { value: "NIT", label: "NIT" },
  { value: "PPT", label: "Permiso por Protección Temporal" },
  { value: "PS", label: "Pasaporte" },
];

const PseForm: React.FC<PseFormProps> = ({
  banks,
  pseDetails,
  setPseDetails,
  phoneError,
  handlePaymentData,
  permalink,
}) => {
  const handlePseDetailsChange = (field: string, value: string) => {
    setPseDetails((prev) => ({ ...prev, [field]: value }));
  };

  // Funciones de manejo de cambios
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
    handlePseDetailsChange("fullName", value);
  };

  const handleNameKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = e.key.charCodeAt(0);
    if (
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122) &&
      charCode !== 32
    ) {
      e.preventDefault();
    }
  };

  const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "");
    handlePseDetailsChange("documentNumber", value);
  };

  const handleDocumentKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = e.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "");
    handlePseDetailsChange("phoneNumber", value);
  };

  const handlePhoneKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = e.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="flex-row">
        <Form.Item
          label="Banco"
          name="bank"
          rules={[{ required: true, message: "Por favor seleccione su banco" }]}
          style={{ flex: 1 }}
        >
          <Select
            size="large"
            showSearch
            placeholder="Seleccione su banco"
            onChange={(value) => handlePseDetailsChange("bank", value)}
            filterOption={(input, option) =>
              (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {banks.length > 0 ? (
              banks.map((bank) => (
                <Option
                  key={bank.financial_institution_code}
                  value={bank.financial_institution_code}
                >
                  {bank.financial_institution_name}
                </Option>
              ))
            ) : (
              <Option disabled>Cargando bancos...</Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Tipo de persona"
          name="personType"
          rules={[
            {
              required: true,
              message: "Por favor seleccione el tipo de persona",
            },
          ]}
          style={{ flex: 1 }}
        >
          <Select
            size="large"
            placeholder="Tipo de persona"
            onChange={(value) => handlePseDetailsChange("personType", value)}
          >
            <Option value="0">Natural</Option>
            <Option value="1">Jurídica</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="flex-row">
        <Form.Item
          label="Tipo de documento"
          name="documentType"
          rules={[
            {
              required: true,
              message: "Por favor seleccione el tipo de documento",
            },
          ]}
          style={{ flex: 1 }}
        >
          <Select
            size="large"
            placeholder="Tipo de documento"
            onChange={(value) => handlePseDetailsChange("documentType", value)}
          >
            {documentTypes.map((doc) => (
              <Option key={doc.value} value={doc.value}>
                {doc.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Número de documento"
          name="documentNumber"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su número de documento",
            },
          ]}
          style={{ flex: 1 }}
        >
          <Input
            placeholder="Número de documento"
            size="large"
            type="text"
            value={pseDetails.documentNumber}
            onChange={handleDocumentChange}
            onKeyPress={handleDocumentKeyPress}
          />
        </Form.Item>
      </div>

      <Form.Item
        label="Nombre completo"
        name="fullName"
        rules={[
          { required: true, message: "Por favor ingrese su nombre completo" },
        ]}
      >
        <Input
          placeholder="Nombre completo"
          size="large"
          value={pseDetails.fullName}
          onChange={handleNameChange}
          onKeyPress={handleNameKeyPress}
        />
      </Form.Item>

      <Form.Item
        label="Correo electrónico"
        name="email"
        rules={[
          {
            required: true,
            message: "Por favor ingrese su correo electrónico",
          },
          { type: "email", message: "El correo electrónico no es válido" },
        ]}
      >
        <Input
          placeholder="Correo electrónico"
          size="large"
          value={pseDetails.email}
          onChange={(e) => handlePseDetailsChange("email", e.target.value)}
        />
      </Form.Item>

      <Form.Item
        label="Número de teléfono"
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: "Por favor ingrese su número de teléfono",
          },
        ]}
        validateStatus={phoneError ? "error" : undefined}
        help={phoneError}
      >
        <Input
          placeholder="Número de teléfono"
          size="large"
          type="text"
          value={pseDetails.phoneNumber}
          onChange={handlePhoneChange}
          onKeyPress={handlePhoneKeyPress}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          onClick={() => handlePaymentData("PSE", pseDetails)}
          size="large"
          style={{
            width: "100%",
            backgroundColor: "#1890ff",
            color: "#fff",
          }}
        >
          Realizar Pago
        </Button>
      </Form.Item>

      {/* Aquí usamos el permalink recibido para los términos y condiciones */}
      <p>
        Al enviar este pedido, acepto los{" "}
        <a href={permalink} target="_blank" rel="noopener noreferrer">
          Términos y Condiciones
        </a>{" "}
        y el{" "}
        <a href={permalink} target="_blank" rel="noopener noreferrer">
          Acuerdo de Suscripción
        </a>
        .
      </p>
    </>
  );
};

export default PseForm;