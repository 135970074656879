import React, { useRef, useEffect } from "react";
import { Modal, Avatar, Skeleton, Image } from "antd";
import { UserOutlined, RobotOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import moment from "moment";

interface Message {
  message: string;
  timestamp: string; // ISO string
  role: "user" | "assistant";
}

interface ChatModalProps {
  visible: boolean;
  onClose: () => void;
  chatDetails: {
    chat_uuid: string;
    establishment_id: string;
    chat_platform_type: string;
    message_count: number;
    messages: Message[];
  } | null;
}

const formatTimestamp = (timestamp: string) => {
  return moment(timestamp).format("DD/MM/YYYY HH:mm:ss");
};

const getPlatformName = (platformType: string) => {
  return platformType.toLowerCase() === "internal"
    ? "Interna"
    : platformType.toLowerCase() === "whatsapp"
    ? "WhatsApp"
    : platformType;
};

const formatMessage = (text: string) => {
  const imageRegex = /\.(jpeg|jpg|gif|png)$/i;

  if (imageRegex.test(text)) {
    return (
      <Image
        src={text}
        alt="Imagen enviada"
        style={{ maxWidth: "100%", borderRadius: "8px", marginTop: "10px" }}
      />
    );
  }

  return (
    <ReactMarkdown
      children={text}
      remarkPlugins={[remarkGfm]}
      components={{
        img: ({ node, ...props }) => (
          <Image
            src={props.src || ""}
            alt={props.alt || "Imagen enviada"}
            style={{ maxWidth: "100%", borderRadius: "8px", marginTop: "10px" }}
          />
        ),
        ul: ({ node, ...props }) => (
          <ul style={{ paddingLeft: "20px", marginTop: "10px" }} {...props} />
        ),
        ol: ({ node, ...props }) => (
          <ol style={{ paddingLeft: "20px", marginTop: "10px" }} {...props} />
        ),
        li: ({ node, ...props }) => (
          <li style={{ marginBottom: "5px" }} {...props} />
        ),
        p: ({ node, ...props }) => (
          <p style={{ margin: "5px 0" }} {...props} />
        ),
      }}
    />
  );
};

const ChatModal: React.FC<ChatModalProps> = ({ visible, onClose, chatDetails }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (visible) {
      setTimeout(scrollToBottom, 100);
    }
  }, [chatDetails?.messages, visible]);

  return (
    <Modal
      open={visible}
      title={`Detalles del Chat ${chatDetails?.chat_uuid || ""} (Plataforma: ${getPlatformName(
        chatDetails?.chat_platform_type || ""
      )})`}
      onCancel={onClose}
      footer={null}
      width={800}
      style={{ padding: 0 }}
      destroyOnClose
    >
      {chatDetails ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "60vh",
            overflow: "hidden",
            backgroundColor: "#f5f5f5",
            borderRadius: "10px",
            padding: "0",
          }}
        >
          <div
            style={{
              flex: 1,
              padding: "20px",
              overflowY: "auto",
              backgroundColor: "#ffffff",
              borderRadius: "10px",
            }}
          >
            {chatDetails.messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  justifyContent: msg.role === "assistant" ? "flex-end" : "flex-start",
                }}
              >
                {msg.role === "user" && (
                  <>
                    <Avatar
                      icon={<UserOutlined />}
                      style={{ backgroundColor: "#52c41a", marginRight: "10px" }}
                    />
                    <div style={{ maxWidth: "80%" }}>
                      <div
                        style={{
                          backgroundColor: "#e6f7ff",
                          padding: "10px 15px",
                          borderRadius: "15px",
                          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                      >
                        {formatMessage(msg.message)}
                      </div>
                      <div style={{ fontSize: "12px", color: "#888", marginTop: "5px" }}>
                        {formatTimestamp(msg.timestamp)}
                      </div>
                    </div>
                  </>
                )}

                {msg.role === "assistant" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginLeft: "auto",
                    }}
                  >
                    {/* Fila para el mensaje y el avatar */}
                    <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "flex-start" }}>
                      <Avatar
                        icon={<RobotOutlined />}
                        style={{
                          backgroundColor: "#1890ff",
                          marginLeft: "10px",
                        }}
                      />
                      <div
                        style={{
                          maxWidth: "70%",
                          textAlign: "left",
                          backgroundColor: "#f0f0f0",
                          padding: "10px 15px",
                          borderRadius: "15px",
                          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                      >
                        {formatMessage(msg.message)}
                      </div>
                    </div>
                    {/* Marca de tiempo */}
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#888",
                        marginTop: "5px",
                        textAlign: "right",
                        marginRight: "50px",
                      }}
                    >
                      {formatTimestamp(msg.timestamp)}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>
      ) : (
        <Skeleton active />
      )}
    </Modal>
  );
};

export default ChatModal;