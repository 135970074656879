import styled from 'styled-components';

export const LinesHero = styled.img`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
`;

export const ImageHero = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-top: 20px;
    @media (max-width: 480px) {
        margin-top: 50px;
    }
`;